import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { Avatar, Badge, Box, Button, CircularProgress, Dialog, DialogTitle, List, ListItemAvatar, ListItemButton, ListItemText, Menu, MenuList } from '@mui/material';
import { CustomerCompanyDto } from 'src/types/apiSchemas';
import { getInitials } from 'src/utils/getInitial';
import { useTranslation } from 'react-i18next';
import { useMounted } from 'src/hooks';
import { SiteUser } from 'src/types/siteUser';
import ChevronDown from 'src/icons/ChevronDown';
import { useNavigate } from 'react-router';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { allCustomersId } from 'src/constants';
import { AuthorizationService, IAuthorizationService } from 'src/Services';

export interface CustomerSelectionProps {
  displayDialog?: boolean;
  displayMenu?: boolean;
  children?: ReactNode;
  fullWidth?: boolean;
  padding?: number;
}

const CustomerSelection: FC<CustomerSelectionProps> = observer((props) => {
  const { displayDialog, displayMenu, children, fullWidth, padding } = props;
  const appStore = useAppStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [uniqueCustomers, setUniqueCustomers] = useState<CustomerCompanyDto[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selecting, setSelecting] = useState<boolean>(false);
  const { t } = useTranslation();
  const mounted = useMounted();
  const navigate = useNavigate();
  const auth: IAuthorizationService = new AuthorizationService();

  const { user, isAuthenticated } = appStore.loginStore.get();

  const getPendingNotificationsCount = (customerId: number): number => {
    const notificationInfo = appStore.notificationStore.get(customerId);
    if (!notificationInfo?.notificationsFetched) { return 0; }
    return notificationInfo.pendingApprovalsCount + notificationInfo.pendingPaymentsCount + notificationInfo.pendingAccessRequestCount + notificationInfo.unreadMessageThreadCount;
  };

  /**
   * Returns Integrata as first, otherwise should sort alphabetically
   */
  const compareCustomerName = (a: CustomerCompanyDto, b: CustomerCompanyDto): number => {
    if (a.customerName === 'Integrata') {
      return -1;
    } else if (b.customerName === 'Integrata') {
      return 1;
    }

    return a.customerName.localeCompare(b.customerName, 'fi');
  };

  /**
   * when accessing messaging after login, should never show customer selection
   * @returns
   */
  const isMessagingPath = (): boolean => {
    const str = window.location.href;
    const regex = /\/messages\/[A-Za-z0-9]{6}$/;
    return regex.test(str);
  };

  /**
   * if user has more than one customer to manage
   * force user to select the customer
   */
  useEffect(() => {
    if (!isAuthenticated || displayDialog && isMessagingPath()) {
      appStore.loginStore.setIsRefreshingAuthentication(false);
      return;
    }
    const userAccessPermissions = user?.accessPermissions;
    const useAdministrativeCompanies = user?.companies;
    if (!userAccessPermissions || !useAdministrativeCompanies) {
      return;
    }
    // customer selection menu will be shown,
    // if user is a messaging consultant (global internal roles for Integrata internal users)
    // or if user has more than one customer (Integrata internal users)
    const showCustomerSelection = displayMenu || auth.get(user).isMessagingConsultant ||
      Array.from(new Set(userAccessPermissions?.map((x) => x.customerId)))?.length > 1;
    if (!showCustomerSelection) { return; }
    if (mounted.current) {
      if (displayDialog) {
        setDialogOpen(true);
        appStore.loginStore.setIsRefreshingAuthentication(true);
      }
      const key = 'customerId';
      setUniqueCustomers([...new Map(useAdministrativeCompanies.map((customer) => [customer[key], customer])).values()]
        .sort((a, b) => compareCustomerName(a, b)));
    }
    
  }, [mounted, isAuthenticated, user]);

  const handleCustomerToggle = async (selectedCustomer: CustomerCompanyDto) => {
    if (mounted.current) {
      try {
        appStore.loginStore.setIsRefreshingAuthentication(true);
        setSelecting(true);

        const customerContextUser = {
          ...user,
          customerId: selectedCustomer?.customerId,
          customerName: selectedCustomer?.customerName ?? ''
        } as SiteUser;
        appStore.loginStore.login(customerContextUser);
        appStore.notificationStore.refreshAllNotifications(customerContextUser);
      } catch (err) {
        console.error(err);
      } finally {
        setSelecting(false);
        setDialogOpen(false);
        appStore.loginStore.setIsRefreshingAuthentication(false);
        // navigating to root because user might be switching customer in a route that
        // doesn't exist for the other customer and if tried to access the same route that doesn't exist for the new customer
        // it would cause a 404 page to appear
        navigate('/');
      }
    }
  };

  const handleCustomerSelect = (value: CustomerCompanyDto) => {
    handleCustomerToggle(value);
  };

  const closeMenu = useCallback(() => {
    if (mounted.current) {
      setAnchorEl(null);
    }
  }, [mounted, anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const loadingSpinner = (
    <Box
      display="flex"
      justifyContent="center"
      sx={{ mt: 10 }}
      bgcolor="background.secondary"
    >
      <CircularProgress
        size={35}
      />
    </Box>
  );

  const allCustomersNotificationCount = getPendingNotificationsCount(allCustomersId);
  const allCustomersNotifivationVisibility = allCustomersNotificationCount > 0 ? 'visible' : 'hidden';

  const customerSelectionElement = (): JSX.Element => (
    <MenuList>
      {
        auth.get(user).isMessagingConsultant && (
          <ListItemButton
            onClick={() => handleCustomerSelect({ customerId: allCustomersId, customerLinks: [] })}
          >
            <ListItemAvatar>
              <Badge
                badgeContent={allCustomersNotificationCount}
                color="primary"
                sx={{ '& .MuiBadge-badge': { fontSize: '1rem', fontWeight: 700, width: 30, height: 30, borderRadius: 15, visibility: allCustomersNotifivationVisibility } }}
              >
                <Avatar
                  sx={{
                    height: 42,
                    width: 42
                  }}
                >
                  <WorkspacesIcon />
                </Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText primary={t('AllCustomers')} />
          </ListItemButton>
        )
      }
      {
        uniqueCustomers.map((cust: CustomerCompanyDto) => {
          const totalNotificationsCount = getPendingNotificationsCount(cust.customerId);
          const notifivationVisibility = totalNotificationsCount > 0 ? 'visible' : 'hidden';
          return (
            <ListItemButton
              onClick={() => handleCustomerSelect(cust)}
              key={cust.customerId}
            >
              <ListItemAvatar>
                <Badge
                  badgeContent={totalNotificationsCount}
                  color="primary"
                  sx={{ '& .MuiBadge-badge': { fontSize: '1rem', fontWeight: 700, width: 30, height: 30, borderRadius: 15, visibility: notifivationVisibility } }}
                >
                  <Avatar
                    sx={{
                      height: 42,
                      width: 42
                    }}
                  >
                    {getInitials(cust.customerName)}
                  </Avatar>
                </Badge>
              </ListItemAvatar>
              <ListItemText primary={cust.customerName} />
            </ListItemButton>
          );
        })
      }
    </MenuList>
  );

  if (displayMenu) {
    const enableCustomerSelection = auth.get(user).isMessagingConsultant || uniqueCustomers?.length > 1;
    const buttonIcon = enableCustomerSelection ? <ChevronDown sx={{ color: 'primary.sidebarText' }} /> : null;

    return (
      <>
        <Button
          id="basic-button"
          aria-controls={openMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={handleClick}
          endIcon={buttonIcon}
          sx={{
            padding,
            textTransform: 'none',
            color: 'inherit',
            justifyContent: 'flex-start',
            '.MuiButton-endIcon': {
              marginLeft: 'auto',
              paddingLeft: 1,
              paddingRight: 1
            }
          }}
          fullWidth={fullWidth}
        >
          {children}
        </Button>
        {enableCustomerSelection && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {customerSelectionElement()}
          </Menu>
        )}
      </>
    );
  }

  if (dialogOpen) {
    return (
      <Dialog
        onClose={handleCustomerToggle}
        open={dialogOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t('LoginChooseCustomerDialogTitle')}</DialogTitle>
        <List sx={{ pt: 0 }}>
          {selecting ? loadingSpinner : customerSelectionElement()}
        </List>
      </Dialog>
    );
  }

  return null;
});

export default CustomerSelection;
