import { Box, Button, CircularProgress, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { baseApi } from 'src/API/baseApi';
import { Absence, AbsenceDto, AbsenceResult, AbsenceTranslated, AbsenceType, AbsenceUpdateResult, AccessPermission, AttachmentFileDto, AttachmentResult, ManagerApprovalStatus, ManagerSubstitutionDto, SubordinateDto } from 'src/types/apiSchemas';
import AddIcon from '@mui/icons-material/Add';
import { formatInTimeZone } from 'date-fns-tz';
import toast from 'react-hot-toast';
import useMounted from 'src/hooks/useMounted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { observer } from 'mobx-react';
import { WhiteOutlineButton } from 'src/components/WhiteOutlineButton';
import { fileExtensionValidation } from 'src/utils/yupUtils';
import { allowedAbsenceFileExtensions } from 'src/constants';
import { differenceInHours } from 'date-fns';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { ensureUTCFormat } from 'src/utils/dateUtils';
import { managerApprovalsApi } from 'src/API/managerApprovalsApi';

export interface EditAbsence {
  userName: string;
  absenceId: string;
}

interface AbsenceFormProps {
  absenceInformation?: EditAbsence;
  onAbsenceCreate?: (absenceId: number) => void;
  onAbsenceEdit?: (absence: AbsenceDto, hasAttachments?: boolean, approvalStatus?: ManagerApprovalStatus) => void;
  disableAttachments?: boolean;
  onCancel?: () => void;
  disableOnBehalfSubordinateOptions?: boolean;
  selectedSubordinateId?: number;
  alwaysPending?: boolean;
}

interface EditAbsenceError {
  isError: boolean;
  resultKey?: string;
}

const AbsenceForm: FC<AbsenceFormProps> = observer(({ absenceInformation, onAbsenceCreate, onAbsenceEdit, disableAttachments, onCancel, disableOnBehalfSubordinateOptions: disableOnBehalfSubordinate, selectedSubordinateId, alwaysPending }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { t } = useTranslation();
  const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>([]);
  const [managers, setManagers] = useState<ManagerSubstitutionDto[]>([]);
  const [managerId, setManagerId] = useState<number>();
  const [isLoadingSubordinates, setIsLoadingSubordinates] = useState<boolean>(false);
  const [subordinateId, setSubordinateId] = useState<number | undefined>(selectedSubordinateId);
  const [subordinates, setSubordinates] = useState<SubordinateDto[]>([]);
  const [behalfOfSubordinate, setBehaldOfSubordinate] = useState(!!selectedSubordinateId);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [editAbsence, setEditAbsence] = useState<AbsenceTranslated>();
  const [editAbsenceError, setEditAbsenceError] = useState<EditAbsenceError>({ isError: false });
  const [isLoadingAbsence, setIsLoadingAbsence] = useState<boolean>(false);
  const [deleteAttachmentIds, setDeleteAttachmentIds] = useState<number[]>([]);
  const mounted = useMounted();
  const navigate = useNavigate();

  enum SubmitButtonValues {
    save = 'save',
    saveAndApprove = 'saveAndApprove'
  }

  const auth: IAuthorizationService = new AuthorizationService();

  const initialStartDate = new Date();
  const initialEndDate = new Date();
  initialStartDate.setHours(0, 0, 0);
  initialEndDate.setHours(23, 59, 59);

  /**
   * absence form edit mode
   */
  const isEditMode = useMemo(() => {
    return !!absenceInformation?.absenceId;
  }, [absenceInformation]);

  /**
   * resolving absence to be edited
   */
  const geAbsenceToEdit = useCallback(async () => {
    if (isEditMode) {
      setIsLoadingAbsence(true);
      setEditAbsenceError({ isError: false });
      try {
        const absenceToEdit = await baseApi.getUserAbsence(user.userId, absenceInformation.absenceId, selectedSubordinateId);
        setEditAbsence(absenceToEdit);
      } catch (err) {
        console.error(err);
        setEditAbsenceError({ isError: true, resultKey: err?.response?.data?.resultKey });
      } finally {
        setIsLoadingAbsence(false);
      }
    }
  }, [absenceInformation?.absenceId]);

  useEffect(() => {
    geAbsenceToEdit();
  }, [absenceInformation?.absenceId]);

  const deleteExistingAttachmentFile = async (attachmentFileId: number): Promise<AttachmentResult> => {
    if (isEditMode) {
      try {
        const result = await baseApi.deleteAbsenceAttachmentFile(user, editAbsence.absenceId, editAbsence.attachment.userAttachmentId, attachmentFileId, selectedSubordinateId);
        return result;
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  };

  const handleDeleteAddAttachmentId = (attachmentFileId: number) => {
    const editedAbsence: AbsenceTranslated = {
      ...editAbsence,
      attachment: { ...editAbsence.attachment, files: editAbsence.attachment.files.filter((f) => f.userAttachmentFileId !== attachmentFileId) }
    };
    setEditAbsence(editedAbsence);
    setDeleteAttachmentIds(prevIds => {
      if (!prevIds.includes(attachmentFileId)) {
        return [...prevIds, attachmentFileId];
      }
      return prevIds;
    });
  };

  const getAbsenceTypes = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await baseApi.getAbsenceTypes(user?.customerId);
      setAbsenceTypes(data.filter((ac) => ac.absenceCategory === 'SickLeave' && (ac.attachmentRequirement !== 'None' || !disableAttachments)));
    } catch (err) {
      console.error(`Error loading absence types: ${err}`);
    } finally {
      setIsLoading(false);
    }
  }, [mounted]);

  const getMyEffectiveManagerSubstitutions = useCallback(async () => {
    setIsLoading(true);
    try {
      const substituteData = await baseApi.getMyEffectiveManagerSubstitutions();
      setManagers(substituteData);
      if (auth.get(user).hasManagerAccess) {
        setManagerId(user.userId);
      } else if (substituteData.length > 0) {
        setManagerId(substituteData[0].managerId);
      }
    } catch (err) {
      console.error(`Error loading manager substitutions: ${err}`);
    } finally {
      setIsLoading(false);
    }
  }, [mounted]);

  const getMyManagerSubstitutionSubordinates = useCallback(async (userId: number) => {
    setIsLoadingSubordinates(true);
    try {
      const subordinateData = await baseApi.getMyManagerSubstitutionSubordinates(userId);
      setSubordinates(subordinateData);
      if (subordinateData.length > 0) {
        setSubordinateId(subordinateData[0].id);
      }
    } catch (err) {
      console.error(`Error loading manager substitution subordinates: ${err}`);
    } finally {
      setIsLoadingSubordinates(false);
    }
  }, [managerId]);

  const getUserSubordinates = useCallback(async () => {
    setIsLoadingSubordinates(true);
    try {
      const subordinateData = await baseApi.getSubordinates(user?.customerId);
      setSubordinates(subordinateData);
      if (subordinateData.length > 0) {
        setSubordinateId(subordinateData[0].id);
      }
    } catch (err) {
      console.error(`Error loading user subordinates: ${err}`);
    } finally {
      setIsLoadingSubordinates(false);
    }
  }, [mounted]);

  useEffect(() => {
    getAbsenceTypes();
    if (!selectedSubordinateId) {
      getUserSubordinates();
      getMyEffectiveManagerSubstitutions();
    }
  }, [mounted]);

  useEffect(() => {
    if (!managerId || !!selectedSubordinateId) { return; }
    if (managerId === user.userId) {
      // getting own subordinates
      getUserSubordinates();
    } else {
      // if selected a substitution, getting subordinates of the substitution manager
      getMyManagerSubstitutionSubordinates(managerId);
    }
  }, [managerId]);

  const attachmentsEnabled = auth.get(user).has(AccessPermission.AttachmentsAccess).verify();

  const canSubmitOnBehalfOfSubordinate = !disableOnBehalfSubordinate &&
    auth.get(user).hasManagerOrSubstituteManagerAccess && (subordinates?.length > 0 || managers?.length > 0);
  const hasSubstitutes = managers?.length > 0;

  if (!auth.get(user).has(AccessPermission.AbsencesAccess).verify()) {
    navigate('/401');
  }

  const isLessThanDay = (): boolean => {
    const startDate = new Date(ensureUTCFormat(editAbsence.startOfAbsenceUTC));
    const endDate = new Date(ensureUTCFormat(editAbsence.endOfAbsenceUTC));
    const hourDifference = differenceInHours(endDate, startDate);
    return hourDifference < 23;
  };

  if (isLoading || isSending || isLoadingAbsence) {
    return <CircularProgress sx={{ margin: 'auto', display: 'block' }} />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        absenceTypeId: isEditMode && editAbsence ? editAbsence.absenceTypeId : absenceTypes.length > 0 ? absenceTypes[0].absenceTypeId : '',
        startDateUtc: isEditMode && editAbsence ? new Date(`${editAbsence.startOfAbsenceUTC}Z`) : initialStartDate,
        endDateUtc: isEditMode && editAbsence ? new Date(`${editAbsence.endOfAbsenceUTC}Z`) : initialEndDate,
        singleDayAbsence: isEditMode && editAbsence && isLessThanDay() ? true : false,
        startTimeUtc: isEditMode && editAbsence ? new Date(`${editAbsence.startOfAbsenceUTC}Z`) : new Date(),
        endTimeUtc: isEditMode && editAbsence ? new Date(`${editAbsence.endOfAbsenceUTC}Z`) : new Date(),
        attachments: [] as File[],
        pendingAttachment: false,
        behalfOfSubordinate: behalfOfSubordinate,
        substituteId: managerId ? managerId.toString() : '',
        subordinateId: subordinateId ? subordinateId.toString() : '',
        submitOperation: SubmitButtonValues.save,
      }}
      validationSchema={Yup.object().shape({
        startDateUtc: Yup.date().nullable().required(t('YupGeneralMessage')),
        endDateUtc: Yup.date().nullable().when('singleDayAbsence', (value) => {
          if (value) {
            return Yup.date().nullable();
          }

          return Yup.date().nullable().when('startDateUtc', (startDateUtc, schema) => {
            if (!startDateUtc) {
              return Yup.date().nullable().required(t('YupGeneralMessage'));
            }

            return schema.min(startDateUtc, t('AbsenceFormValidationEndDateMustBeLater')).required(t('YupGeneralMessage'));
          });
        }),
        startTimeUtc: Yup.date().nullable().when('singleDayAbsence', (value) => {
          if (value) {
            return Yup.date().nullable().required(t('YupGeneralMessage'));
          }
          return Yup.date().nullable();
        }),
        endTimeUtc: Yup.date().nullable().when('singleDayAbsence', (value, schema) => {
          if (value) {
            return schema.min(Yup.ref('startTimeUtc'), t('AbsenceFormValidationEndTimeMustBeLater')).required(t('YupGeneralMessage'));
          }
          return Yup.date().nullable();
        }),
        attachments: Yup.array().when(['absenceTypeId', 'pendingAttachment'], (absenceTypeId, pendingAttachment) => {
          const absenceType = absenceTypes.find((at) => at.absenceTypeId === absenceTypeId);

          if (!attachmentsEnabled || absenceType.attachmentRequirement !== 'Required' || pendingAttachment || disableAttachments) {
            return Yup.array().test(fileExtensionValidation(t('FileTypeNotAccepted'), allowedAbsenceFileExtensions));
          }

          let minAttachments = 1;
          if (isEditMode && editAbsence?.attachment?.files?.length > 0) {
            minAttachments = 0;
          }
          return Yup.array().min(minAttachments, t('AbsenceFormValidationMissingAttachment')).test(fileExtensionValidation(t('FileTypeNotAccepted'), allowedAbsenceFileExtensions));
        })
      })}
      onSubmit={async (values) => {
        setIsSending(true);

        let data: AbsenceResult | AbsenceUpdateResult;
        let status: ManagerApprovalStatus = 'Pending';
        let saveErrors = [];

        try {
          const getStartDate = (date: Date) => {
            if (values.singleDayAbsence) {
              const startDt = new Date(values.startDateUtc);
              startDt.setHours(values.startTimeUtc.getHours());
              startDt.setMinutes(values.startTimeUtc.getMinutes());
              return startDt;
            }
            return date;
          };

          const getEndDate = (date: Date) => {
            if (values.singleDayAbsence) {
              const endDt = new Date(values.startDateUtc);
              endDt.setHours(values.endTimeUtc.getHours());
              endDt.setMinutes(values.endTimeUtc.getMinutes());
              return endDt;
            }
            return date;
          };

          let absenceId: number;

          if (isEditMode) {
            const editedAbsence: Absence = {
              absenceTypeId: values.absenceTypeId as number,
              startOfAbsenceUTC: formatInTimeZone(getStartDate(values.startDateUtc), 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'"),
              endOfAbsenceUTC: formatInTimeZone(getEndDate(values.endDateUtc), 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'"),
              alwaysPending: !!alwaysPending
            };
            data = await baseApi.putAbsence(user, editAbsence.absenceId, editedAbsence, values.behalfOfSubordinate ? subordinateId : null);
            absenceId = data.absence.absenceId;

            // deleting attachments from existing absence
            if (deleteAttachmentIds.length > 0) {
              const promises: Promise<AttachmentResult>[] = [];
              deleteAttachmentIds.forEach((attachmentFileId) => {
                const promise = deleteExistingAttachmentFile(attachmentFileId);
                promises.push(promise);
              });
              try {
                const results = await Promise.all(promises.map((promise, index) =>
                  promise.then(result => {
                    console.log(`Promise ${index} resolved with result:`, result);
                    return { isError: false } as EditAbsenceError;
                  }).catch(err => {
                    console.error(`Error in promise ${index}:`, err);
                    return { isError: true, resultKey: err?.response?.data?.resultKey } as EditAbsenceError;
                  })
                ));

                results.forEach((result: EditAbsenceError, index: number) => {
                  if (result.isError) {
                    console.log(`Handling error for delete attachment promise ${index}:`, result?.resultKey);
                    const attachmentDeleteError = result?.resultKey ? `. ${t(result?.resultKey)}` : '';
                    const message = `${t('AttachmentDeleteErrorMessage')}${attachmentDeleteError}`;
                    toast.error(message);
                    saveErrors.push(message);
                  }
                });
              } catch (err) {
                console.error('A critical error occurred while deleting attachments:', err);
                const message = t('AttachmentDeleteErrorMessage');
                toast.error(message);
                saveErrors.push(message);
              }
            }

          } else {
            const newAbsence: Absence = {
              absenceTypeId: values.absenceTypeId as number,
              startOfAbsenceUTC: formatInTimeZone(getStartDate(values.startDateUtc), 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'"),
              endOfAbsenceUTC: formatInTimeZone(getEndDate(values.endDateUtc), 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'"),
              alwaysPending: !!alwaysPending
            };
            data = await baseApi.postAbsence(user, newAbsence, values.behalfOfSubordinate ? subordinateId : null);
            absenceId = data.absenceId;
          }

          if (values.attachments.length > 0) {
            try {
              await baseApi.postAttachment(user, values.attachments, 'SickLeaveCertificate', absenceId, values.behalfOfSubordinate ? subordinateId : null, isEditMode);
            } catch (err) {
              const attachmentError = err?.response?.data?.resultKey ? `. ${t(err.response.data.resultKey)}` : '';
              if (attachmentError) {
                toast.error(`${t('AttachmentsFormAttachmentsSendFailed')}${t(attachmentError)}`);
              } else {
                const message = t('AttachmentsFormAttachmentsSendFailed');
                toast.error(message);
                saveErrors.push(message);
              }
            }
          }

          toast.success(t('AbsenceFormAbsenceSaved'));

        } catch (err) {
          console.error(err);
          const attachmentSaveError = err?.response?.data?.resultKey ? ` :${t(err.response.data.resultKey)}` : '';
          const message = `${t('AbsenceFormAbsenceSaveFailed')}${attachmentSaveError}`;
          toast.error(message);
          saveErrors.push(message);
        }

        try {
          if (saveErrors.length === 0 && values.submitOperation === SubmitButtonValues.saveAndApprove && isEditMode) {
            // manager chose to approve the absence after editing it
            await managerApprovalsApi.putApproval(user.userId, editAbsence.absenceId, 'Approved', '', user?.customerId);
            status = 'Approved';
            toast.success(`${t('ApprovalSuccessful')}`);
          }
        } catch (err) {
          console.error(err);
          const approvalError = err?.response?.data?.resultKey ? `. ${t(err.response.data.resultKey)}` : '';
          const message = `${t('ApprovalFailed')}${approvalError}`;
          toast.error(message);
          saveErrors.push(message);
        }

        appStore.notificationStore.refreshApprovalNotifications(user);

        if (saveErrors.length === 0) {
          if (onAbsenceCreate) {
            const result = data as AbsenceResult;
            onAbsenceCreate(result.absenceId);
          } else if (isEditMode && onAbsenceEdit) {
            const hasAttachments = editAbsence?.attachment?.files?.length > 0 || values.attachments.length > 0;
            const result = data as AbsenceUpdateResult;
            onAbsenceEdit(result.absence, hasAttachments, status);
          } else {
            navigate('/dashboard/absences');
          }
        }

        setIsSending(false);
      }}
    >
      {({ values, touched, errors, setFieldValue, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Typography
            variant="h3"
            sx={{ mb: 3 }}
          >
            {t('AbsenceFormSickLeave')}
          </Typography>
          <Typography
            variant="h5"
            sx={{ mb: 3 }}
          >
            {absenceInformation?.userName}
          </Typography>
          <Grid
            container
            gap={3}
          >
            {editAbsenceError.isError &&
              <Grid
                item
                xs={12}
              >
                <SentimentVeryDissatisfiedIcon fontSize="large" color="error" />
                <Typography variant="h6" color="error">
                  {`${editAbsenceError.resultKey ? t(editAbsenceError.resultKey) : t('GeneralErrorMessage')}`}
                </Typography>
              </Grid>
            }

            {canSubmitOnBehalfOfSubordinate && (
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  sx={{ ml: 0.3 }}
                  control={(
                    <Switch
                      onChange={(event) => {
                        setFieldValue('behalfOfSubordinate', event.target.checked);
                        setBehaldOfSubordinate(event.target.checked);
                      }}
                      checked={values.behalfOfSubordinate}
                      color="primary"
                      edge="start"
                    />
                  )}
                  label={(
                    <Box>
                      {t('AbsenceFormSendBehalfOfSubordinate')}
                    </Box>
                  )}
                />
                {values.behalfOfSubordinate && (
                  <>
                    {hasSubstitutes && (
                      <Grid
                        item
                        xs={12}
                        sx={{ mt: 2 }}
                      >
                        <Typography
                          variant="formFieldHeader"
                          sx={{ mb: 1 }}
                        >
                          {t('UserManager')}
                        </Typography>
                        <Select
                          sx={{ mb: 2, minWidth: 300 }}
                          value={values.substituteId}
                          name="substituteId"
                          onChange={(event) => {
                            setFieldValue('substituteId', event.target.value);
                            setManagerId(Number(event.target.value));
                          }}
                        >
                          {auth.get(user).hasManagerAccess && (
                            <MenuItem
                              value={user.userId}
                              key={`own-${user.userId}`}
                            >
                              {t('OwnTeamSubordinates')}
                            </MenuItem>
                          )}
                          {managers.length > 0 && (
                            <ListSubheader sx={{ marginTop: 2 }}>
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                              >
                                {t('ManagerSubstitutions')}
                              </Typography>
                            </ListSubheader>
                          )}
                          {managers.map((manager) => (
                            <MenuItem
                              value={manager.managerId}
                              key={`substitute-${manager.managerId}`}
                            >
                              {manager.managerName}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}

                    >
                      <Typography
                        variant="formFieldHeader"
                        sx={{ mb: 1 }}
                      >
                        {t('AbsenceFormSubordinate')}
                      </Typography>
                      {isLoadingSubordinates ?
                        (
                          <CircularProgress size={20} />
                        )
                        :
                        (
                          <Select
                            sx={{ minWidth: 300 }}
                            value={values.subordinateId}
                            name="subordinateId"
                            onChange={(event) => {
                              setFieldValue('subordinateId', event.target.value);
                              setSubordinateId(Number(event.target.value));
                            }}
                          >
                            {subordinates?.map((member) => (
                              <MenuItem
                                value={member.id}
                                key={member.id}
                              >
                                {member.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }
                    </Grid>
                  </>
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="formFieldHeader"
                sx={{ mb: 1 }}
              >
                {t('AbsenceFormAbsenceReason')}
              </Typography>
              <Select
                value={values.absenceTypeId}
                name="absenceTypeId"
                onChange={handleChange}
              >
                {absenceTypes.map((absenceType) => (
                  <MenuItem
                    value={absenceType.absenceTypeId}
                    key={absenceType.absenceTypeId}
                  >
                    {absenceType.contents.find((c) => c.languageCode === user?.languageCode)?.name || absenceType.code}
                  </MenuItem>
                ))}
              </Select>
              <Typography sx={{ mt: 1 }}>{absenceTypes.find((at) => at.absenceTypeId === values.absenceTypeId)?.contents.find((c) => c.languageCode === user?.languageCode)?.description}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                variant="formFieldHeader"
                sx={{ mb: 1 }}
              >
                {t('AbsenceFormAbsenceStartDate')}
              </Typography>
              <DatePicker
                value={values.startDateUtc}
                onChange={(val: Date) => {
                  if (val) {
                    val.setHours(0, 0, 0);
                  }
                  setFieldValue('startDateUtc', val);
                }}
                mask="__.__.____"
                componentsProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                inputFormat="dd.MM.yyyy"
                renderInput={(params) => (
                  <TextField
                    sx={{ maxWidth: 200 }}
                    style={{ display: 'flex' }}
                    {...params}
                    error={Boolean(touched.startDateUtc && errors.startDateUtc)}
                    helperText={(touched.startDateUtc && errors.startDateUtc)}
                  />
                )}
              />
            </Grid>
            {!values.singleDayAbsence && (
              <Grid
                item
                xs={12}
                sm={5}
              >
                <Typography
                  variant="formFieldHeader"
                  sx={{ mb: 1 }}
                >
                  {t('AbsenceFormAbsenceEndDate')}
                </Typography>
                <DatePicker
                  value={values.endDateUtc}
                  onChange={(val: Date) => {
                    if (val) {
                      val.setHours(23, 59, 59);
                    }
                    setFieldValue('endDateUtc', val);
                  }}
                  mask="__.__.____"
                  componentsProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  inputFormat="dd.MM.yyyy"
                  renderInput={(params) => (
                    <TextField
                      sx={{ maxWidth: 200 }}
                      style={{ display: 'flex' }}
                      {...params}
                      error={Boolean(touched.endDateUtc && errors.endDateUtc)}
                      helperText={(touched.endDateUtc && errors.endDateUtc)}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <FormControlLabel
                sx={{ ml: 0.3 }}
                control={(
                  <Switch
                    onChange={(event) => {
                      setFieldValue('singleDayAbsence', event.target.checked);
                      if (!event.target.checked) {
                        values.startDateUtc.setHours(0, 0, 0);
                        values.endDateUtc.setHours(23, 59, 59);
                        setFieldValue('startDateUtc', values.startDateUtc);
                        setFieldValue('endDateUtc', values.endDateUtc);
                      }
                    }}
                    checked={values.singleDayAbsence}
                    color="primary"
                    edge="start"
                  />
                )}
                label={(
                  <Box>
                    {t('AbsenceFormUnderOneDayAbsence')}
                  </Box>
                )}
              />
            </Grid>
            {values.singleDayAbsence && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                    variant="formFieldHeader"
                    sx={{ mb: 1 }}
                  >
                    {t('AbsenceFormStartTime')}
                  </Typography>
                  <TimePicker
                    ampm={false}
                    value={values.startTimeUtc}
                    onChange={(val) => {
                      setFieldValue('startTimeUtc', val);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ maxWidth: 200 }}
                        style={{ display: 'flex' }}
                        {...params}
                        error={Boolean(touched.startTimeUtc && errors.startTimeUtc)}
                        helperText={(touched.startTimeUtc && errors.startTimeUtc)}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                >
                  <Typography
                    variant="formFieldHeader"
                    sx={{ mb: 1 }}
                  >
                    {t('AbsenceFormEndTime')}
                  </Typography>
                  <TimePicker
                    ampm={false}
                    value={values.endTimeUtc}
                    onChange={(val) => {
                      setFieldValue('endTimeUtc', val);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ maxWidth: 200 }}
                        style={{ display: 'flex' }}
                        {...params}
                        error={Boolean(touched.endTimeUtc && errors.endTimeUtc)}
                        helperText={(touched.endTimeUtc && errors.endTimeUtc)}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {!disableAttachments && attachmentsEnabled && absenceTypes.find((at) => at.absenceTypeId === values.absenceTypeId)?.attachmentRequirement !== 'None'
              && (
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="h5"
                      sx={{ mb: 1, mt: 5 }}
                    >
                      {t('AbsenceFormAddAttachments')}
                    </Typography>
                    {/* existing attachments of edited absence */}
                    {isEditMode && editAbsence?.attachment?.files?.length > 0 && (
                      <List sx={{ maxWidth: 300 }}>
                        {editAbsence?.attachment.files.map((file: AttachmentFileDto) => (
                          <ListItem
                            key={file.userAttachmentFileId}
                            secondaryAction={(
                              <IconButton
                                key={file.userAttachmentFileId}
                                onClick={() =>
                                  handleDeleteAddAttachmentId(file.userAttachmentFileId)
                                }
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            )}
                            disableGutters
                            sx={{ p: 0 }}
                          >
                            <ListItemText>
                              <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 40 }}>{file.originalFilename}</div>
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    )}
                    {values.attachments.length > 0 && (
                      <List sx={{ maxWidth: 300 }}>
                        {values.attachments.map((file: File, index) => (
                          <ListItem
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={index}
                            secondaryAction={(
                              <IconButton
                                onClick={() => {
                                  setFieldValue('attachments', values.attachments.filter((v, i) => i !== index));
                                }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            )}
                            disableGutters
                            sx={{ p: 0 }}
                          >
                            <ListItemText>
                              <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 40 }}>{file.name}</div>
                              {errors.attachments?.at(index) && <div><Typography color="error">{errors.attachments?.at(index)}</Typography></div>}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      startIcon={<AddIcon fontSize="small" />}
                      variant="light"
                      component="label"
                    >
                      {t('AbsenceFormAddDocument')}
                      <input
                        accept={allowedAbsenceFileExtensions.join(', ')}
                        name="attachment"
                        type="file"
                        hidden
                        onChange={(e) => {
                          setFieldValue('attachments', [...values.attachments, e.target.files[0]]);
                          e.target.value = '';
                        }}
                        capture
                      />
                    </Button>
                    <div style={{ margin: '10px 0' }}>
                      {t('AttachmentsPageAllowedFileExtensions')}
                      :
                      {' '}
                      {allowedAbsenceFileExtensions.join(', ')}
                    </div>
                  </Grid>
                  {absenceTypes.find((at) => at.absenceTypeId === values.absenceTypeId)?.attachmentRequirement === 'Required'
                    && (
                      <Grid
                        item
                        xs={12}
                      >
                        {values.attachments.length < 1 && <Typography>{t('AbsenceFormPendingAttachmentRequired')}</Typography>}
                        {errors.attachments && touched.attachments && values.attachments.length < 1 && (
                          <div style={{ marginBottom: 10 }}>
                            <Typography color="error">
                              {errors.attachments}
                            </Typography>
                          </div>
                        )}
                        {/* user can create absences without an attachment, if creating the absence for themselves */}
                        {/* user cannnot edit absences without an attachment */}
                        {!values.behalfOfSubordinate && !isEditMode && values.attachments.length < 1 && (
                          <FormControlLabel
                            sx={{ ml: 0.3 }}
                            control={(
                              <Switch
                                checked={values.pendingAttachment}
                                color="primary"
                                edge="start"
                                name="compact"
                                onChange={(event) => {
                                  setFieldValue('pendingAttachment', event.target.checked);
                                }}
                              />
                            )}
                            label={(
                              <Box>
                                {t('AbsenceFormPendingAttachment')}
                              </Box>
                            )}
                          />
                        )}
                      </Grid>
                    )}
                </>
              )}
            <Grid
              item
              xs={12}
            >
              <Divider sx={{ mb: 3 }} />
              {!editAbsenceError.isError &&
                <>
                  <LoadingButton
                    loading={isSending}
                    variant="contained"
                    color="success"
                    type="submit"
                    onClick={() => setFieldValue('submitOperation', SubmitButtonValues.save)}
                  >
                    {t('GenericButtonSave')}
                  </LoadingButton>
                  {auth.get(user).hasManagerOrSubstituteManagerAccess && values.behalfOfSubordinate && isEditMode &&
                    <LoadingButton
                      loading={isSending}
                      variant="contained"
                      color="success"
                      sx={{ ml: 2 }}
                      type="submit"
                      onClick={() => setFieldValue('submitOperation', SubmitButtonValues.saveAndApprove)}
                    >
                      {t('AbsenceEditButtonSaveAndApprove')}
                    </LoadingButton>
                  }
                </>
              }
              {onCancel && (
                <WhiteOutlineButton
                  variant="outlined"
                  sx={{ ml: 2 }}
                  onClick={onCancel}
                >
                  {t('ButtonCancel')}
                </WhiteOutlineButton>
              )}
            </Grid>
          </Grid>
        </form>
      )
      }
    </Formik >
  );
});

export default AbsenceForm;
