import { makeAutoObservable } from 'mobx';
import { Mention } from 'src/components/MessageEditor/MentionNode';
import { StorageKeys } from 'src/constants';
import { InternalRole, MessageAttachmentCreation, UserRole } from 'src/types/apiSchemas';
import { autoSaveToStorage } from './autoSaveToStorage';

type StringScopedValue<T> = { [key: string]: T };

class MessageStore {
  draft: StringScopedValue<string> = {};
  content: StringScopedValue<string> = {};
  richTextContent: StringScopedValue<string> = {};
  attachments: StringScopedValue<MessageAttachmentCreation[]> = {};
  userMentions: StringScopedValue<number[]> = {};
  userRoleMentions: StringScopedValue<UserRole[]> = {};
  internalRoleMentions: StringScopedValue<InternalRole[]> = {};
  clearValue: StringScopedValue<boolean> = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    autoSaveToStorage(this, StorageKeys.messagesStoreKey);
  }

  setDraft(value: string, messageThreadId: string) {
    this.draft[messageThreadId] = value;
  }

  setContent(value: string, messageThreadId: string) {
    this.content[messageThreadId] = value;
  }

  setRichTextContent(value: string, messageThreadId: string) {
    this.richTextContent[messageThreadId] = value;
  }

  addAttachment(value: MessageAttachmentCreation, messageThreadId: string) {
    this.attachments[messageThreadId] = [...(this.attachments[messageThreadId] ?? []), value];
  }

  removeAttachment(url: string, messageThreadId: string) {
    this.attachments[messageThreadId] = this.attachments[messageThreadId]?.map(a => {
      return { ...a, deleted: a.urlWithoutSas === url ? true : a.deleted };
    });
  }

  restoreAttachment(url: string, messageThreadId: string) {
    this.attachments[messageThreadId] = this.attachments[messageThreadId]?.map(a => {
      return { ...a, deleted: a.urlWithoutSas === url ? false : a.deleted };
    });
  }

  addMention(value: Mention, messageThreadId: string) {
    if (value.userId) {
      this.userMentions[messageThreadId] = [...(this.userMentions[messageThreadId] ?? []), value.userId];
    } else if (value.userRole) {
      this.userRoleMentions[messageThreadId] = [...(this.userRoleMentions[messageThreadId] ?? []), value.userRole];
    } else if (value.internalRole) {
      this.internalRoleMentions[messageThreadId] = [...(this.internalRoleMentions[messageThreadId] ?? []), value.internalRole];
    }
  }

  removeMention(value: Mention, messageThreadId: string) {
    if (value.userId) {
      const index = this.userMentions[messageThreadId]?.indexOf(value.userId);
      const arr = [...this.userMentions[messageThreadId] ?? []];
      if (index > -1) {
        arr.splice(index, 1);
      }
      this.userMentions[messageThreadId] = arr;
    } else if (value.userRole) {
      const index = this.userRoleMentions[messageThreadId]?.indexOf(value.userRole);
      const arr = [...this.userRoleMentions[messageThreadId] ?? []];
      if (index > -1) {
        arr.splice(index, 1);
      }
      this.userRoleMentions[messageThreadId] = arr;
    } else if (value.internalRole) {
      const index = this.internalRoleMentions[messageThreadId]?.indexOf(value.internalRole);
      const arr = [...this.internalRoleMentions[messageThreadId] ?? []];
      if (index > -1) {
        arr.splice(index, 1);
      }
      this.internalRoleMentions[messageThreadId] = arr;
    }
  }

  clearMentions(messageThreadId: string) {
    delete this.userMentions[messageThreadId];
    delete this.userRoleMentions[messageThreadId];
    delete this.internalRoleMentions[messageThreadId];
  }

  clearDraft(messageThreadId: string) {
    delete this.draft[messageThreadId];
    delete this.content[messageThreadId];
    delete this.richTextContent[messageThreadId];
    delete this.attachments[messageThreadId];
    delete this.userMentions[messageThreadId];
    delete this.userRoleMentions[messageThreadId];
    delete this.internalRoleMentions[messageThreadId];
  }

  deleteClearValue(messageThreadId: string) {
    delete this.clearValue[messageThreadId];
  }

  clearEditor(messageThreadId: string) {
    this.clearValue[messageThreadId] = !this.clearValue[messageThreadId];
    this.clearDraft(messageThreadId);
  }
}

export default new MessageStore();