import { DropDownOption } from './types/dropDownOption';
import { uiTheme } from './utils/environmentUtils';

export const THEMES = {
  DARK: 'DARK',
  ACCOUNTOR: 'ACCOUNTOR',
  LIGHT: 'LIGHT'
};

export const LANGUAGES: DropDownOption[] = [
  {
    label: 'Finnish',
    value: 'fi'
  },
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Swedish',
    value: 'sv'
  },
];

export const SiteThemes = uiTheme === THEMES.ACCOUNTOR ? [THEMES.ACCOUNTOR] : [THEMES.DARK, THEMES.LIGHT];

export const storagePrefix = 'https://app.humhum.fi';

export const manageSubstitutionsPath = 'managesubstitutions';

export const StorageKeys = {
  loginStoreKey: 'loginStore',
  settingsStoreKey: 'siteSettingsStore',
  notificationStoreKey: 'notificationStore',
  stateStoreKey: 'stateStore',
  messagesStoreKey: 'messagesStore'
};

export const allowedAbsenceFileExtensions = ['.jpeg', '.jpg', '.jfif', '.pdf', '.png', '.doc', '.docx'];
export const allowedMessageFileExtensions = ['.jpeg', '.jpg', '.jfif', '.pdf', '.png', '.doc', '.docx', '.xls', '.xlsx', '.csv', '.pptx', '.ppt', '.txt'];
export const imageFileExtensions = ['.jpeg', '.jpg', '.jfif', '.png'];
export const attachmentFileSizeLimitInMB = 10;
/**
 * the "customerId" value of "All customers" selection in customer selection context
 */
export const allCustomersId = 0;

export const notFoundPayslipId = -1;

export const HumHumAccessCustomRole: string = 'HumHumAccess';
