import { Helmet } from 'react-helmet-async';
import { Box, Container, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Avatar, Badge, Grid, TextField, MenuItem, Breadcrumbs, Link, CircularProgress, Button, Zoom, IconButton, Menu, Select, InputLabel, FormControl, SelectChangeEvent, Icon, Tooltip, AutocompleteChangeDetails, AutocompleteChangeReason, Autocomplete } from '@mui/material';
import { Link as RouterLink, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ChevronRightIcon from '../../icons/ChevronRight';
import { useTranslation } from 'react-i18next';
import { baseApi } from 'src/API/baseApi';
import { useEffect, useState, useCallback, useRef, FC } from 'react';
import CreateMessageForm from 'src/components/dashboard/messages/CreateMessageForm';
import format from 'date-fns/format';
import { useAppStore } from 'src/store/mobx';
import { AccessPermission, InternalRole, MessageCategoryType, MessageThreadAssignmentStatus, MessageThreadQueryParameters, MessageThreadSort, MessageThreadStatus, MessageThreadsFilters, MessageThreadsResponse, SLAPriority, SortDirection, UserReferenceDto, UserRole } from 'src/types/apiSchemas';
import { getInitials } from 'src/utils/getInitial';
import { LoadingButton } from '@mui/lab';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { allCustomersId } from 'src/constants';
import { MoreVert, SwapVert, EmailOutlined, ClosedCaption, Computer } from '@mui/icons-material';
import { observer } from 'mobx-react';
import CommonModal from 'src/components/common/CommonModal';
import stateStore, { MessageView } from 'src/store/mobx/stateStore';
import CommonDialog from 'src/components/common/CommonDialog';
import toast from 'react-hot-toast';
import useScroll from 'src/hooks/useScroll';
import EditIcon from '@mui/icons-material/Edit';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import ScrollBar from 'react-perfect-scrollbar';
import messageStore from 'src/store/mobx/messageStore';
import SearchableSelect from 'src/components/SearchableSelect';
import { GetInternalRoleTranslation, GetUserRoleTranslation } from 'src/utils/roleUtils';
import { GenericAbortSignal } from 'axios';

const getTranslation = (key: string) => {
  const { t } = useTranslation();
  return t(key);
};

const PriorityIcon = ({ priority }: { priority: SLAPriority }) => {
  const { t } = useTranslation();
  switch (priority) {
    case SLAPriority.Low:
      return <Tooltip title={t('MessageThreadPriorityLow')}><Looks3OutlinedIcon sx={{ opacity: 0.2 }} /></Tooltip>;
    case SLAPriority.Normal:
      return <Tooltip title={t('MessageThreadPriorityNormal')}><LooksTwoOutlinedIcon sx={{ opacity: 0.7 }} /></Tooltip>;
    case SLAPriority.High:
      return <Tooltip title={t('MessageThreadPriorityHigh')}><LooksOneIcon /></Tooltip>;
    default:
      return null;
  }
};

const MessageSender = ({ message, unreadThreads, hasDraft }: { message: MessageThreadsResponse, unreadThreads: string[], hasDraft: boolean }) => {
  const isUnread = unreadThreads.includes(message.messageThreadUniqueId);
  const fontWeight = isUnread ? 700 : 400;
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center', maxWidth: '20vw' }}>
      <div>
        {isUnread ? (
          <Badge
            badgeContent=""
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            color="primary"
            overlap="circular"
          >
            <Avatar
              src={message.starterProfilePictureUrl}
              alt={message.starterName}
            >
              {getInitials(message.starterName)}
            </Avatar>
          </Badge>
        ) : (
          <Avatar
            src={message.starterProfilePictureUrl}
            alt={message.starterName}
          >
            {getInitials(message.starterName)}
          </Avatar>
        )}
      </div>
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <div>
          <Typography sx={{ fontWeight: isUnread ? 700 : 600 }}>
            {message.subject}
          </Typography>
        </div>
        <div>
          <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight }}>
            {message.latestMessage.senderName}
            :
            {' '}
            {message.latestMessage.isDeleted ? <i>{getTranslation('DeletedMessage')}</i> : message.latestMessage.content}
          </Typography>
        </div>
        {hasDraft && <div style={{ fontStyle: 'italic', marginTop: 10, opacity: 0.7 }}>{t('MessagesPageDraftSaved')}</div>}
      </div>
    </div>
  );
};

interface MessageCategoryData {
  categoryName: string;
  categoryId: number;
  customerId: number;
}

interface MessageCustomerData {
  customerName: string;
  customerId: number;
}

interface AssigneeData {
  id: number;
  name?: string;
}

interface RelatedCompanyData {
  relatedCompanyId: number;
  relatedCompanyName: string;
}

interface MessagesProps {
  quickSearch?: QuickSearch;
}

export enum QuickSearch {
  AllUnsolved = 'AllUnsolved',
  MyUnsolved = 'MyUnsolved',
  NotAssigned = 'NotAssigned',
  AllSolved = 'AllSolved',
  AllMessages = 'AllMessages'
}

export enum MessageThreadHeader {
  Status = 'Status',
  Handler = 'Handler',
  Category = 'Category',
  SLA = 'SLA',
  Company = 'Company',
  Created = 'Created',
  Latest = 'Latest'
}

const SortIcon = ({ active }: { active?: boolean }) => <Icon sx={{ opacity: active ? 1 : 0.2 }}><SwapVert sx={{ width: '20px' }} /></Icon>;
const uniqueByProperty = (array: any[], property) => {
  const map = new Map();
  return array.filter(item => {
    if (!map.has(item[property])) {
      map.set(item[property], true);
      return true;
    }
    return false;
  });
};

const useAssignees = (assigneeIds: (number | string)[], customerId?: number) => {
  const [assignees, setAssignees] = useState<MessageThreadsFilters>({
    assigneeFilters: [],
    customerAssigneeFilters: []
  } as MessageThreadsFilters);

  useEffect(() => {
    const fetchAssigness = async (ids: number[]) => {
      try {
        const res = await baseApi.getAssigneeFilters(customerId, null, ids);
        setAssignees(res);
      } catch (err) {
        console.log(err);
      }
    };

    const ids = assigneeIds.map(a => parseInt(`${a}`, 10)).filter(a => !isNaN(a));

    if (ids.length > 0) {
      fetchAssigness(ids);
    }

  }, [assigneeIds.join(), customerId]);

  return assignees;
};

const useUserMentions = (userIds: number[], customerId?: number) => {
  const [users, setUsers] = useState<UserReferenceDto[]>([]);

  useEffect(() => {
    const fetchAssigness = async (ids: number[]) => {
      try {
        const res = await baseApi.getUserMentionFilters(customerId, null, ids);
        setUsers(res);
      } catch (err) {
        console.log(err);
      }
    };
    if (userIds.length > 0) {
      fetchAssigness(userIds);
    }

  }, [userIds.join(), customerId]);

  return users;
};

const Messages: FC<MessagesProps> = observer(({ quickSearch }) => {
  const defaultPageSize = 20;
  const { t } = useTranslation();
  const [messageThreads, setMessageThreads] = useState<MessageThreadsResponse[]>([]);
  const [messageThreadsNextPage, setMessageThreadsNextPage] = useState<MessageThreadsResponse[]>([]);
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { messagesAssignee: { [user.customerId]: messagesAssignee = [] }, setMessagesAssignee } = stateStore;
  const [includeBothAssignees, setIncludeBothAssignees] = useState(false);
  const { messagesCustomerAssignee: { [user.customerId]: messagesCustomerAssignee = [] }, setMessagesCustomerAssignee } = stateStore;
  const { messagesSearch: { [user.customerId]: messagesSearch = '' }, setMessagesSearch } = stateStore;
  const { messagesStatus: { [user.customerId]: messagesStatus = [] }, setMessagesStatus } = stateStore;
  const { messagesCategory: { [user.customerId]: messagesCategory = [] }, setMessagesCategory } = stateStore;
  const { messagesCategoryType: { [user.customerId]: messagesCategoryType = [] }, setMessagesCategoryType } = stateStore;
  const { messagesCustomer: { [user.customerId]: messagesCustomer = [] }, setMessagesCustomer } = stateStore;
  const { messagesSort: { [user.customerId]: messagesSort = MessageThreadSort.LastMessage }, setMessagesSort } = stateStore;
  const { messagesSortDirection: { [user.customerId]: messagesSortDirection = SortDirection.Desc }, setMessagesSortDirection } = stateStore;
  const { messagesSLA: { [user.customerId]: messagesSLA = [] }, setMessagesSLA } = stateStore;
  const { messagesRelatedCompany: { [user.customerId]: messagesRelatedCompany = [] }, setMessagesRelatedCompany } = stateStore;
  const { messagesResponsibleInternalRole: { [user.customerId]: messagesResponsibleInternalRole = [] }, setMessagesResponsibleInternalRole } = stateStore;
  const { hiddenHeaders: { [user.customerId]: hiddenHeaders = [] }, setHiddenHeaders } = stateStore;
  const { messagesUserMention: { [user.customerId]: messagesUserMention = [] }, setMessagesUserMention } = stateStore;
  const { messagesUserRoleMention: { [user.customerId]: messagesUserRoleMention = [] }, setMessagesUserRoleMention } = stateStore;
  const { messagesInternalRoleMention: { [user.customerId]: messagesInternalRoleMention = [] }, setMessagesInternalRoleMention } = stateStore;
  const [searchInputValue, setSearchInputValue] = useState(messagesSearch);
  const [loading, setLoading] = useState(false);
  const [loadingUnreadMessages] = useState(false);
  const [unreadThreadsIdLoading, setUnreadThreadsIdLoading] = useState<string[]>([]);
  const [eof, setEof] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryData, setCategoryData] = useState<MessageCategoryData[]>([]);
  const [customerData, setCustomerData] = useState<MessageCustomerData[]>([]);
  const [assigneeData, setAssigneeData] = useState<AssigneeData[]>([]);
  const [relatedCompanyData, setRelatedCompanyData] = useState<RelatedCompanyData[]>([]);
  const { messageViews = [], saveMessageView, deleteMessageView } = stateStore;
  const [deleteFilterSetConfirm, setDeleteFilterSetConfirm] = useState(false);
  const { messageViewName } = useParams();
  const messageView = messageViewName ? messageViews.find(f => f.customerId === user.customerId && f.urlName === messageViewName) : undefined;
  const [isViewNameEditMode, setIsViewNameEditMode] = useState(false);
  const [viewName, setViewName] = useState(messageView?.name);
  const [isSlaCategoriesUsed, setIsSlaCategoriesUsed] = useState<boolean>(false);
  const {
    draft
  } = messageStore;

  const { messagesLastView: { [user.customerId]: messagesLastView = '' }, setMessagesLastView } = stateStore;
  const currentViewString = quickSearch ? quickSearch : messageView ? `view/${messageView.name}` : '';

  const [unreadThreads, setUnreadThreads] = useState<string[]>([]);
  const loadMoreButton = useRef(null);
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuMessageThread, setMenuMessageThread] = useState<null | MessageThreadsResponse>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const unreadMessageThreads = appStore.notificationStore.getAllUnreadMessageThreads()?.flatMap((n) => n) ?? [];
  const navigationType = useNavigationType();

  const { messagesPageNumber: { [currentViewString]: messagesPageNumber = 1 }, setMessagesPageNumber } = stateStore;
  const loadPagesUntil = navigationType === 'POP' ? messagesPageNumber : 1;
  const firstLoadDone = useRef(false);
  const filtersChanged = useRef(false);
  const [pageNumber, setPageNumber] = useState<number>(loadPagesUntil);
  const { saveScroll, restoreScroll, resetScroll } = useScroll(currentViewString);
  const [isTeamMessage, setIsTeamMessage] = useState(false);

  useEffect(() => {
    return () => {
      saveScroll();
    };
  }, [currentViewString, pageNumber]);

  const applyFilters = (mv: MessageView) => {
    setSearchInputValue(mv.messagesSearch);
    setMessagesSearch(mv.messagesSearch, user.customerId);
    setMessagesAssignee(mv.messagesAssignee, user.customerId);
    setMessagesCustomerAssignee(mv.messagesCustomerAssignee, user.customerId);
    setMessagesCategory(mv.messagesCategory, user.customerId);
    setMessagesStatus(mv.messagesStatus, user.customerId);
    setMessagesCustomer(mv.messagesCustomer, user.customerId);
    setMessagesSort(mv.messagesSort, user.customerId);
    setMessagesSortDirection(mv.messagesSortDirection, user.customerId);
    setMessagesCategoryType(mv.messagesCategoryType, user.customerId);
    setMessagesSLA(mv.messagesSLA, user.customerId);
    setMessagesRelatedCompany(mv.messagesRelatedCompany, user.customerId);
    setMessagesResponsibleInternalRole(mv.messagesResponsibleInternalRole, user.customerId);
    setMessagesUserMention(mv.messagesUserMention, user.customerId);
    setMessagesUserRoleMention(mv.messagesUserRoleMention, user.customerId);
    setMessagesInternalRoleMention(mv.messagesInternalRoleMention, user.customerId);
  };

  useEffect(() => {
    setUnreadThreads(unreadMessageThreads);
  }, [unreadMessageThreads.join('')]);

  useEffect(() => {
    if (messageViewName && !messageView) {
      navigate('/404');
    }
  }, [messageViewName, messageView]);

  useEffect(() => {
    if (messageView && (currentViewString !== messagesLastView || navigationType !== 'POP')) {
      applyFilters(messageView);
      setViewName(messageView.name);
    }
  }, [messageView]);

  useEffect(() => {
    if (quickSearch && (currentViewString !== messagesLastView || navigationType !== 'POP')) {
      let assigneeFilter: (number | MessageThreadAssignmentStatus)[] = [];
      let includeBothAssigneesFilter = false;
      let searchFilter = '';
      let statusFilter: MessageThreadStatus[] = [];
      let categoryFilter: number[] = [];
      let customerFilter: string[] = [];
      let categoryType: MessageCategoryType[] = [];
      let slaFilter: SLAPriority[] = [];
      let relatedCompanyFilter: number[] = [];
      let responsibleInternalRoleFilter: InternalRole[] = [];

      switch (quickSearch) {
        case QuickSearch.AllUnsolved:
          statusFilter = [MessageThreadStatus.New, MessageThreadStatus.Open, MessageThreadStatus.Pending];
          break;
        case QuickSearch.MyUnsolved:
          assigneeFilter = [user.userId];
          includeBothAssigneesFilter = true;
          statusFilter = [MessageThreadStatus.New, MessageThreadStatus.Open, MessageThreadStatus.Pending];
          break;
        case QuickSearch.NotAssigned:
          assigneeFilter = [MessageThreadAssignmentStatus.NotAssigned];
          statusFilter = [MessageThreadStatus.New, MessageThreadStatus.Open, MessageThreadStatus.Pending];
          break;
        case QuickSearch.AllSolved:
          statusFilter = [MessageThreadStatus.Solved];
          break;
        case QuickSearch.AllMessages:
          break;
      }

      setMessagesAssignee(assigneeFilter, user.customerId);
      setIncludeBothAssignees(includeBothAssigneesFilter);
      setSearchInputValue(searchFilter);
      setMessagesSearch(searchFilter, user.customerId);
      setMessagesStatus(statusFilter, user.customerId);
      setMessagesCategory(categoryFilter, user.customerId);
      setMessagesCustomer(customerFilter, user.customerId);
      setMessagesCategoryType(categoryType, user.customerId);
      setMessagesSLA(slaFilter, user.customerId);
      setMessagesRelatedCompany(relatedCompanyFilter, user.customerId);
      setMessagesResponsibleInternalRole(responsibleInternalRoleFilter, user.customerId);
      setMessagesUserMention([], user.customerId);
      setMessagesUserRoleMention([], user.customerId);
      setMessagesInternalRoleMention([], user.customerId);
    }

  }, [quickSearch]);

  useEffect(() => {
    if (currentViewString != messagesLastView) {
      setMessagesLastView(currentViewString, user.customerId);
      setPageNumber(loadPagesUntil);
      firstLoadDone.current = false;
    }
  }, [quickSearch, messageView]);

  const handleMessageMenuClick = (event: React.MouseEvent<HTMLButtonElement>, messageThread: MessageThreadsResponse) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuMessageThread(messageThread);
  };

  const handleMessageMenuClose = () => {
    setMenuMessageThread(null);
    setMenuAnchorEl(null);
  };

  const auth: IAuthorizationService = new AuthorizationService();

  const isMessagingEnabledForAnyCustomer = auth.get(user).anyCustomerHas(AccessPermission.MessagingAccess).verify();
  const isMessagingEnabledForSelectedCustomer = auth.get(user).has(AccessPermission.MessagingAccess).verify();
  const hasAnyInternalRole = auth.get(user).hasAnyInternalRole(user.customerId).verify();
  const isCustomerHR = auth.get(user).is(UserRole.HR, user.customerId).verify();
  const isMainUser = auth.get(user).isMainUser;
  const canFilterBySLA = hasAnyInternalRole || isCustomerHR || isMainUser;

  const getSelectedStatuses = () => {
    return typeof messagesStatus === 'string' ? [messagesStatus] : messagesStatus;
  };

  const getSelectedCategories = () => {
    return typeof messagesCategory === 'number' ? [messagesCategory] : messagesCategory;
  };

  const getSelectedAssignees = () => {
    return typeof messagesAssignee === 'number' ? [messagesAssignee] : messagesAssignee;
  };

  const getSelectedCustomerAssignees = () => {
    return typeof messagesCustomerAssignee === 'number' ? [messagesCustomerAssignee] : messagesCustomerAssignee;
  };

  const getSelectedCustomers = () => {
    return typeof messagesCustomer === 'string' ? [messagesCustomer] : messagesCustomer;
  };

  const getSelectedRelatedCompanies = () => {
    return typeof messagesRelatedCompany === 'number' ? [messagesRelatedCompany] : messagesRelatedCompany;
  };

  const flipSortDirection = () => {
    setMessagesSortDirection(messagesSortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc, user.customerId);
  };

  if ((user?.customerId === 0 && !isMessagingEnabledForAnyCustomer) || (user?.customerId !== 0 && !isMessagingEnabledForSelectedCustomer)) {
    navigate('/401');
  }

  const getMessageThreads = async (signal?: GenericAbortSignal) => {
    try {
      setEof(true);
      const loadPage = firstLoadDone.current && !filtersChanged.current ? pageNumber : 1;
      const pageSize = firstLoadDone.current || filtersChanged.current ? defaultPageSize : defaultPageSize * loadPagesUntil;
      const params: MessageThreadQueryParameters = {
        searchString: messagesSearch.startsWith('@') ? '' : messagesSearch,
        status: getSelectedStatuses(),
        category: user.customerId === allCustomersId ? undefined : getSelectedCategories(),
        assignmentStatus: getSelectedAssignees().filter(a => a === MessageThreadAssignmentStatus.Assigned || a === MessageThreadAssignmentStatus.NotAssigned) as MessageThreadAssignmentStatus[],
        assigneeId: (getSelectedAssignees().filter(a => a !== MessageThreadAssignmentStatus.Assigned && a !== MessageThreadAssignmentStatus.NotAssigned) as number[]),
        includeBothAssignees: includeBothAssignees,
        customerId: user?.customerId === 0 ? getSelectedCustomers() || undefined : [`${user?.customerId}`],
        includeAllCustomers: (user?.customerId === allCustomersId && getSelectedCustomers().length < 1) || undefined,
        pageNumber: loadPage,
        pageSize,
        sort: messagesSort,
        sortDirection: messagesSortDirection,
        messageCategoryTypes: user.customerId === allCustomersId ? messagesCategoryType : undefined,
        slaPriority: canFilterBySLA ? messagesSLA : [],
        relatedCompany: getSelectedRelatedCompanies(),
        responsibleInternalRoles: user.isConsultantUser ? messagesResponsibleInternalRole : [],
        userMention: messagesUserMention,
        userRoleMention: messagesUserRoleMention,
        internalRoleMention: messagesInternalRoleMention
      };

      let response: MessageThreadsResponse[];
      if (loadPage === 1) {
        setLoading(true);
        response = await baseApi.getMessageThreads(params, signal);
        firstLoadDone.current = true;
      } else {
        // pagination: adding next results to current results
        response = [...messageThreads, ...messageThreadsNextPage];
      }

      setMessageThreads(response);
      setLoading(false);
      const pageOffset = loadPage > loadPagesUntil || filtersChanged.current ? loadPage : loadPagesUntil;
      setMessagesPageNumber(pageOffset, currentViewString);
      // loading next page behind the scenes
      const nextPage = pageOffset + 1;
      const paramsNextPage = { ...params, pageNumber: nextPage, pageSize: defaultPageSize };
      const responseNextPage = await baseApi.getMessageThreads(paramsNextPage, signal);
      setMessageThreadsNextPage(responseNextPage);
      // eof determines if 'load next page' should be visible
      setEof(!(responseNextPage?.length > 0));

      if (navigationType === 'POP') {
        restoreScroll();
      }
      filtersChanged.current = false;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getThreadsFilters = async () => {
      var filters = await baseApi.getMessageThreadsFilters(
        user?.customerId === allCustomersId ? undefined : `${user?.customerId}`
      );

      const newCustomerData: MessageCustomerData[] = filters.customerCategoryFilters.map((f) => ({
        customerId: f.customerId,
        customerName: f.customerName
      }));
      setCustomerData(newCustomerData.sort((a, b) => a.customerName.localeCompare(b.customerName)));

      const newCategoryData: MessageCategoryData[] = filters.customerCategoryFilters.flatMap((f) => (
        f.categories.map((cf) => ({
          categoryId: cf.categoryId,
          categoryName: cf.categoryName,
          customerId: f.customerId
        }))
      ));
      setCategoryData(newCategoryData.sort((a, b) => a.categoryName.localeCompare(b.categoryName)));

      if (filters.customerCategoryFilters.some(f => f.categories.some(c => c.slaMonitoring))) {
        setIsSlaCategoriesUsed(true);
      }

      const newRelatedCompanyData: RelatedCompanyData[] = filters.relatedCompanyFilters.map((f) => ({
        relatedCompanyId: f.relatedCompanyId,
        relatedCompanyName: f.relatedCompanyName
      })).sort((a, b) => a.relatedCompanyName.localeCompare(b.relatedCompanyName));
      setRelatedCompanyData(newRelatedCompanyData);
    };
    getThreadsFilters();
  }, [user?.customerId]);

  const handleStatusFilterChange = (event: SelectChangeEvent<MessageThreadStatus[]>) => {
    setPageNumber(1);
    setMessagesStatus(event.target.value as MessageThreadStatus[], user.customerId);
  };

  const handleCustomerFilterChange = (event, value: { label: string, value: string }[]) => {
    setPageNumber(1);
    setMessagesCustomer(value.map(v => v.value), user.customerId);
  };

  const handleCategoryFilterChange = (event, value: { label: string, value: number }[]) => {
    setPageNumber(1);
    setMessagesCategory(value.map(v => v.value), user.customerId);
  };

  const handleCategoryTypeChange = (event, value: { label: string, value: MessageCategoryType }[]) => {
    setPageNumber(1);
    setMessagesCategoryType(value.map(v => v.value), user.customerId);
  };

  const handleSLAFilterChange = (event: SelectChangeEvent<SLAPriority[]>) => {
    setPageNumber(1);
    setMessagesSLA(event.target.value as SLAPriority[], user.customerId);
  };

  const handleUserMentionFilterChange = (event, value: { label: string, value: number }[]) => {
    setPageNumber(1);
    setMessagesUserMention(value.map(v => v.value), user.customerId);
  };

  const handleUserRoleMentionFilterChange = (event, value: { label: string, value: UserRole }[]) => {
    setPageNumber(1);
    setMessagesUserRoleMention(value.map(v => v.value), user.customerId);
  };

  const handleInternalRoleMentionFilterChange = (event, value: { label: string, value: InternalRole }[]) => {
    setPageNumber(1);
    setMessagesInternalRoleMention(value.map(v => v.value), user.customerId);
  };

  const handlehiddenHeadersChange = (event: SelectChangeEvent<MessageThreadHeader[]>) => {
    setHiddenHeaders(event.target.value as MessageThreadHeader[], user?.customerId);
  };

  const handleAssigneeFilterChange = (
    event: React.SyntheticEvent,
    value: { label: string, value: string | number | MessageThreadAssignmentStatus }[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<{ label: string, value: string | number | MessageThreadAssignmentStatus }>) => {

    setPageNumber(1);
    const addedItem = details?.option;

    if (!addedItem) {
      setMessagesAssignee([], user.customerId);
    } else if (addedItem.value === MessageThreadAssignmentStatus.Assigned || addedItem.value === MessageThreadAssignmentStatus.NotAssigned) {
      setMessagesAssignee([addedItem.value], user.customerId);
    } else {
      const assigneePersons = value.filter(v => v.value !== MessageThreadAssignmentStatus.Assigned && v.value !== MessageThreadAssignmentStatus.NotAssigned);
      setMessagesAssignee(assigneePersons.map(v => v.value), user.customerId);
      setAssigneeData(assigneePersons.map(v => ({ id: parseInt(`${v.value}`, 10), name: v.label })));
    }
  };

  const handleRelatedCompanyFilterChange = (event: SelectChangeEvent<number[]>) => {
    setPageNumber(1);
    setMessagesRelatedCompany(event.target.value as number[], user.customerId);
  };

  const handleResponsibleInternalRoleFilterChange = (event: SelectChangeEvent<InternalRole[]>) => {
    setPageNumber(1);
    setMessagesResponsibleInternalRole(event.target.value as InternalRole[], user.customerId);
  };

  const updateUnreadState = useCallback((threadId: string, markAsUnread: boolean) => {
    const unreadThreadsData = [...unreadThreads];
    const index = unreadThreadsData.indexOf(threadId);
    if (index === -1 && markAsUnread) {
      unreadThreadsData.push(threadId);
    } else if (index > -1 && !markAsUnread) {
      unreadThreadsData.splice(index, 1);
    }
    setUnreadThreads(unreadThreadsData);
  }, [unreadThreads]);

  const handleToggleRead = useCallback(async (threadId: string, markAsUnread: boolean) => {
    try {
      setUnreadThreadsIdLoading((prev) => [...prev, threadId]);
      await baseApi.markThreadAsRead(threadId, markAsUnread);
      setUnreadThreadsIdLoading((prev) => prev.filter((id) => id !== threadId));
    } catch (error) {
      console.error('Failed to update read status:', error);
      setUnreadThreadsIdLoading((prev) => prev.filter((id) => id !== threadId));
    }
    appStore.notificationStore.refreshUnreadMessagesNotifications(user);
  }, [unreadThreads, unreadThreadsIdLoading]);

  useEffect(() => {
    const controller = new AbortController();
    const timeout = setTimeout(() => {
      getMessageThreads(controller.signal);
    }, 500);
    return () => {
      clearTimeout(timeout);
      controller.abort();
    };
  }, [getSelectedStatuses().join(), getSelectedCategories().join(), getSelectedAssignees().join(), getSelectedCustomers().join(), messagesSort, messagesSortDirection, messagesSearch, messagesCategoryType.join(), messagesSLA.join(), getSelectedRelatedCompanies().join(), messagesResponsibleInternalRole.join(), messagesUserMention.join(), messagesUserRoleMention.join(), messagesInternalRoleMention.join(), pageNumber]);

  useEffect(() => {
    if (firstLoadDone.current) {
      filtersChanged.current = true;
      resetScroll();
    }
    firstLoadDone.current = false;
  }, [getSelectedStatuses().join(), getSelectedCategories().join(), getSelectedAssignees().join(), getSelectedCustomers().join(), messagesSort, messagesSortDirection, messagesSearch, messagesCategoryType.join(), messagesSLA.join(), getSelectedRelatedCompanies().join(), messagesResponsibleInternalRole.join(), messagesUserMention.join(), messagesUserRoleMention.join(), messagesInternalRoleMention.join()]);

  const markMessageReadButtonContent = (message: MessageThreadsResponse): JSX.Element => {
    if (unreadThreadsIdLoading.includes(message.messageThreadUniqueId)) {
      return <CircularProgress size={24} />;
    }

    if (unreadThreads.includes(message.messageThreadUniqueId)) {
      return <>{t('MessagesMarkAsRead')}</>;
    }
    return <>{t('MessagesMarkAsUnread')}</>;
  };

  const filtersActive = messagesSearch.length > 0 || getSelectedCustomers().length > 0 || getSelectedStatuses().length > 0 || getSelectedCategories().length > 0 || getSelectedAssignees().length > 0 || messagesCategoryType.length > 0 || messagesSLA.length > 0 || getSelectedRelatedCompanies().length > 0 || messagesResponsibleInternalRole.length > 0 || messagesUserMention.length > 0 || messagesUserRoleMention.length > 0 || messagesInternalRoleMention.length > 0;

  function getMessageViewTranslation() {
    if (messageView) {
      return messageView.name;
    }

    switch (quickSearch) {
      case QuickSearch.AllUnsolved:
        return t('MessagesAllUnsolved');
      case QuickSearch.MyUnsolved:
        return t('MessagesMyUnsolved');
      case QuickSearch.NotAssigned:
        return t('MessageNotAssigned');
      case QuickSearch.AllSolved:
        return t('MessagesAllSolved');
      default:
        return t('MessagesAllMessages');
    }
  }

  const messageAssignee = (message: MessageThreadsResponse): string => {
    if (user.isConsultantUser) {
      // show either assignee or customer assignee name for constultant user, if filtering by both assignees
      // otherwise only assignee name
      return includeBothAssignees ? message.assignee?.name ?? message.customerAssignee?.name : message.assignee?.name;
    } else {
      // show customer assignee name for non consultant users
      return message.customerAssignee?.name;
    }
  };

  const [assigneeSearch, setAssigneeSearch] = useState('');
  const [assigneeSearchData, setAssigneeSearchData] = useState<AssigneeData[]>([]);
  const currentSelectedAssignees = [...getSelectedAssignees()].map(v => v.toString());
  const missingOptions = currentSelectedAssignees.filter(a => !assigneeSearchData.map(d => d.id.toString()).includes(a) && !assigneeData.map(d => d.id.toString()).includes(a));
  const missingAssignees = useAssignees(missingOptions, user.customerId === allCustomersId ? undefined : user.customerId);
  const addAssignees = user.isConsultantUser ? missingAssignees.assigneeFilters.map(a => ({ label: a.assigneeName, value: a.assigneeId })) : missingAssignees.customerAssigneeFilters.map(a => ({ label: a.customerAssigneeName, value: a.customerAssigneeId }));
  const assigneePersons = [
    ...assigneeSearchData.map(a => ({ label: a.name, value: a.id })),
    ...assigneeData.map(a => ({ label: a.name, value: a.id })),
    ...addAssignees].sort((a, b) => a.label.localeCompare(b.label));
  const assigneeOptions = uniqueByProperty([
    { label: t('MessageThreadAssignmentStatusNotAssigned'), value: MessageThreadAssignmentStatus.NotAssigned },
    { label: t('MessageThreadAssignmentStatusAssigned'), value: MessageThreadAssignmentStatus.Assigned },
    ...assigneePersons
  ], 'value');

  const [userMentionSearchData, setUserMentionSearchData] = useState<UserReferenceDto[]>([]);

  const userMentions = useUserMentions(messagesUserMention, user.customerId === allCustomersId ? undefined : user.customerId);
  const userMentionOptions = uniqueByProperty([...userMentionSearchData, ...userMentions].map(m => ({ label: m.name, value: m.id })), 'value');
  const selectedUsers = userMentionOptions.filter(u => messagesUserMention.includes(u.value));

  const internalRoleOptions = Object.keys(InternalRole).map((x) => ({ value: x, label: GetInternalRoleTranslation(t, x as InternalRole) })).sort((a, b) => a.label.localeCompare(b.label));
  const userRoleOptions = Object.keys(UserRole).map((x) => ({ value: x, label: GetUserRoleTranslation(t, x as UserRole) })).sort((a, b) => a.label.localeCompare(b.label));

  const selectedInternalRoles = internalRoleOptions.filter(r => messagesInternalRoleMention.includes(r.value as InternalRole));
  const selectedUserRoles = userRoleOptions.filter(r => messagesUserRoleMention.includes(r.value as UserRole));

  const responsibleInternalRoleOptions = internalRoleOptions;

  const selectedAssignees = assigneeOptions.filter(ao => getSelectedAssignees().map(a => a.toString()).includes(ao.value.toString()));

  const messageCategoryTypeOptions = Object.values(MessageCategoryType).sort((a, b) => t(`MessageCategoryType${a}`).localeCompare(t(`MessageCategoryType${b}`))).map(c => ({ value: c, label: t(`MessageCategoryType${c}`) }));
  const selectedMessageCategoryTypes = messageCategoryTypeOptions.filter(mct => messagesCategoryType.includes(mct.value));

  const messageCategoryOptions = categoryData.map(c => ({ label: c.categoryName, value: c.categoryId }));
  const selectedMessageCategories = messageCategoryOptions.filter(mco => getSelectedCategories().includes(mco.value));

  const customerOptions = customerData.map(c => ({ label: c.customerName, value: `${c.customerId}` }));
  const selectedCustomers = customerOptions.filter(co => getSelectedCustomers().includes(co.value));

  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const autoCompleteOptions = [...userMentionOptions, ...internalRoleOptions, ...userRoleOptions];
  const selectedAutocompleteValues = [...selectedUsers, ...selectedUserRoles, ...selectedInternalRoles];

  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{t('MessagesPageHelmet')}</title>
      </Helmet>
      <Box
        mx={2}
        py={8}
      >
        <Container maxWidth="xl">
          <Typography
            color="textPrimary"
            variant="h5"
            sx={{ mb: 3 }}
          >
            {t('MessagesPageTitle')}
          </Typography>
          <Breadcrumbs
            sx={{
              paddingLeft: '1%',
            }}
            aria-label="breadcrumb"
            separator={(
              <ChevronRightIcon
                fontSize="small"
                sx={{ mb: 5 }}
              />
            )}
          >
            <Grid
              item
              sx={{ mb: 5 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard/landingpage"
                variant="subtitle2"
              >
                {t('LandingPagePageTitle')}
              </Link>
            </Grid>
            <Grid item>
              <Typography
                color="text.primary"
                sx={{ mb: 5 }}
                variant="subtitle2"
              >
                {t('MessagesPageTitle')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color="text.primary"
                sx={{ mb: 5 }}
                variant="subtitle2"
              >
                {getMessageViewTranslation()}
              </Typography>
            </Grid>
          </Breadcrumbs>
          <Button
            variant="contained"
            sx={{ marginBottom: 5 }}
            onClick={() => {
              setIsTeamMessage(false);
              setIsModalOpen(true);
            }}
          >
            {t('MessagesPageMessageSendNewMessage')}
          </Button>
          {user.isManager && user.customerId === user.employmentCustomerId && (
            <Button
              variant="contained"
              sx={{ marginBottom: 5, marginLeft: 1 }}
              onClick={() => {
                setIsTeamMessage(true);
                setIsModalOpen(true);
              }}
            >
              {t('MessagesPageSendTeamMessage')}
            </Button>
          )}
          <Paper sx={{ overflow: 'hidden' }}>
            <Grid
              container
              padding={3}
              pb={0}
              justifyContent="space-between"
            >
              <Grid item>
                {
                  messageView ?
                    (
                      <>
                        {isViewNameEditMode ? (
                          <TextField
                            variant="outlined"
                            value={viewName}
                            onChange={(e) => setViewName(e.target.value)}
                            onBlur={() => setIsViewNameEditMode(false)}
                            autoFocus
                          />
                        ) : (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6">{viewName}</Typography>
                            <IconButton onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setIsViewNameEditMode(true);
                            }}
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        )}
                      </>
                    )
                    :
                    (
                      <Typography variant="h6">
                        {getMessageViewTranslation()}
                      </Typography>
                    )
                }
              </Grid>
              {messageView && (
                <Grid item>
                  <Button variant="skinny"
                    sx={{ marginRight: 1 }}
                    disabled={!viewName}
                    onClick={() => {
                      const updatedFilter: MessageView = {
                        ...messageView,
                        name: viewName,
                        messagesSearch,
                        messagesAssignee: getSelectedAssignees(),
                        messagesCustomerAssignee: getSelectedCustomerAssignees(),
                        messagesCategory: getSelectedCategories(),
                        messagesStatus: getSelectedStatuses(),
                        messagesCustomer: getSelectedCustomers(),
                        messagesSort,
                        messagesSortDirection,
                        messagesCategoryType,
                        messagesSLA,
                        messagesRelatedCompany: getSelectedRelatedCompanies(),
                        messagesResponsibleInternalRole,
                        messagesUserMention,
                        messagesUserRoleMention,
                        messagesInternalRoleMention
                      };
                      saveMessageView(updatedFilter);
                      toast.success(t('SavingSucceeded'));
                    }}
                  >
                    {t('GenericButtonSave')}
                  </Button>
                  <Button variant="skinny"
                    sx={{ marginRight: 1 }}
                    onClick={() => {
                      setDeleteFilterSetConfirm(true);
                    }}
                  >
                    {t('DeleteButtonTitle')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid
              gap={3}
              container
              paddingLeft={3}
              paddingRight={3}
            >
              <Grid item xs={12}>
                <Autocomplete
                  forcePopupIcon={false}
                  disableClearable
                  open={autocompleteOpen}
                  multiple
                  value={selectedAutocompleteValues}
                  options={autoCompleteOptions}
                  inputValue={searchInputValue}
                  onInputChange={async (event, value, reason) => {
                    if (reason === 'input') {
                      setSearchInputValue(value);

                      if (value.startsWith('@')) {
                        setAutocompleteOpen(true);
                      } else {
                        setMessagesSearch(value, user.customerId);
                      }

                      try {
                        if (value.length > 3 && value.startsWith('@')) {
                          const res: UserReferenceDto[] = await baseApi.getUserMentionFilters(user.customerId === allCustomersId ? undefined : user.customerId, value.substring(1));
                          setUserMentionSearchData(res);
                        } else {
                          setUserMentionSearchData([]);
                        }

                      } catch (err) {
                        console.log(err);
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('MessagesPageMessageSearchThreads')}
                      onBlur={() => {
                        setAutocompleteOpen(false);
                      }}
                      onFocus={() => {
                        if (searchInputValue.startsWith('@'))
                          setAutocompleteOpen(true);
                      }}
                    />
                  )}
                  onChange={(e, value, reason, details) => {
                    if (reason === 'selectOption') {
                      setSearchInputValue('');
                    }
                    setAutocompleteOpen(false);
                    if (typeof details?.option?.value === 'number') {
                      if (reason === 'selectOption')
                        handleUserMentionFilterChange(null, [...messagesUserMention.map(m => ({ value: m })), details.option]);
                      else if (reason === 'removeOption')
                        handleUserMentionFilterChange(null, messagesUserMention.filter(m => m !== details.option.value).map(m => ({ value: m, label: '' })));
                    }
                    if (Object.values(UserRole).includes(details?.option?.value)) {
                      if (reason === 'selectOption')
                        handleUserRoleMentionFilterChange(null, [...messagesUserRoleMention.map(m => ({ value: m })), details.option]);
                      else if (reason === 'removeOption')
                        handleUserRoleMentionFilterChange(null, messagesUserRoleMention.filter(m => m !== details.option.value).map(m => ({ value: m, label: '' })));

                    }
                    if (Object.values(InternalRole).includes(details?.option?.value)) {
                      if (reason === 'selectOption')
                        handleInternalRoleMentionFilterChange(null, [...messagesInternalRoleMention.map(m => ({ value: m })), details.option]);
                      else if (reason === 'removeOption')
                        handleInternalRoleMentionFilterChange(null, messagesInternalRoleMention.filter(m => m !== details.option.value).map(m => ({ value: m, label: '' })));

                    }
                  }}
                  filterOptions={(options, state) => {
                    if (state.inputValue.startsWith('@')) {
                      const val = state.inputValue.substring(1).toLowerCase();
                      return options.filter(o => o.label.toLowerCase().includes(val));
                    }
                    return [];
                  }}
                />
              </Grid>
              {user?.customerId === allCustomersId && (
                <Grid
                  item
                  md={2}
                  xs={3}
                >
                  <SearchableSelect
                    value={selectedCustomers}
                    options={customerOptions}
                    label={t('Customer')}
                    onChange={handleCustomerFilterChange}
                    disableCloseOnSelect
                  />
                </Grid>
              )}
              <Grid
                item
                md={2}
                xs={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="statusLabel">{t('MessagesPageMessageStatus')}</InputLabel>
                  <Select labelId="statusLabel"
                    multiple
                    value={getSelectedStatuses()}
                    label={t('MessagesPageMessageStatus')}
                    onChange={handleStatusFilterChange}
                  >
                    <MenuItem
                      key="New"
                      value={MessageThreadStatus.New}
                    >
                      {t('MessageThreadStatusNew')}
                    </MenuItem>
                    <MenuItem
                      key="Open"
                      value={MessageThreadStatus.Open}
                    >
                      {t('MessageThreadStatusOpen')}
                    </MenuItem>
                    <MenuItem
                      key="Pending"
                      value={MessageThreadStatus.Pending}
                    >
                      {t('MessageThreadStatusPending')}
                    </MenuItem>
                    <MenuItem
                      key="Solved"
                      value={MessageThreadStatus.Solved}
                    >
                      {t('MessageThreadStatusSolved')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={2}
                xs={3}
              >
                {user.customerId === allCustomersId ? (
                  <SearchableSelect
                    options={messageCategoryTypeOptions}
                    value={selectedMessageCategoryTypes}
                    onChange={handleCategoryTypeChange}
                    label={t('MessageCategoryType')}
                    disableCloseOnSelect
                  />
                ) : (
                  <SearchableSelect
                    options={messageCategoryOptions}
                    value={selectedMessageCategories}
                    onChange={handleCategoryFilterChange}
                    label={t('MessagesPageMessageCategory')}
                    disableCloseOnSelect
                  />
                )}
              </Grid>
              <Grid
                item
                md={2}
                xs={3}
              >
                <SearchableSelect
                  label={t('MessagesPageThreadHandler')}
                  onChange={handleAssigneeFilterChange}
                  options={assigneeOptions}
                  value={selectedAssignees}
                  disableCloseOnSelect
                  searchValue={assigneeSearch}
                  onSearchChange={async (e) => {
                    setAssigneeSearch(e.target.value);
                    try {
                      const res: MessageThreadsFilters = e.target.value.length > 2 ?
                        await baseApi.getAssigneeFilters(user.customerId === allCustomersId ? undefined : user.customerId, e.target.value) :
                        {
                          assigneeFilters: [],
                          customerAssigneeFilters: []
                        } as MessageThreadsFilters;

                      let newAssigneeData: AssigneeData[] = [];
                      if (user.isConsultantUser) {
                        newAssigneeData = res.assigneeFilters.map((f) => ({
                          id: f.assigneeId,
                          name: f.assigneeName
                        }));
                      } else {
                        newAssigneeData = res.customerAssigneeFilters.map((f) => ({
                          id: f.customerAssigneeId,
                          name: f.customerAssigneeName
                        }));
                      }
                      setAssigneeSearchData(newAssigneeData);
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                />
              </Grid>
              {user?.customerId !== 0 && relatedCompanyData.length > 0 && (
                <Grid
                  item
                  md={2}
                  xs={3}
                >
                  <FormControl fullWidth>
                    <InputLabel id="statusLabel">{t('Company')}</InputLabel>
                    <Select labelId="statusLabel"
                      multiple
                      value={getSelectedRelatedCompanies()}
                      label={t('Company')}
                      onChange={handleRelatedCompanyFilterChange}
                    >
                      {relatedCompanyData.map((c) => (
                        <MenuItem
                          key={c.relatedCompanyId}
                          value={c.relatedCompanyId}
                        >
                          {c.relatedCompanyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {canFilterBySLA && isSlaCategoriesUsed && (
                <Grid
                  item
                  md={2}
                  xs={3}
                >
                  <FormControl fullWidth>
                    <InputLabel id="SLALabel">{t('MessagesPageSLA')}</InputLabel>
                    <Select labelId="SLALabel"
                      multiple
                      value={messagesSLA}
                      label={t('MessagesPageSLA')}
                      onChange={handleSLAFilterChange}
                    >
                      <MenuItem key="Low" value={SLAPriority.Low}>{t('MessageThreadPriorityLow')}</MenuItem>
                      <MenuItem key="Normal" value={SLAPriority.Normal}>{t('MessageThreadPriorityNormal')}</MenuItem>
                      <MenuItem key="High" value={SLAPriority.High}>{t('MessageThreadPriorityHigh')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {user?.isConsultantUser && (
                <Grid
                  item
                  md={2}
                  xs={3}
                >
                  <FormControl fullWidth>
                    <InputLabel id="statusLabel">{t('MessageThreadHeadResponsibleInternalRoleTitle')}</InputLabel>
                    <Select labelId="statusLabel"
                      multiple
                      value={messagesResponsibleInternalRole}
                      label={t('MessageThreadHeadResponsibleInternalRoleTitle')}
                      onChange={handleResponsibleInternalRoleFilterChange}
                    >
                      {responsibleInternalRoleOptions.map((ir) => (
                        <MenuItem
                          key={ir.value}
                          value={ir.value}
                        >
                          {ir.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid
                item
                alignItems="stretch"
                style={{ display: 'flex' }}
              >
                <Zoom in={filtersActive}>
                  <Box sx={{ justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setMessagesCategory([], user.customerId);
                        setMessagesCategoryType([], user.customerId);
                        setMessagesStatus([], user.customerId);
                        setMessagesAssignee([], user.customerId);
                        setMessagesCustomer([], user.customerId);
                        setMessagesSearch('', user.customerId);
                        setMessagesSLA([], user.customerId);
                        setMessagesRelatedCompany([], user.customerId);
                        setSearchInputValue('');
                        setMessagesResponsibleInternalRole([], user.customerId);
                        setMessagesUserMention([], user.customerId);
                        setMessagesUserRoleMention([], user.customerId);
                        setMessagesInternalRoleMention([], user.customerId);
                        setPageNumber(1);
                      }}
                    >
                      {t('RemoveFiltersTitle')}
                    </Button>
                  </Box>
                </Zoom>
              </Grid>
            </Grid>
            <>
              <Grid
                container
                paddingRight={2}
                justifyContent={'flex-end'}
              >
                <Grid item
                  xs={3}
                >
                  <FormControl variant="standard" size="small" fullWidth>
                    <InputLabel id="hiddenHeadersLabel">{t('MessagesHiddenHeaders')}</InputLabel>
                    <Select labelId="hiddenHeadersLabel"
                      multiple
                      value={hiddenHeaders}
                      label={t('MessagesHiddenHeaders')}
                      onChange={handlehiddenHeadersChange}
                    >
                      <MenuItem
                        key='statusHidden'
                        value={MessageThreadHeader.Status}
                      >
                        {t('MessagesPageMessageStatus')}
                      </MenuItem>
                      <MenuItem
                        key='handlerHidden'
                        value={MessageThreadHeader.Handler}
                      >
                        {t('MessagesPageMessageHandler')}
                      </MenuItem>
                      <MenuItem
                        key='categoryHidden'
                        value={MessageThreadHeader.Category}
                      >
                        {user.customerId === allCustomersId ? t('MessageCategoryType') : t('MessagesPageMessageCategory')}
                      </MenuItem>
                      <MenuItem
                        key='slaHidden'
                        value={MessageThreadHeader.SLA}
                      >
                        {t('MessagesPageSLA')}
                      </MenuItem>
                      <MenuItem
                        key='companyHidden'
                        value={MessageThreadHeader.Company}
                      >
                        {t('Company')}
                      </MenuItem>
                      <MenuItem
                        key='createdHidden'
                        value={MessageThreadHeader.Created}
                      >
                        {t('MessagesPageMessageTimestamp')}
                      </MenuItem>
                      <MenuItem
                        key='latestHidden'
                        value={MessageThreadHeader.Latest}
                      >
                        {t('MessagesPageLatestMessageAt')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
              <ScrollBar>
                <Table component="div">
                  <TableHead component="div">
                    <TableRow component="div">
                      {user?.customerId === 0 && (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.Customer) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.Customer, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.Customer} />{t('Customer')}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell component="div">
                        <Typography variant="tableHead">
                          {t('MessagesPageMessageSender')}
                        </Typography>
                      </TableCell>
                      <TableCell component="div" />
                      {!hiddenHeaders.includes(MessageThreadHeader.Status) && (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.Status) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.Status, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.Status} />{t('MessagesPageMessageStatus')}
                          </Typography>
                        </TableCell>
                      )}
                      {!hiddenHeaders.includes(MessageThreadHeader.Handler) && (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.Assignee) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.Assignee, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.Assignee} />{t('MessagesPageMessageHandler')}
                          </Typography>
                        </TableCell>
                      )}
                      {!hiddenHeaders.includes(MessageThreadHeader.Category) && (user.customerId === allCustomersId ? (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.CategoryType) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.CategoryType, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.CategoryType} />{t('MessageCategoryType')}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.Category) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.Category, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.Category} />{t('MessagesPageMessageCategory')}
                          </Typography>
                        </TableCell>
                      ))}
                      {!hiddenHeaders.includes(MessageThreadHeader.SLA) && canFilterBySLA && isSlaCategoriesUsed && (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.Priority) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.Priority, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.Priority} />{t('MessagesPageSLA')}
                          </Typography>
                        </TableCell>
                      )}
                      {!hiddenHeaders.includes(MessageThreadHeader.Company) && user?.customerId !== 0 && (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.RelatedCompany) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.RelatedCompany, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.RelatedCompany} />{t('Company')}
                          </Typography>
                        </TableCell>
                      )}
                      {!hiddenHeaders.includes(MessageThreadHeader.Created) && (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.CreationTime) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.CreationTime, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.CreationTime} />{t('MessagesPageMessageTimestamp')}
                          </Typography>
                        </TableCell>
                      )}
                      {!hiddenHeaders.includes(MessageThreadHeader.Latest) && (
                        <TableCell component="div">
                          <Typography variant="tableHead"
                            sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                              if (messagesSort === MessageThreadSort.LastMessage) {
                                flipSortDirection();
                              }
                              setPageNumber(1);
                              setMessagesSort(MessageThreadSort.LastMessage, user.customerId);
                            }}
                          >
                            <SortIcon active={messagesSort === MessageThreadSort.LastMessage} />{t('MessagesPageLatestMessageAt')}
                          </Typography>
                        </TableCell>
                      )}
                      {user?.isConsultantUser && (
                        <TableCell component="div">
                          <Typography variant="tableHead">
                            {t('MessageThreadHeadResponsibleInternalRoleTitle')}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell component="div"
                        sx={{
                          background: theme.palette.background.paper,
                          position: 'sticky',
                          right: 0
                        }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody component="div">
                    {!loading && messageThreads?.map((message) => (
                      <TableRow
                        onClick={saveScroll}
                        key={message.id}
                        component={RouterLink}
                        to={`/dashboard/messages/${message.messageThreadUniqueId}`}
                        sx={{
                          textDecoration: 'none',
                          '&:hover': {
                            background: 'rgba(255, 255, 255, 0.05)'
                          }
                        }}
                      >
                        {user?.customerId === 0 && (
                          <TableCell component="div">
                            <Typography>
                              {message.customerName}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell component="div">
                          <MessageSender
                            message={message}
                            unreadThreads={unreadThreads}
                            hasDraft={message.messageThreadUniqueId in draft}
                          />
                        </TableCell>
                        <TableCell component="div">{message.ccRecipients?.length > 0 ? <Tooltip title={message.ccRecipients.map((cc, i) => <div key={i}>{cc}</div>)}><ClosedCaption /></Tooltip> : null}</TableCell>
                        {!hiddenHeaders.includes(MessageThreadHeader.Status) && <TableCell component="div"><Typography>{t(`MessageThreadStatus${message.status}`)}</Typography></TableCell>}
                        {!hiddenHeaders.includes(MessageThreadHeader.Handler) && (
                          <TableCell
                            component="div"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            {messageAssignee(message) ?
                              (
                                <Typography>{messageAssignee(message)}</Typography>
                              )
                              :
                              (
                                <Typography sx={{ fontWeight: 'bold' }}>{t('MessageNotAssigned')}</Typography>
                              )
                            }
                          </TableCell>
                        )}
                        {!hiddenHeaders.includes(MessageThreadHeader.Category) && (user.customerId === allCustomersId ?
                          <TableCell component="div"><Typography sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>{message.emailCategoryMessage && <EmailOutlined sx={{ mr: 1 }} />}{message.techCategoryMessage && <Computer sx={{ mr: 1 }} />}{message.messageCategoryType && t(`MessageCategoryType${message.messageCategoryType}`)}</Typography></TableCell> :
                          <TableCell component="div"><Typography sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>{message.emailCategoryMessage && <EmailOutlined sx={{ mr: 1 }} />}{message.techCategoryMessage && <Computer sx={{ mr: 1 }} />}{message.categoryName}</Typography></TableCell>
                        )}
                        {!hiddenHeaders.includes(MessageThreadHeader.SLA) && canFilterBySLA && isSlaCategoriesUsed && (
                          <TableCell component="div">
                            <PriorityIcon priority={message.priority} />
                          </TableCell>
                        )}
                        {!hiddenHeaders.includes(MessageThreadHeader.Company) && user?.customerId !== 0 && (
                          <TableCell component="div">
                            <Typography>
                              {message.relatedCompanyName}
                            </Typography>
                          </TableCell>
                        )}
                        {!hiddenHeaders.includes(MessageThreadHeader.Created) && (
                          <TableCell component="div">
                            <div><Typography>{format(new Date(message.creationTime), 'd.M.yyyy')}</Typography></div>
                            <div>
                              <Typography>
                                {t('DateTimeAt')}
                                {' '}
                                {format(new Date(message.creationTime), 'HH:mm')}
                              </Typography>
                            </div>
                          </TableCell>
                        )}
                        {!hiddenHeaders.includes(MessageThreadHeader.Latest) && (
                          <TableCell component="div">
                            <div><Typography>{format(new Date(message.latestMessage.creationTime), 'd.M.yyyy')}</Typography></div>
                            <div>
                              <Typography>
                                {t('DateTimeAt')}
                                {' '}
                                {format(new Date(message.latestMessage.creationTime), 'HH:mm')}
                              </Typography>
                            </div>
                          </TableCell>
                        )}
                        {user.isConsultantUser && (
                          <TableCell component="div">
                            <Typography>{GetInternalRoleTranslation(t, message.responsibleInternalRole)}</Typography>
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            background: theme.palette.background.paper,
                            position: 'sticky',
                            right: 0
                          }}
                          component="div"
                        >
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleMessageMenuClick(e, message);
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ScrollBar>
              {menuAnchorEl && menuMessageThread && (
                <Menu
                  anchorEl={menuAnchorEl}
                  open={menuOpen}
                  onClose={handleMessageMenuClose}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <MenuItem
                    disabled={loadingUnreadMessages}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const markAsUnread = !unreadThreads.includes(menuMessageThread.messageThreadUniqueId);
                      updateUnreadState(menuMessageThread.messageThreadUniqueId, markAsUnread);
                      handleToggleRead(menuMessageThread.messageThreadUniqueId, markAsUnread);
                      handleMessageMenuClose();
                    }}
                  >
                    {markMessageReadButtonContent(menuMessageThread)}
                  </MenuItem>
                </Menu>
              )}
              {loading && (
                <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
              )}
              {!eof
                && (
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    disabled={eof}
                    variant="light"
                    ref={loadMoreButton}
                    onClick={
                      () => {
                        const nextPageNumber = pageNumber + 1;
                        setPageNumber(nextPageNumber);
                      }
                    }
                  >
                    {t('LoadMore')}
                  </LoadingButton>
                )}
            </>
          </Paper>
        </Container>
      </Box>
      <CommonDialog
        isOpen={deleteFilterSetConfirm}
        dialogTitle={t('MessagesPageConfirmDeleteViewTitle')}
        dialogContent={t('MessagesPageConfirmDeleteView')}
        confirmButtonTitle={t('DeleteButtonTitle')}
        cancelButtonTitle={t('ButtonCancel')}
        handleCancel={() => {
          setDeleteFilterSetConfirm(false);
        }}
        handleConfirm={() => {
          deleteMessageView(messageView);
          navigate('/dashboard/messages');
          setDeleteFilterSetConfirm(false);
        }}
      />
      <CommonModal open={isModalOpen}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') {
            setIsModalOpen(false);
          }
        }}
        width={1300}
      >
        <div style={{ maxHeight: '80vh', overflowY: 'auto', padding: 10 }}>
          <CreateMessageForm
            showTeamMembers={isTeamMessage}
            onSend={() => {
              getMessageThreads();
              setIsModalOpen(false);
            }}
            onClose={() => {
              setIsModalOpen(false);
            }}
          />
        </div>
      </CommonModal>
    </>
  );
});

export default Messages;
