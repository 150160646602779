import axios, { CancelToken } from 'axios';
import qs from 'qs';
import { PaymentQueryParameters, PaymentDetailDtoPagedResultDto, TransactionDtoPagedResultDto, DashboardPaymentTransactionsQueryParameters, PayslipPartDto, PaymentReviewStatus, TransactionDto, PagedResult, UserPayslip, TranslatedSalaryCodeDescriptionDto, PayslipDto, PaymentPayslipDto, PaymentDetailDto } from 'src/types/apiSchemas';
import { SiteUser } from 'src/types/siteUser';
import { getBackendUrl, formatQueryParameterToString } from 'src/utils/requestUtils';

class PaymentApi {
  getPaymentData = async (user: SiteUser, queryParameters: PaymentQueryParameters, customerId?: number): Promise<PaymentDetailDtoPagedResultDto> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments`;
    const custId = customerId ?? user.customerId;

    if (!custId) return Promise.reject();

    const formattedParameters = {
      PageNumber: formatQueryParameterToString(queryParameters?.PageNumber),
      PageSize: formatQueryParameterToString(queryParameters?.PageSize),
      PaymentStatus: queryParameters.PaymentStatus,
      SearchString: formatQueryParameterToString(queryParameters?.SearchString),
      CustomerId: custId,
      StartDate: queryParameters.StartDate,
      EndDate: queryParameters.EndDate
    };

    try {
      const response = await axios(endpointUrl, {
        params:
          formattedParameters,
        responseType: 'json',
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      });
      const payments: PaymentDetailDtoPagedResultDto = { ...response.data, customerId: custId };
      return await Promise.resolve(payments);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  getPayment = async (paymentId: number): Promise<PaymentDetailDto> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}`;

    try {
      const response = await axios(endpointUrl, { responseType: 'json' });
      const payment: PaymentDetailDto = response.data;
      return await Promise.resolve(payment);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  getPaymentPayslips = async (paymentId: number, pageNumber: number, pageSize: number, searchString: string, cancelToken: CancelToken): Promise<PagedResult<PaymentPayslipDto>> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/Payslips`;

    try {
      const response = await axios(endpointUrl, { cancelToken, params: { pageNumber, pageSize, searchString }, responseType: 'json' });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  getTransactions = async (paymentId: number, parameters: DashboardPaymentTransactionsQueryParameters, cancelToken?: CancelToken): Promise<TransactionDtoPagedResultDto> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/Transactions`;

    try {
      const response = await axios(endpointUrl, {
        cancelToken,
        responseType: 'json',
        params: { ...parameters },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      });
      const results: TransactionDtoPagedResultDto = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getTransactionWithNextAndPrev = async (paymentId: number, payslipId: number, parameters: DashboardPaymentTransactionsQueryParameters): Promise<TransactionDto[]> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/Transaction/${payslipId}`;

    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: { ...parameters },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      });
      const results: TransactionDto[] = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getPayslip = async (payslipId: number): Promise<PayslipPartDto[]> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payslips/${payslipId}`;

    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json'
      });
      const results: PayslipPartDto[] = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getTransactionPayslip = async (paymentId: number, transactionId: number): Promise<PayslipDto> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/transaction/${transactionId}/payslip`;

    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json'
      });
      const results: PayslipDto = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getUserPayslips = async (userId: number, pageNumber: number, pageSize: number): Promise<PagedResult<UserPayslip>> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/Payslips`;

    try {
      const response = await axios.get(endpointUrl, {
        params: {
          pageNumber,
          pageSize
        },
        responseType: 'json'
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getUserPayslip = async (userId: number, payslipId: number): Promise<PayslipPartDto[]> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/Payslips/${payslipId}`;

    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json'
      });
      const results: PayslipPartDto[] = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getUserPayslipPdf = async (userId: number, payslipId: number | string): Promise<Blob> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/Payslips/${payslipId}/Pdf`;

    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'blob'
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getSalaryCodeDescriptions = async (): Promise<TranslatedSalaryCodeDescriptionDto[]> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/SalaryCodeDescriptions`;

    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json'
      });
      const results: TranslatedSalaryCodeDescriptionDto[] = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  declinePayment = async (paymentId: number, userId: number): Promise<PaymentReviewStatus> => {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/Users/${userId}/Review?status=Declined`;
    try {
      const response = await axios.patch(endpointUrl, { status: 'Declined' });
      const results = response.data;
      console.log(results);
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  async updatePaymentStatus(paymentId: number, paymentStatus: string, reasonForDeclining: string, userId: number, sendApproverNotification: boolean) {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/Users/${userId}/Review?status=${paymentStatus}`;
    try {
      const response = await axios.patch(endpointUrl, null, {
        params: { status: paymentStatus, comment: reasonForDeclining, sendApproverNotification: sendApproverNotification }
      });

      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async approvePayment(paymentId: number, paymentStatus: string, reasonForDeclining: string, userId: number) {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/Users/${userId}/Approval?status=${paymentStatus}`;
    try {
      const response = await axios.patch(endpointUrl, null, {
        params: { status: paymentStatus, comment: reasonForDeclining }
      });

      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async flagTransaction(paymentId: number, transactionId: number, flagged: boolean, comment: string) {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/Transactions/${transactionId}?flag=${flagged}&comment=${comment}`;
    try {
      const response = await axios.patch(endpointUrl, null, {
        params: { paymentId, comment, transactionId, flagged }
      });

      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async removeFlagTransaction(paymentId: number) {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/${paymentId}/Transactions/Flags`;
    try {
      const response = await axios.delete(endpointUrl, {
        params: { paymentId }
      });

      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async downloadPdfReport(companyId: number, startDate: string, endDate: string) {
    const endpointUrl = `${getBackendUrl()}/api/v1/Payments/Report`;
    try {
      const response = await axios.get(endpointUrl, {
        params: { companyId, startDate, endDate },
        responseType: 'blob',
      });
      const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(fileURL, '_blank');

      return await Promise.resolve('Success');
    } catch (err) {
      return Promise.reject(err);
    }
  }

}

export const paymentApi = new PaymentApi();
