import { Autocomplete, CircularProgress, Grid, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { MessageThreadRelatedItemDto, MessageThreadRelatedItemOptionsDto, MessageThreadRelatedItemWithName, RelatedUserReferenceDto, UserReferenceDto } from 'src/types/apiSchemas';
import { baseApi } from 'src/API/baseApi';
import toast from 'react-hot-toast';

enum MessageThreadRelatedItemType {
  NoRelatedItem = 'NoRelatedItem',
  Company = 'Company',
  Person = 'Person'
}

interface MessageThreadRelatedItemChangeProps {
  handleRelatedItemChange: (relatedItem: MessageThreadRelatedItemDto) => void
  handleDisableSaveButton?: (disable: boolean) => void
  currentRelatedItem?: MessageThreadRelatedItemWithName
  selectedCustomer: number;
  toBeModifiedRelatedItemType?: MessageThreadRelatedItemType;
}

const MessageThreadRelatedPersonChange: FC<MessageThreadRelatedItemChangeProps> = observer(({ handleRelatedItemChange, currentRelatedItem, selectedCustomer, toBeModifiedRelatedItemType }) => {
  const { t } = useTranslation();

  const [messageThreadRelatedItemOptions, setMessageThreadRelatedItemOptions] = useState<MessageThreadRelatedItemOptionsDto>(null);
  const [messageThreadRelatedItemOptionsLoading, setMessageThreadRelatedItemOptionsLoading] = useState<boolean>(true);
  const [messageThreadRelatedPersonSearchString, setMessageThreadRelatedPersonSearchString] = useState<string>(currentRelatedItem?.person?.name ?? '');
  const [messageThreadRelatedUser, setMessageThreadRelatedUser] = useState<UserReferenceDto>(currentRelatedItem?.person ?? null);

  const getMessageThreadRelatedItemOptions = async () => {
    setMessageThreadRelatedItemOptionsLoading(true);
    try {
      const response = await baseApi.getMessageThreadRelatedItemOptions(selectedCustomer, messageThreadRelatedPersonSearchString);
      response.persons.sort((a, b) => a.name.localeCompare(b.name));
      setMessageThreadRelatedItemOptions(response);
    } catch (err) {
      toast.error(t('CouldNotRetrieveMessageThreadRelatedItemOptionsToast'));
      console.log(err);
    }
    setMessageThreadRelatedItemOptionsLoading(false);
  };

  useEffect(() => {
    getMessageThreadRelatedItemOptions();
  }, [messageThreadRelatedPersonSearchString]);

  useEffect(() => {
    if (selectedCustomer !== 0) {
      if (!currentRelatedItem) {
        setMessageThreadRelatedUser(null);
      }
      setMessageThreadRelatedItemOptions(null);
      getMessageThreadRelatedItemOptions();
    }
  }, [selectedCustomer]);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Grid
          item
          container
          xs={12}
          flexDirection='row'
          alignItems='center'
        >
          {messageThreadRelatedItemOptions && (
            <Autocomplete
              sx={{ width: 400, maxWidth: '100%' }}
              id="person"
              value={messageThreadRelatedUser}
              defaultValue={currentRelatedItem?.person}
              options={messageThreadRelatedItemOptions?.persons ?? []}
              getOptionLabel={(option: RelatedUserReferenceDto) => option.name}
              filterOptions={(options) => options}
              isOptionEqualToValue={(option, value) => { return option.id === value.id; }}
              onChange={(e, value: RelatedUserReferenceDto) => {
                setMessageThreadRelatedUser(value);
                var urcId = currentRelatedItem?.companyId;
                if (toBeModifiedRelatedItemType == null) {
                  urcId = messageThreadRelatedItemOptions?.companies?.find(c => c.id === value?.employmentCompanyId)?.id;
                }
                handleRelatedItemChange({ companyId: urcId, personUserId: value?.id ?? null });
              }}
              onInputChange={(event, value) => {
                setMessageThreadRelatedPersonSearchString(value);
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    label={t('MessagesCreateMessageRelatedUserAutoCompletePlaceHolder')}
                    name="substitute"
                    {...params}
                  />
                </>
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props.key} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ fontWeight: 800 }}>{option.name}</div><div style={{ fontSize: '0.8em' }}>{option.username}</div>
                  </li>);
              }}
            />
          )}

          {messageThreadRelatedItemOptionsLoading && (
            <CircularProgress
              size={20}
              sx={{ ml: 2 }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
});

export default MessageThreadRelatedPersonChange;