import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InternalRole, UserReference, UserRole } from 'src/types/apiSchemas';
import CloseIcon from '@mui/icons-material/Close';
import CommonModal from 'src/components/common/CommonModal';
import RoleDisplay from './RoleDisplay';

interface RoleUserListModalProps {
  roleDetailsId: InternalRole | UserRole;
  roleDetailsUsers: UserReference[];
  onClose: () => void;
}

const RoleUserListModal: FC<RoleUserListModalProps> = ({ roleDetailsId, roleDetailsUsers, onClose }) => {
  const { t } = useTranslation();
  return (
    <CommonModal
      onClose={() => { onClose(); }}
      open={!!roleDetailsId}
      width={400}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          variant="text"
          sx={{ color: 'text.primary', textTransform: 'none' }}
          onClick={() => { onClose(); }}
        >
          <Typography>{t('GenericButtonClose')}</Typography>
          <CloseIcon sx={{ ml: 1 }} />
        </Button>
      </div>
      <div style={{ margin: '20px 0' }}><RoleDisplay role={roleDetailsId} /></div>
      <Typography sx={{ mb: 3 }}>
        {roleDetailsUsers?.length}
        {' '}
        {roleDetailsUsers?.length === 1 ? t('MessagesPagePerson') : t('MessagesPagePersons')}
      </Typography>
      {roleDetailsUsers?.sort((a, b) => a.name.localeCompare(b.name, 'fi'))
        .map((u) => <div key={u.id}>{u.name}</div>)}
    </CommonModal>
  );
};

export default RoleUserListModal;

