import { LoadingButton } from '@mui/lab';
import { Button, FormHelperText, Grid, MenuItem, Select, TextField, Typography, Box, FormControlLabel, Checkbox, Tooltip, Chip } from '@mui/material';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { baseApi } from 'src/API/baseApi';
import { useAppStore } from 'src/store/mobx';
import { CompanyReferenceDto, Customer, Feature, InternalRole, MessageThread, MessageThreadRelatedItemDto, MessageThreadVisibility, MessageThreadVisibilityParameters, SLAPriority, SubordinateDto, TranslatedMessageCategory, UserReference, UserRole } from 'src/types/apiSchemas';
import * as Yup from 'yup';
import MessageThreadVisibilityModal from './MessageThreadVisibilityModal';
import CommonModal from 'src/components/common/CommonModal';
import { GetInternalRoleTranslation, GetUserRoleTranslation } from 'src/utils/roleUtils';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { AttachmentPreview } from 'src/pages/dashboard/UseAttachments';
import { allCustomersId } from 'src/constants';
import InfoIcon from '@mui/icons-material/Info';
import UserDisplay from './UserDisplay';
import MessageThreadRelatedItemChange from './MessageThreadRelatedItemChange';
import MessageEditor from 'src/components/MessageEditor/MessageEditor';
import messageStore from 'src/store/mobx/messageStore';
import { observer } from 'mobx-react';
import CCModal from './CCModal';
import SearchableSelect from 'src/components/SearchableSelect';
import RoleUserListModal from './RoleUserListModal';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CommonDialog from 'src/components/common/CommonDialog';

interface CreateMessageFormProps {
  onClose: () => void;
  onSend: () => void;
  showTeamMembers?: boolean;
}

const CreateMessageForm: FC<CreateMessageFormProps> = observer(({ onClose, onSend, showTeamMembers }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const [messageCategories, setMessageCategories] = useState<TranslatedMessageCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingVisibilityParameters, setIsLoadingVisibilityParameters] = useState(false);
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
  const [roleDetailsId, setRoleDetailsId] = useState<InternalRole | UserRole>(null);
  const [category, setCategory] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState(user.customerId);
  const initialVisibility = { userRoles: [], internalRoles: [], managerHasAccess: false, users: [] };
  const [visibility, setVisibility] = useState<MessageThreadVisibility>(initialVisibility);
  const [visibilityParameters, setVisibilityParameters] = useState<MessageThreadVisibilityParameters>({
    userRoles: [],
    internalRoles: [],
    users: [],
  });
  const { t } = useTranslation();
  const auth: IAuthorizationService = new AuthorizationService();
  const draftScope = `new-thread-${selectedCustomer}`;
  const [displayEditor, setDisplayEditor] = useState(true);
  const [roleDetailsUsers, setRoleDetailsUsers] = useState<UserReference[]>([]);

  const {
    attachments: { [draftScope]: attachments = [] },
    userMentions: { [draftScope]: userMentions = [] },
    userRoleMentions: { [draftScope]: userRoleMentions = [] },
    internalRoleMentions: { [draftScope]: internalRoleMentions = [] },
    removeAttachment,
    content: { [draftScope]: content = '' },
    richTextContent: { [draftScope]: richTextContent = '' },
    clearEditor
  } = messageStore;

  const messageAttachments = attachments.filter(a => !a.deleted);

  const [priority, setPriority] = useState<SLAPriority>(SLAPriority.Normal);
  const [teamMembers, setTeamMembers] = useState<SubordinateDto[]>([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<number[]>([]);
  const [messageThreadSelectedRelatedItem, setMessageThreadSelectedRelatedItem] = useState<MessageThreadRelatedItemDto>();

  const hasAnyInternalRole = auth.get(user).hasAnyInternalRole(selectedCustomer).verify();
  const hasAnyUserRole = auth.get(user).hasAnyUserRole(selectedCustomer).verify();
  const isCustomerHR = auth.get(user).is(UserRole.HR, selectedCustomer).verify();
  const isCustomerFinance = auth.get(user).is(UserRole.Finance, selectedCustomer).verify();
  const isCustomerTechnical = auth.get(user).is(UserRole.Technical, selectedCustomer).verify();
  const canChangeVisibility = hasAnyInternalRole || hasAnyUserRole;
  const canChangePriority = hasAnyInternalRole || isCustomerHR;
  const canChangeRelatedItem = hasAnyInternalRole || isCustomerHR || (user.isManager && user.employmentCustomerId === selectedCustomer);
  const [relatedCompanyOptions, setRelatedCompanyOptions] = useState<CompanyReferenceDto[]>([]);
  const requireRelatedCompany = user.isManager && !hasAnyInternalRole && !isCustomerHR && relatedCompanyOptions.length > 0;
  const [cc, setCC] = useState<string[]>([]);
  const [showCCModal, setShowCCModal] = useState(false);
  const canChangeCC = ((hasAnyInternalRole || hasAnyUserRole) && user.isConsultantUser) || (isCustomerHR || isCustomerFinance || isCustomerTechnical);
  
  const handleRelatedItemChange = (relatedItem: MessageThreadRelatedItemDto) => {
    setMessageThreadSelectedRelatedItem(relatedItem);
  };

  useEffect(() => {
    const updateCategories = async () => {
      setIsLoadingCategories(true);
      try {
        const response = await baseApi.getMessageCategories(selectedCustomer);
        const filteredCategories = response.filter(c => !c.emailCategory && !c.technicalCategory);
        setMessageCategories(filteredCategories);

      } catch (err) {
        console.log(err);
      }
      setIsLoadingCategories(false);
    };
    if (selectedCustomer)
      updateCategories();
  }, [selectedCustomer]);

  useEffect(() => {
    const updateVisibilityParameters = async () => {
      setIsLoadingVisibilityParameters(true);
      try {
        const response = await baseApi.getMessageThreadVisibilityParameters(selectedCustomer);
        setVisibilityParameters(response);

      } catch (err) {
        console.log(err);
      }
      setIsLoadingVisibilityParameters(false);
    };
    if (selectedCustomer)
      updateVisibilityParameters();
  }, [selectedCustomer]);


  useEffect(() => {
    const getUserCustomers = async () => {
      try {
        const response = await baseApi.getUserCustomers();
        const filtereCustomers = response.filter((c) => c.customerFeatures?.includes(Feature.Messaging));
        setCustomers(filtereCustomers.sort((a, b) => a.name.localeCompare(b.name)));
      } catch (err) {
        console.log(err);
      }
    };

    if (user.customerId === allCustomersId)
      getUserCustomers();
  }, []);

  useEffect(() => {
    const getSubordinates = async () => {
      const result = await baseApi.getSubordinates(user?.customerId);
      setTeamMembers(result);
    };
    if (showTeamMembers) {
      getSubordinates();
    }
  }, []);

  useEffect(() => {
    const getRelatedCompanies = async () => {
      try {
        const response = await baseApi.getMessageThreadRelatedItemOptions(selectedCustomer, '');
        setRelatedCompanyOptions(response.companies);
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedCustomer) {
      getRelatedCompanies();
    } else {
      setRelatedCompanyOptions([]);
    }
  }, [selectedCustomer]);

  const hasSLAEnabled = (id: string): boolean => {
    return messageCategories.find((c) => c.id === parseInt(id))?.slaMonitoring;
  };

  const customerOptions = customers.map(c => ({ label: c.name, value: c.customerId }));
  const selectedCustomerOption = [customerOptions.find(c => c.value === selectedCustomer)].filter(v => v);

  const [openDialog, setOpenDialog] = useState(false);

  const handleCancel = () => {
    if (richTextContent.trim() !== '' || content.trim() !== '') {
      setOpenDialog(true);
    } else {
      clearEditor(draftScope);
      onClose();
    }
  };
  
  const confirmClearEditor = () => {
    clearEditor(draftScope);
    onClose();
    setOpenDialog(false);
  };
  
  const cancelClearEditor = () => {
    setOpenDialog(false);
  };
  
  return (
    <Formik
      initialValues={{
        subject: '',
        category: '',
      }}
      onSubmit={async (values) => {
        setIsLoading(true);

        const threadVisibility: MessageThreadVisibility = showTeamMembers ? {
          users: selectedTeamMembers.map(u => ({ id: u })),
          managerHasAccess: false,
          userRoles: [],
          internalRoles: []
        } : canChangeVisibility ? visibility : undefined;

        const messageThread: MessageThread = {
          categoryId: parseInt(category, 10),
          subject: values.subject,
          message: content,
          richTextContent: richTextContent,
          attachmentIds: messageAttachments.map(a => a.messageAttachmentId),
          customerId: selectedCustomer,
          messageThreadVisibility: threadVisibility,
          priority: hasSLAEnabled(category) ? priority : undefined,
          relatedCompanyId: messageThreadSelectedRelatedItem?.companyId,
          relatedPersonId: messageThreadSelectedRelatedItem?.personUserId,
          userMentions,
          userRoleMentions,
          internalRoleMentions,
          ccRecipients: cc
        };
        try {
          await baseApi.postMessageThread(messageThread);
          toast.success(t('MessagesPageMessageSent'));
          clearEditor(draftScope);
          onSend();
        } catch (err) {
          console.log(err);
          toast.error(t('MessagesPageMessageSendingFailed'));
        }
        setIsLoading(false);
      }}
      validationSchema={
        Yup.object().shape({
          subject: Yup.string().required(t('YupGeneralMessage')),
          category: Yup.string().required(t('YupGeneralMessage'))
        })
      }
    >
      {({ values, touched, errors, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            gap={3}
          >
            {user.customerId === allCustomersId &&
              <Grid item xs={12}>
                <Typography
                  variant="formFieldHeader"
                  sx={{ mb: 1 }}
                >
                  {t('Customer')}
                </Typography>
                <div style={{ maxWidth: 300 }}>
                  <SearchableSelect
                    value={selectedCustomerOption}
                    options={customerOptions}
                    onChange={(event, value, reason, details) => {
                      const customerId = details.option.value;

                      if (customerId) {
                        setDisplayEditor(false);
                        setTimeout(() => {
                          setDisplayEditor(true);
                        }, 300);
                        setSelectedCustomer(parseInt(customerId as string, 10));
                        setCategory('');
                        setVisibility(initialVisibility);
                      }
                    }}
                    label=''
                  />
                </div>
              </Grid>
            }
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="formFieldHeader"
                sx={{ mb: 1 }}
              >
                {t('MessagesPageMessageSubject')}
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                onChange={handleChange}
                name="subject"
                error={Boolean(touched.subject && errors.subject)}
                helperText={(touched.subject && errors.subject)}
                value={values.subject}
              />
            </Grid>
            {selectedCustomer !== allCustomersId &&
              <>
                <Grid item container xs={12} direction="row">
                  <Grid
                    item
                    xs={6}
                    sx={{ mb: 3 }}
                  >
                    <Typography
                      variant="formFieldHeader"
                      sx={{ mb: 1 }}
                    >
                      {t('MessagesPageMessageCategory')}
                    </Typography>
                    <Select
                      value={isLoadingCategories ? '' : category}
                      name="category"
                      error={Boolean(touched.category && errors.category)}
                      onChange={(e) => {
                        setCategory(e.target.value);
                        const selectedCategory = messageCategories.find((c) => c.id === parseInt(e.target.value, 10));
                        setCategoryDescription(selectedCategory.description);
                        setVisibility({
                          internalRoles: selectedCategory.messageCategoryInternalRoles,
                          userRoles: selectedCategory.messageCategoryRoles,
                          managerHasAccess: selectedCategory.managerHasAccess,
                          users: []
                        });
                        handleChange(e);
                      }}
                      sx={{ width: 400, maxWidth: '100%' }}
                    >
                      {messageCategories.map((mc) => (
                        <MenuItem value={mc.id} key={mc.id}>
                          {mc.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.category && <FormHelperText error>{errors.category}</FormHelperText>}
                    {categoryDescription &&
                      <Grid
                        item
                        xs={12}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <InfoIcon fontSize="small" />
                          <Typography sx={{ ml: 1 }}>
                            {categoryDescription}
                          </Typography>
                        </Box>
                      </Grid>}
                  </Grid>
                  {canChangeVisibility && !showTeamMembers && (
                    <Grid
                      item
                      container
                      xs={6}
                    >
                      <Typography
                        variant="formFieldHeader"
                        sx={{ mb: 1 }}
                      >
                        {t('MessagesPageVisibility')}
                      </Typography>
                      <Grid item container spacing={0.5} sx={{ mb: 1 }}>
                        {visibility.internalRoles.map((ir) => (
                          <Grid key={ir}
                            item
                          >
                            <Chip
                              disabled={isLoadingVisibilityParameters}
                              icon={<GroupsOutlinedIcon color='primary' />}
                              variant="outlined"
                              label={GetInternalRoleTranslation(t, ir)}
                              onClick={() => {
                                setRoleDetailsId(ir);
                                setRoleDetailsUsers(visibilityParameters?.internalRoles.find(r => r.internalRole == ir).users);
                              }}
                            />
                          </Grid>
                        ))}
                        {visibility.userRoles.map((ur) => (
                          <Grid key={ur}
                            item
                          >
                            <Chip
                              disabled={isLoadingVisibilityParameters}
                              icon={<GroupsOutlinedIcon color='success' />}
                              variant="outlined"
                              label={GetUserRoleTranslation(t, ur)}
                              onClick={() => {
                                setRoleDetailsId(ur);
                                setRoleDetailsUsers(visibilityParameters?.userRoles.find(r => r.userRole == ur).users);
                              }}
                            />
                          </Grid>
                        ))}
                        {visibility.managerHasAccess &&
                          <Grid
                            item
                          >
                            <Chip
                              label={t('ApprovalsManager')}
                            />
                          </Grid>}
                        {visibility.users.map((u) => (
                          <Grid key={u.id}
                            item
                          >
                            <Tooltip
                              title={u.username}
                            >
                              <Chip
                                label={u.name}
                              />
                            </Tooltip>
                          </Grid>
                        ))}
                      </Grid>
                      <Button
                        variant="light"
                        disabled={!category}
                        onClick={() => { setIsVisibilityModalOpen(true); }}
                      >
                        {t('MessagesPageChangeVisibility')}
                      </Button>
                    </Grid>
                  )}
                  {showTeamMembers &&
                    <Grid item xs={12} sx={{ mb: 3 }}>
                      <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center' }}>
                        <div>
                          <Typography
                            variant="formFieldHeader"
                            sx={{ mb: 1 }}
                          >
                            {t('MessagesPageVisibility')}
                          </Typography>
                        </div>
                        <div style={{ marginLeft: 'auto', marginRight: 20 }}>
                          {teamMembers.length > 0 &&
                            <Button variant="contained"
                              onClick={() => {
                                if (selectedTeamMembers.length < teamMembers.length)
                                  setSelectedTeamMembers(teamMembers.map(tm => tm.id));
                                else
                                  setSelectedTeamMembers([]);
                              }}
                            >
                              {selectedTeamMembers.length < teamMembers.length ? t('ApprovalsSelectAll') : t('RemoveAll')}
                            </Button>}
                        </div>
                      </div>
                      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(340px, 1fr))', gap: 20, maxHeight: 300, overflow: 'auto' }}>
                        {teamMembers.map((u) => (
                          <div
                            key={u.id}
                            style={{ paddingLeft: 11, overflow: 'hidden' }}
                          >
                            <Tooltip title={<><div>{u.name}</div><div>{t('EmployeeId')}: {u.employeeId}</div></>} placement="top">
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    sx={{ mr: 1 }}
                                    checked={selectedTeamMembers.some((ur) => ur === u.id)}
                                    onChange={(event) => {
                                      setSelectedTeamMembers((prev) => (event.target.checked ? [...prev, u.id] : prev.filter((p) => p !== u.id)));
                                    }}
                                  />
                                )}
                                label={(
                                  <UserDisplay
                                    displayName={u.name}
                                    secondaryText={u.email}
                                  />
                                )}
                              />
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  }
                </Grid>
                <Grid item container xs={12} direction="row">
                  {canChangeCC &&
                    <Grid item xs={6}>
                      <Typography
                        variant="formFieldHeader"
                        sx={{ mb: 1 }}
                      >
                        {t('MessagesPageCCRecipients')}
                      </Typography>
                      <div>
                        {cc.join(', ')}
                      </div>
                      <Button variant="light" onClick={() => setShowCCModal(true)} sx={{ mt: 1 }}>{t('MessagesPageChangeCC')}</Button>
                      {showCCModal && <CCModal cc={cc} onChange={(emails) => { setCC(emails); }} onClose={() => { setShowCCModal(false); }} />}
                    </Grid>

                  }
                  {category && hasSLAEnabled(category) && canChangePriority &&
                    <Grid
                      item
                      xs={6}
                    >
                      <>
                        <Typography
                          variant="formFieldHeader"
                          sx={{ mb: 1 }}
                        >
                          {t('MessageThreadPriority')}
                        </Typography>
                        <Select
                          name="priority"
                          sx={{ width: 300, maxWidth: '100%' }}
                          value={priority}
                          onChange={(e) => { setPriority(e.target.value as SLAPriority); }}
                        >
                          <MenuItem
                            value={SLAPriority.Low}
                            key={SLAPriority.Low}
                          >
                            {t('MessageThreadPriorityLow')}
                          </MenuItem>
                          <MenuItem
                            value={SLAPriority.Normal}
                            key={SLAPriority.Normal}
                          >
                            {t('MessageThreadPriorityNormal')}
                          </MenuItem>
                          <MenuItem
                            value={SLAPriority.High}
                            key={SLAPriority.High}
                          >
                            {t('MessageThreadPriorityHigh')}
                          </MenuItem>
                        </Select>
                      </>
                    </Grid>
                  }
                </Grid>
                {canChangeRelatedItem && (
                  <MessageThreadRelatedItemChange requireRelatedCompany={requireRelatedCompany} companyHasError={requireRelatedCompany && !messageThreadSelectedRelatedItem?.companyId} handleRelatedItemChange={handleRelatedItemChange} selectedCustomer={selectedCustomer} />
                )}
                {displayEditor &&
                  <Grid item xs={12}>
                    <MessageEditor
                      messageThreadUniqueId=''
                      scope={draftScope}
                      maxHeight={300}
                      messageThreadVisibility={visibility}
                      customerId={selectedCustomer}
                      showToolbar
                    />
                    {messageAttachments.length > 0 &&
                      <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap', marginTop: 25 }}>
                        {/* eslint-disable-next-line react/no-array-index-key */}
                        {messageAttachments.map((a, i) =>
                          richTextContent.includes(a.urlWithoutSas) ? null :
                            <AttachmentPreview
                              displayRemoveButton={!isLoading}
                              onRemove={() => {
                                removeAttachment(a.urlWithoutSas, draftScope);
                              }}
                              attachment={a}
                              key={i}
                              t={t}
                            />)}
                      </div>}
                  </Grid>}
              </>}
            <Grid
              container
              gap={2}
            >
              <Grid item>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isLoading}
                  disabled={selectedCustomer === allCustomersId || (showTeamMembers && selectedTeamMembers.length < 1) || (requireRelatedCompany && !messageThreadSelectedRelatedItem?.companyId)}
                >
                  {t('ButtonSend')}
                </LoadingButton>
              </Grid>
              <Grid item>
              <Button
                variant="light"
                onClick={handleCancel}
              >
                {t('ButtonCancel')}
              </Button>
              <CommonDialog
                isOpen={openDialog}
                dialogTitle={t('MessageDraftExitSave')}
                dialogContent=""
                handleConfirm={onClose}
                handleSecondConfirm={confirmClearEditor}
                handleCancel={cancelClearEditor}
                confirmButtonTitle={t('GenericButtonSave')}
                secondConfirmButtonTitle={t('GenericButtonDiscard')}
                cancelButtonTitle={t('ButtonCancel')}
                confirmButtonColor='success'
              />
              </Grid>
            </Grid>
          </Grid>
          <RoleUserListModal
            roleDetailsId={roleDetailsId}
            roleDetailsUsers={roleDetailsUsers}
            onClose={() => {
              setRoleDetailsId(null);
              setRoleDetailsUsers([]);
            }}
          />
          <CommonModal
            open={isVisibilityModalOpen}
            width={1200}
          >
            <div style={{ height: '80vh', overflow: 'auto' }}>
              <MessageThreadVisibilityModal
                initialVisibility={visibility}
                customerId={selectedCustomer}
                onClose={() => {
                  setIsVisibilityModalOpen(false);
                }}
                onVisibilityUpdate={(updatedVisibility) => {
                  setVisibility(updatedVisibility);
                }}
              />
            </div>
          </CommonModal>
        </form>
      )}
    </Formik>
  );
});

export default CreateMessageForm;