import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, TextField } from '@mui/material';
import { ChangeEventHandler, FC } from 'react';

interface SearchableSelectProps {
  onChange: (event: React.SyntheticEvent, value: any[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => void
  value: any[];
  options: any[];
  label: string;
  disableCloseOnSelect?: boolean;
  onSearchChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  searchValue?: string;
}

const SearchableSelect: FC<SearchableSelectProps> = ({ value, options, label, onChange, disableCloseOnSelect, onSearchChange, searchValue }) => {
  return (
    <Autocomplete
      value={value}
      multiple
      disableClearable
      disableCloseOnSelect={disableCloseOnSelect}
      options={options}
      inputValue={searchValue}
      renderInput={params => (
        <TextField
          {...params}
          label={value.length > 0 ? label : ''}
          InputLabelProps={{
            shrink: true
          }}
          placeholder={value.map(a => a.label).join(', ') || label}
          sx={{
            '& .MuiInputBase-input::placeholder': {
              color: 'text.primary',
              opacity: value.length > 0 ? 1 : 0.6
            }
          }}
          onKeyDown={(event: any) => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
              event.stopPropagation();
            }
          }}
          onChange={onSearchChange}
        />
      )}
      onChange={onChange}
      renderTags={() => {
        return '';
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
    />
  );
};

export default SearchableSelect;