import { Add, Delete } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { baseApi } from 'src/API/baseApi';
import CommonDialog from 'src/components/common/CommonDialog';
import CommonModal from 'src/components/common/CommonModal';
import X from 'src/icons/X';

interface CCModalProps {
  onClose: () => void;
  messageThreadId?: string;
  onChange: (cc: string[]) => void;
  cc: string[];
}

const CCModal: FC<CCModalProps> = ({ onClose, messageThreadId, cc, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [removeEmail, setRemoveEmail] = useState<string>();
  const { t } = useTranslation();

  const isValidEmail = (val: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(val?.trim());
  };

  return (
    <CommonModal open>
      <CommonDialog handleCancel={() => {
        setRemoveEmail(undefined);
      }}
        handleConfirm={async () => {
          setIsLoading(true);
          try {
            const values = cc.filter(c => c != removeEmail);
            if (messageThreadId) {
              await baseApi.updateMessageThreadCC(messageThreadId, values);
            }
            onChange(values);
            setRemoveEmail(undefined);
          } catch (err) {
            console.log(err);
          }
          setIsLoading(false);
        }}
        isOpen={!!removeEmail}
        dialogTitle=''
        dialogContent={t('MessagesPageConfirmDeleteCC')}
        confirmButtonTitle={t('DeleteButtonTitle')}
        cancelButtonTitle={t('ButtonCancel')}
      >
      </CommonDialog>
      {!cc && <CircularProgress style={{ display: 'block', margin: 'auto' }} />}
      {cc &&
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <Typography
                variant="h5"
              >
                {t('MessagesPageCCRecipients')}
              </Typography>
            </div>
            <div style={{ marginLeft: 'auto' }}><Button variant="light" style={{ border: 0, textTransform: 'none', fontSize: 16 }} onClick={onClose}>{t('GenericButtonClose')} <X sx={{ ml: 1 }} /></Button></div>
          </div>
          <div style={{ display: 'flex', margin: '30px 0' }}>
            <div style={{ flex: 1 }}>
              <TextField value={inputValue} onChange={(e) => { setInputValue(e.target.value); }} autoComplete="off" placeholder={t('MessagesPageAddCCRecipient')} size="small" fullWidth />
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton disabled={isLoading || !isValidEmail(inputValue)}
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    const values = [...new Set([...cc, inputValue?.trim()])];
                    if (messageThreadId) {
                      await baseApi.updateMessageThreadCC(messageThreadId, values);
                    }
                    onChange(values);
                    setInputValue('');
                  } catch (err) {
                    console.log(err);
                  }
                  setIsLoading(false);
                }}
              >
                <Add />
              </IconButton>
            </div>
          </div>
          <div>
            {cc && cc.map(email =>
              <div key={email} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1, wordBreak: 'break-all' }}>{email}</div>
                <div style={{ marginLeft: 'auto' }}>
                  <IconButton disabled={isLoading}
                    onClick={async () => {
                      setRemoveEmail(email);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </div>)}
          </div>
        </>}
    </CommonModal >);
};

export default CCModal;