import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Divider, Fade, Grid, Typography, Link, CircularProgress, Breadcrumbs, Container, TableHead, TableBody, TableRow, Table, TableCell } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import { useMounted } from 'src/hooks';
import { ManagerApprovalAbsenceTypeDto, ManagerApprovalDetailDto, ManagerApprovalStatus } from 'src/types/apiSchemas';
import { managerApprovalsApi } from 'src/API/managerApprovalsApi';
import AbsenceApprovalDialog from 'src/components/dashboard/approvals/AbsenceApprovalDialog';
import AbsenceDeclineDialog from 'src/components/dashboard/approvals/AbsenceDeclineDialog';
import { format, isSameDay, differenceInHours } from 'date-fns';
import { displayLocalDate, ensureUTCFormat } from 'src/utils/dateUtils';
import AttachmentApprovalDialog from 'src/components/dashboard/approvals/AttachmentApprovalDialog';
import AttachmentDeclineDialog from 'src/components/dashboard/approvals/AttachmentDeclineDialog';
import AttachmentsPreviewDialog from 'src/components/dashboard/approvals/AttachmentsPreviewDialog';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import NotFound from '../NotFound';
import ChevronRightIcon from '../../icons/ChevronRight';
import HolidayBonusChangeRequestApprovalDialog from 'src/components/dashboard/approvals/HolidayBonusChangeRequestApprovalDialog';
import HolidayBonusChangeRequestDeclineDialog from 'src/components/dashboard/approvals/HolidayBonusChangeRequestDeclineDialog';
import HolidayBonusChangeRequestReturnDialog from 'src/components/dashboard/approvals/HolidayBonusChangeRequestReturnDialog';
import { ManagerInfo } from 'src/components/dashboard/approvals/ApprovalCard';
import AutoDeleteOutlinedIcon from '@mui/icons-material/AutoDeleteOutlined';
import { useTheme } from '@mui/material/styles';
import CommonModal from 'src/components/common/CommonModal';
import AbsenceForm from 'src/components/dashboard/absences/AbsenceForm';
import EditIcon from '@mui/icons-material/Edit';
import UserDisplay from 'src/components/dashboard/messages/UserDisplay';

export const absenceTimeDurationFormatter = (t, absenceStart, absenceEnd): string => {
  const startDate = new Date(`${absenceStart}Z`);
  const endDate = new Date(`${absenceEnd}Z`);
  const hourDifference = differenceInHours(endDate, startDate);
  const dayDifference = Math.ceil(hourDifference / 24);
  const isUnderOneHour = hourDifference < 1;
  if (isUnderOneHour) return `${t('ApprovalUnderHour')} ${t('ApprovalHourLabel')}`;
  const isUnderOneDay = hourDifference < 23;
  if (isUnderOneDay) return `${hourDifference} ${hourDifference < 2 ? t('ApprovalHourLabel') : t('ApprovalHoursLabel')}`;
  if (dayDifference < 2) return `1 ${t('ApprovalDayLabel')}`;
  return `${dayDifference} ${t('ApprovalDaysLabel')}`;
};

export const absenceTranslator = (t, absenceCategory): string => {
  if (absenceCategory === 'SickLeave') {
    return t('ApprovalSickLeaveTitle');
  }
  return '';
};

export const datesFormatter = (startDate, endDate) => {
  const dateStart = new Date(ensureUTCFormat(startDate));
  const dateEnd = new Date(ensureUTCFormat(endDate));
  if (isSameDay(dateEnd, dateStart)) {
    return `${format(dateStart, 'dd.MM.yyyy')}`;
  }
  return `${format(dateStart, 'dd.MM.yyyy')}-${format(dateEnd, 'dd.MM.yyyy')}`;
};

export const timeFormatter = (startDate, endDate) => {
  const dateStart = new Date(ensureUTCFormat(startDate));
  const dateEnd = new Date(ensureUTCFormat(endDate));
  const hourDifference = differenceInHours(dateEnd, dateStart);
  const isUnderOneDay = hourDifference < 23;
  if (isUnderOneDay) {
    return (
      <Typography>
        {`${format(dateStart, 'HH:mm')}-${format(dateEnd, 'HH:mm')}`}
      </Typography>
    );
  } return '';
};

const ApprovalAbsenceCard: FC = observer(() => {
  const [approval, setApproval] = useState<ManagerApprovalDetailDto>(null);
  const { t } = useTranslation();
  const mounted = useMounted();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { approvalType, approvalId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false);
  const [openAttachmentApproveDialog, setOpenAttachmentApproveDialog] = useState<boolean>(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState<boolean>(false);
  const [openAttachmentDeclineDialog, setOpenAttachmentDeclineDialog] = useState<boolean>(false);
  const [openHolidayBonusChangeRequestDeclineDialog, setOpenHolidayBonusChangeRequestDeclineDialog] = useState<boolean>(false);
  const [openHolidayBonusChangeRequestApproveDialog, setOpenHolidayBonusChangeRequestApproveDialog] = useState<boolean>(false);
  const [openHolidayBonusChangeRequestReturnDialog, setOpenHolidayBonusChangeRequestReturnDialog] = useState<boolean>(false);
  const [openImgCarouselDialog, setOpenImgCarouselDialog] = useState<boolean>(false);
  const [absenceTypes, setAbsenceTypes] = useState<ManagerApprovalAbsenceTypeDto[]>([]);
  const [attachmentIndex, setAttachmentIndex] = useState<number>(null);
  const [error, setError] = useState<boolean>(false);
  const [absenceFormVisible, setAbsenceFormVisible] = useState(false);
  const [editAbsence, setEditAbsence] = useState<ManagerApprovalDetailDto>(null);
  const location = useLocation();
  const { canEdit } = location.state as ManagerInfo;
  const theme = useTheme();

  const getAbsence = useCallback(async () => {
    try {
      setLoading(true);
      let data: ManagerApprovalDetailDto;
      if (approvalType === 'absence') {
        data = await managerApprovalsApi.getAbsence(approvalId, user?.userId, user?.customerId);
      } else if (approvalType === 'attachment') {
        data = await managerApprovalsApi.getAttachment(approvalId, user?.userId, user?.customerId);
      } else {
        data = await managerApprovalsApi.getHolidayBonusChangeRequestDetails(user, approvalId, user?.customerId);
      }

      if (data.absence) {
        const at = await managerApprovalsApi.getAttachmentRequirements(user?.userId);
        if (mounted.current) {
          setAbsenceTypes(at);
        }
      }

      if (mounted.current) {
        setApproval(data);
      }
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [mounted]);

  useEffect(() => {
    getAbsence();
  }, [mounted]);

  const creationDateFormatter = () => {
    const creationDate = new Date(ensureUTCFormat(approval.creationTimeUTC));
    return `${format(creationDate, 'dd.MM.yyyy HH:mm')}`;
  };

  const attachmentTypeTranslator = (): string => {
    switch (approval.attachment.attachmentType) {
      case 'EmploymentDocument':
        return t('ApprovalEmploymentDocument');
      case 'SickLeaveCertificate':
        return t('ApprovalSickLeaveDocument');
      default:
        return t('ApprovalOtherDocument');
    }
  };

  const translateApprovalType = (): string => {
    switch (approvalType) {
      case 'absence':
        return t('ApprovalsAbsenseTitle');
      case 'attachment': 
        return t('ApprovalsAttachmentTitle');
      case 'holidayBonusChangeRequest':
        return t('ApprovalsHolidayBonusChangeRequest');
    }
  };

  const translateApprovalStatus = (status: ManagerApprovalStatus) => {
    switch (status) {
      case 'Pending':
        return t('ApprovalsStatusPending');
      case 'Approved':
        return t('ApprovalsStatusApproved');
      case 'Declined':
        return t('ApprovalsStatusDeclined');
      case 'Returned':
        return t('ApprovalsStatusReturned');
    }
  };

  const handleEditAbsence = (approvalItem: ManagerApprovalDetailDto) => {
    setEditAbsence(approvalItem);
    setAbsenceFormVisible(true);
  };

  const updateAbsence = async () => {
    if (approvalType === 'absence') {
      await getAbsence();
    }
  };

  const handleCloseApprovalDialogWithoutSuccess = () => {
    setOpenApproveDialog(false);
  };

  const handleCloseApprovalDialogWithSuccess = () => {
    getAbsence();
    setOpenApproveDialog(false);
  };

  const handleClickOpenApprovalDialog = () => {
    setOpenApproveDialog(true);
  };

  const handleCloseAttachmentApprovalDialogWithoutSuccess = () => {
    setOpenAttachmentApproveDialog(false);
  };

  const handleCloseAttachmentApprovalDialogWithSuccess = () => {
    getAbsence();
    setOpenAttachmentApproveDialog(false);
  };

  const handleClickOpenAttachmentApprovalDialog = () => {
    setOpenAttachmentApproveDialog(true);
  };

  const handleCloseDeclineDialogWithoutSuccess = () => {
    setOpenDeclineDialog(false);
  };

  const handleCloseDeclineDialogWithSuccess = () => {
    getAbsence();
    setOpenDeclineDialog(false);
  };

  const handleClickOpenDeclineDialog = () => {
    setOpenDeclineDialog(true);
  };

  const handleCloseAttachmentDeclineDialogWithoutSuccess = () => {
    setOpenAttachmentDeclineDialog(false);
  };

  const handleCloseAttachmentDeclineDialogWithSuccess = () => {
    getAbsence();
    setOpenAttachmentDeclineDialog(false);
  };

  const handleClickOpenAttachmentDeclineDialog = () => {
    setOpenAttachmentDeclineDialog(true);
  };

  const handleOpenImgCarouselDialog = (ai: number) => {
    setAttachmentIndex(ai);
    setOpenImgCarouselDialog(true);
  };

  const handleCloseImgCarouselDialog = () => {
    setOpenImgCarouselDialog(false);
  };

  const handleCloseHolidayBonusChangeRequestApprovalDialogWithoutSuccess = () => {
    setOpenHolidayBonusChangeRequestApproveDialog(false);
  };

  const handleCloseHolidayBonusChangeRequestApprovalDialogWithSuccess = () => {
    getAbsence();
    setOpenHolidayBonusChangeRequestApproveDialog(false);
  };

  const handleClickOpenHolidayBonusChangeRequestApprovalDialog = () => {
    setOpenHolidayBonusChangeRequestApproveDialog(true);
  };

  const handleCloseHolidayBonusChangeRequestDeclineDialogWithoutSuccess = () => {
    setOpenHolidayBonusChangeRequestDeclineDialog(false);
  };

  const handleCloseHolidayBonusChangeRequestDeclineDialogWithSuccess = () => {
    getAbsence();
    setOpenHolidayBonusChangeRequestDeclineDialog(false);
  };

  const handleClickOpenHolidayBonusChangeRequestDeclineDialog = () => {
    setOpenHolidayBonusChangeRequestDeclineDialog(true);
  };

  const handleCloseHolidayBonusChangeRequestReturnDialogWithoutSuccess = () => {
    setOpenHolidayBonusChangeRequestReturnDialog(false);
  };

  const handleCloseHolidayBonusChangeRequestReturnDialogWithSuccess = () => {
    getAbsence();
    setOpenHolidayBonusChangeRequestReturnDialog(false);
  };

  const handleClickOpenHolidayBonusChangeRequestReturnDialog = () => {
    setOpenHolidayBonusChangeRequestReturnDialog(true);
  };

  const needsAttachment = (): boolean => {
    const absence = absenceTypes.find((type) => type.absenceTypeId === approval.absence?.absenceTypeId);
    const attachmentRequirement = absence?.attachmentRequirement ?? approval.absence?.attachmentRequirement;
    switch (attachmentRequirement) {
      case 'Required':
        return true;
      default:
        return false;
    }
  };

  const WhiteUnderlineLink = withStyles({
    root: {
      color: theme.palette.primary.sidebarText,
      textDecoration: 'underline #b5b5b5'
    }
  })(Link);

  if (error) {
    return (
      <NotFound />
    );
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
          mx: 2
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
              sx={{ mb: 3 }}
            >
              {t('ApprovalsPageTitle')}
            </Typography>
            <Grid
              container
              direction="row"
            >
              <Breadcrumbs
                sx={{
                  paddingLeft: '1%',
                }}
                aria-label="breadcrumb"
                separator={(
                  <ChevronRightIcon
                    fontSize="small"
                    sx={{ mb: 5 }}
                  />
                )}
              >
                <Grid
                  item
                  sx={{ mb: 5 }}
                >
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/dashboard/landingpage"
                    variant="subtitle2"
                  >
                    {t('LandingPagePageTitle')}
                  </Link>
                </Grid>
                <Grid
                  item
                  sx={{ mb: 5 }}
                >
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/dashboard/approvals"
                    variant="subtitle2"
                  >
                    {t('ApprovalsPageTitle')}
                  </Link>
                </Grid>
                <Grid item>
                  <Typography
                    color="text.primary"
                    sx={{ mb: 5 }}
                    variant="subtitle2"
                  >
                    {approvalType === 'holidayBonusChangeRequest' ? t('ApprovalsHolidayBonusChangeRequestBreadcrumb') : t('ApprovalsAbsenceAttachmentBreadcrumb')}
                  </Typography>
                </Grid>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
          >
            {loading && (
              <Box
                display="flex"
                justifyContent="center"
                sx={{ mt: 10 }}
                bgcolor="background.secondary"
              >
                <CircularProgress
                  size={65}
                />
              </Box>
            )}
            {!loading && (
              <Fade in>
                <Card sx={{ minWidth: '100%', maxWidth: '100%', backgroundColor: 'primary.cardBackground' }}>
                  <Card sx={{ minWidth: '100%', maxWidth: '100%', backgroundColor: 'primary.cardBackground', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px', boxShadow: 'none' }}>
                    <Box sx={{ m: 3 }}>
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          sx={{ mb: 0 }}
                        >
                          <Grid 
                            item
                            xs={12}
                            sx={{ mb: 4 }}
                          >
                            <Typography
                              color="textPrimary"
                              variant="h4"
                              fontWeight={700}
                              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                            >
                              {translateApprovalType()} 
                              {approval.absence && approval.absence.absenceCategory === 'SickLeave' && (
                                <SickOutlinedIcon fontSize="large"/>
                              )}
                              {approval.attachment && !approval.absence && (
                                <AttachmentIcon fontSize="large"/>
                              )}
                            </Typography>
                            <Typography
                              color={ approval.approvalStatus === 'Approved' ? 'success.main' 
                                : approval.approvalStatus === 'Declined' ? 'error.main' 
                                  : 'textPrimary'}
                              variant="h6"
                            >
                              {translateApprovalStatus(approval.approvalStatus)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{ mb: 1 }}
                          >
                            <Box
                              minWidth="30%"
                              maxWidth="30%"
                            >
                              <UserDisplay 
                                displayName={approval.requester.name}
                                secondaryText={approval.requester.username}
                                imageUrl={approval.requester.profilePictureUrl}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                           container
                           sx={{ mb: 0 }}
                        >
                          {approval.absence && approval.absence.startOfAbsenceUTC && approval.absence.endOfAbsenceUTC && (
                            <Grid
                              item
                              xs={12}
                            >
                              <Typography
                                color="textPrimary"
                                variant="h6"
                              >
                                {`${absenceTranslator(t, approval.absence.absenceCategory)} ${absenceTimeDurationFormatter(t, approval.absence.startOfAbsenceUTC, approval.absence.endOfAbsenceUTC)}`}
                              </Typography>
                              <Typography
                                color="textSecondary"
                                variant="h6"
                              >
                                {`${datesFormatter(approval.absence.startOfAbsenceUTC, approval.absence.endOfAbsenceUTC)}`}
                              </Typography>
                              {timeFormatter(approval.absence.startOfAbsenceUTC, approval.absence.endOfAbsenceUTC)}
                            </Grid>
                          )}
                          {approval.attachment && !approval.absence && (
                            <Typography
                              color="textPrimary"
                              variant="h6"
                            >
                              {attachmentTypeTranslator()}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                  {(approval.absence || approval.attachment) && (
                    <>
                      <Table >
                          <TableHead>
                            <TableRow>
                              {approval.absence && (<TableCell><Typography variant="h7">{t('ApprovalAbsenceTypeTitle')}</Typography></TableCell>)}
                              <TableCell><Typography variant="h7">{t('ApprovalCreationTimeTitle')}</Typography></TableCell>
                              <TableCell><Typography variant="h7">{t('ApprovalNameTitle')}</Typography></TableCell>
                              <TableCell><Typography variant="h7">{t('ApprovalPhoneNumberTitle')}</Typography></TableCell>
                              <TableCell><Typography variant="h7">{t('ApprovalsManager')}</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              {approval.absence && (<TableCell>{approval.absence?.absenceTypeName ?? absenceTypes.find(type => type.absenceTypeId === approval.absence?.absenceTypeId)?.name}</TableCell>)}
                              <TableCell>{creationDateFormatter()}</TableCell>
                              <TableCell>{approval.userName}</TableCell>
                              <TableCell> 
                                <WhiteUnderlineLink
                                  href={`tel:${approval.userPhoneNumber}`}
                                  minWidth="20%"
                                  maxWidth="20%"
                                >
                                  {approval.userPhoneNumber}
                                </WhiteUnderlineLink>
                              </TableCell>
                              <TableCell>
                                {approval?.manager && (
                                  <>
                                    {approval.manager.name}
                                    <div style={{ opacity: 0.5, fontSize: '0.8rem' }}>{approval.manager.username}</div>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                      </Table>
                      {approval.approverComment && (
                        <>
                          <Grid
                            container
                            sx={{ mx: 2, mt: 4, mb: 2 }}
                            direction="column"
                          >
                            <Typography
                              minWidth="25%"
                              variant="h6"
                            >
                              {t('ApproverCommentTitle')}
                            </Typography>    
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            container
                            sx={{ mx: 4, mt: 1, mb: 2 }}
                          >
                            <Typography
                                minWidth="25%"
                            >
                              {approval.approverComment}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Divider />
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                  >
                    {!approval.holidayBonusChangeRequest && (
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        fontWeight={700}
                        sx={{ mx: 2, mt: 2, mb: 1 }}
                      >
                        {t('ApprovalAttachmentsTitle')}
                      </Typography>
                    )}
                    {!(approval.attachment?.files?.length > 0) && !approval.holidayBonusChangeRequest && (
                      <>
                        {needsAttachment() && (
                          <Typography
                            color="error.main"
                            variant="h6"
                            sx={{ mx: 4, mt: 1, mb: 2 }}
                          >
                            {t('ApprovalsAttachmentsMissing')}
                          </Typography>
                        )}
                        {!needsAttachment() && (
                          <Typography
                            color="textPrimary"
                            variant="h6"
                            sx={{ mx: 4, mt: 1, mb: 2 }}
                          >
                            {t('ApprovalsNoAttachments')}
                          </Typography>
                        )}
                      </>
                    )}
                    {approval.attachment && (
                      <>
                        <Grid
                          item
                          xs={11}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          sx={{ mx: 4, mt: 1, mb: 2 }}
                        >
                          {approval.attachment.files.map((f, i) => (
                            <Grid
                              key={f.userAttachmentFileId}
                              container
                              direction="row"
                              alignItems="center"
                            >
                              {f.uri && (
                                <div key={f.userAttachmentFileId}>
                                  <Button
                                    variant="text"
                                    color="inherit"
                                    startIcon={<AttachmentIcon />}
                                    onClick={() => {
                                      handleOpenImgCarouselDialog(i);
                                    }}
                                  >
                                    {f.originalFilename}
                                  </Button>
                                </div>
                              )}
                              {!f.uri && (
                                <>
                                  <Typography
                                    fontWeight={600}
                                    sx={{
                                      color: '##808080',
                                      marginRight: '8px',
                                    }}
                                  >
                                    {f.originalFilename}
                                  </Typography>
                                  <AutoDeleteOutlinedIcon />
                                </>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                    {approval.holidayBonusChangeRequest && (
                      <>
                        <Table >
                          <TableHead>
                            <TableRow>
                              <TableCell><Typography variant="h7">{t('ApprovalRequestedDays')}</Typography></TableCell>
                              <TableCell><Typography variant="h7">{t('ReceivedVacationDaysAmount')}</Typography></TableCell>
                              <TableCell><Typography variant="h7">{t('ApprovalCreationTimeTitle')}</Typography></TableCell>
                              <TableCell><Typography variant="h7">{t('ApprovalNameTitle')}</Typography></TableCell>
                              <TableCell><Typography variant="h7">{t('ApprovalPhoneNumberTitle')}</Typography></TableCell>
                              <TableCell><Typography variant="h7">{t('ApprovalsManager')}</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{approval.holidayBonusChangeRequest.days}</TableCell>
                              <TableCell>{approval.holidayBonusChangeRequest.receivedDays}</TableCell>
                              <TableCell>{creationDateFormatter()}</TableCell>
                              <TableCell>{approval.userName}</TableCell>
                              <TableCell> 
                                <WhiteUnderlineLink
                                  href={`tel:${approval.userPhoneNumber}`}
                                  minWidth="20%"
                                  maxWidth="20%"
                                >
                                  {approval.userPhoneNumber}
                                </WhiteUnderlineLink>
                              </TableCell>
                              <TableCell>
                                {approval?.manager && (
                                  <>
                                    {approval.manager.name}
                                    <div style={{ opacity: 0.5, fontSize: '0.8rem' }}>{approval.manager.username}</div>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        {approval.holidayBonusChangeRequest !== null && approval.approvalStatus === 'Pending' && (
                          <>
                            <Button
                              sx={{ m: 2 }}
                              variant="contained"
                              color="primary"
                              onClick={handleClickOpenHolidayBonusChangeRequestDeclineDialog}
                              size="large"
                            >
                              {t('DeclineTitle')}
                            </Button>
                            <Button
                              sx={{ m: 2 }}
                              variant="contained"
                              color="primary"
                              onClick={handleClickOpenHolidayBonusChangeRequestReturnDialog}
                              size="large"
                            >
                              {t('ReturnTitle')}
                            </Button>
                            <Button
                              sx={{ m: 2 }}
                              variant="contained"
                              color="success"
                              onClick={handleClickOpenHolidayBonusChangeRequestApprovalDialog}
                              size="large"
                            >
                              {t('ApproveTitle')}
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    {approval.approvalStatus !== 'Approved' && approval.approvalStatus !== 'Declined' && approval.absence !== null && (
                      <Button
                        sx={{ ml: 3 }}
                        variant="contained"
                        onClick={handleClickOpenDeclineDialog}
                        size="large"
                      >
                        {t('ReturnTitle')}
                      </Button>
                    )}
                    {approval.approvalStatus !== 'Approved' && approval.approvalStatus !== 'Declined' && approval.absence !== null && (
                      <Button
                        sx={{ m: 2 }}
                        variant="contained"
                        color="success"
                        onClick={handleClickOpenApprovalDialog}
                        size="large"
                      >
                        {t('ApproveTitle')}
                      </Button>
                    )}
                    {canEdit && approval.approvalStatus !== 'Approved' && approval.approvalStatus !== 'Declined' && approval.absence !== null && (
                      <Button
                        variant="contained"
                        color="success"
                        type="submit"
                        startIcon={<EditIcon />}
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditAbsence(approval);
                        }}
                      >
                        {t('EditButtonTitle')}
                      </Button>
                    )}
                    {approval.approvalStatus !== 'Approved' && approval.approvalStatus !== 'Declined' && approval.attachment && approval.absence === null && (
                      <Button
                        sx={{ m: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpenAttachmentDeclineDialog}
                        size="large"
                      >
                        {t('ReturnTitle')}
                      </Button>
                    )}
                    {approval.approvalStatus !== 'Approved' && approval.approvalStatus !== 'Declined' && approval.attachment && approval.absence === null && (
                      <Button
                        sx={{ m: 2 }}
                        variant="contained"
                        color="success"
                        onClick={handleClickOpenAttachmentApprovalDialog}
                        size="large"
                      >
                        {t('ApproveTitle')}
                      </Button>
                    )}
                  </Grid>
                </Card>
              </Fade>
            )}
            {approval && approval.absence && (
              <AbsenceApprovalDialog
                openDialog={openApproveDialog}
                closeDialogWithSuccess={handleCloseApprovalDialogWithSuccess}
                isAttachmentRequired={needsAttachment()}
                absenceAttachment={approval.attachment}
                closeDialogWithoutSuccess={handleCloseApprovalDialogWithoutSuccess}
                managerId={user?.userId}
                id={approval.absence.absenceId}
                name={approval.userName}
                absenceString={`${absenceTranslator(t, approval.absence.absenceCategory)} ${absenceTimeDurationFormatter(t, approval.absence.startOfAbsenceUTC, approval.absence.endOfAbsenceUTC)}`}
              />
            )}
            {approval && approval.absence && (
              <AbsenceDeclineDialog
                openDialog={openDeclineDialog}
                closeDialogWithSuccess={handleCloseDeclineDialogWithSuccess}
                closeDialogWithoutSuccess={handleCloseDeclineDialogWithoutSuccess}
                managerId={user?.userId}
                id={approval.absence.absenceId}
                name={approval.userName}
                absenceString={`${absenceTranslator(t, approval.absence.absenceCategory)} ${absenceTimeDurationFormatter(t, approval.absence.startOfAbsenceUTC, approval.absence.endOfAbsenceUTC)}`}
              />
            )}
            {approval && approval.attachment && (
              <AttachmentApprovalDialog
                openDialog={openAttachmentApproveDialog}
                closeDialogWithSuccess={handleCloseAttachmentApprovalDialogWithSuccess}
                closeDialogWithoutSuccess={handleCloseAttachmentApprovalDialogWithoutSuccess}
                managerId={user?.userId}
                id={approval.attachment.userAttachmentId}
                attachmentType={attachmentTypeTranslator()}
                name={approval.userName}
              />
            )}
            {approval && approval.attachment && (
              <AttachmentDeclineDialog
                openDialog={openAttachmentDeclineDialog}
                closeDialogWithSuccess={handleCloseAttachmentDeclineDialogWithSuccess}
                closeDialogWithoutSuccess={handleCloseAttachmentDeclineDialogWithoutSuccess}
                managerId={user?.userId}
                id={approval.attachment.userAttachmentId}
                attachmentType={attachmentTypeTranslator()}
                name={approval.userName}
              />
            )}
            <Grid
              container
              justifyContent="center"
            >
              {approval && approval.attachment && openImgCarouselDialog && (
                <AttachmentsPreviewDialog
                  onClose={handleCloseImgCarouselDialog}
                  files={approval.attachment.files}
                  initialIndex={attachmentIndex}
                />
              )}
            </Grid>
            {approval && approval.holidayBonusChangeRequest && (
              <>
                <HolidayBonusChangeRequestApprovalDialog
                  openDialog={openHolidayBonusChangeRequestApproveDialog}
                  closeDialogWithSuccess={handleCloseHolidayBonusChangeRequestApprovalDialogWithSuccess}
                  closeDialogWithoutSuccess={handleCloseHolidayBonusChangeRequestApprovalDialogWithoutSuccess}
                  managerId={user?.userId}
                  id={approval.holidayBonusChangeRequest.holidayBonusChangeRequestId}
                  name={approval.userName}
                  days={approval.holidayBonusChangeRequest.days}
                  period={`${displayLocalDate(approval.holidayBonusChangeRequest.period.periodStartUtc, true)}–${displayLocalDate(approval.holidayBonusChangeRequest.period.periodEndUtc, true)}`}
                  receivedDays={approval.holidayBonusChangeRequest.receivedDays}
                />
                <HolidayBonusChangeRequestDeclineDialog
                  openDialog={openHolidayBonusChangeRequestDeclineDialog}
                  closeDialogWithSuccess={handleCloseHolidayBonusChangeRequestDeclineDialogWithSuccess}
                  closeDialogWithoutSuccess={handleCloseHolidayBonusChangeRequestDeclineDialogWithoutSuccess}
                  managerId={user?.userId}
                  id={approval.holidayBonusChangeRequest.holidayBonusChangeRequestId}
                  name={approval.userName}
                  days={approval.holidayBonusChangeRequest.days}
                  period={`${displayLocalDate(approval.holidayBonusChangeRequest.period.periodStartUtc, true)}–${displayLocalDate(approval.holidayBonusChangeRequest.period.periodEndUtc, true)}`}
                  receivedDays={approval.holidayBonusChangeRequest.receivedDays}
                />
                <HolidayBonusChangeRequestReturnDialog
                  openDialog={openHolidayBonusChangeRequestReturnDialog}
                  closeDialogWithSuccess={handleCloseHolidayBonusChangeRequestReturnDialogWithSuccess}
                  closeDialogWithoutSuccess={handleCloseHolidayBonusChangeRequestReturnDialogWithoutSuccess}
                  managerId={user?.userId}
                  id={approval.holidayBonusChangeRequest.holidayBonusChangeRequestId}
                  name={approval.userName}
                  days={approval.holidayBonusChangeRequest.days}
                  period={`${displayLocalDate(approval.holidayBonusChangeRequest.period.periodStartUtc, true)}–${displayLocalDate(approval.holidayBonusChangeRequest.period.periodEndUtc, true)}`}
                  receivedDays={approval.holidayBonusChangeRequest.receivedDays}
                />
              </>
            )}
          </Grid>
          {editAbsence && approval.absence &&
            <CommonModal open={absenceFormVisible}>
              <AbsenceForm
                absenceInformation={{ userName: approval.userName, absenceId: approval.absence.absenceId.toString() }}
                selectedSubordinateId={approval.userId}
                disableOnBehalfSubordinateOptions
                onAbsenceEdit={() => {
                  updateAbsence();
                  setAbsenceFormVisible(false);
                }}
                onCancel={() => {
                  setAbsenceFormVisible(false);
                }}
                alwaysPending={true}
              />
            </CommonModal>
          }
        </Container>
      </Box>

    </>

  );
});

export default ApprovalAbsenceCard;
