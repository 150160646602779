
import { Autocomplete, Breadcrumbs, Button, Checkbox, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Popover, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField, ToggleButton, Typography } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { managerApprovalsApi } from 'src/API/managerApprovalsApi';
import { useAppStore } from 'src/store/mobx';
import { AbsenceDto, ApprovalInclude, ApprovalItemDto, ApprovalQueryParameters, ApprovalSort, ManagerApprovalStatus, ManagerSubstitutionDto, SortDirection, UserReferenceDto } from 'src/types/apiSchemas';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { differenceInHours, format, isSameDay } from 'date-fns';
import { displayLocalDate, ensureUTCFormat } from 'src/utils/dateUtils';
import { baseApi } from 'src/API/baseApi';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import stateStore from 'src/store/mobx/stateStore';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from 'src/hooks';
import ManagerSubstitutionAlert from 'src/components/common/ManagerSubstitutionAlert';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CommonModal from 'src/components/common/CommonModal';
import AbsenceForm from 'src/components/dashboard/absences/AbsenceForm';
import EditIcon from '@mui/icons-material/Edit';
import { ManagerInfo } from 'src/components/dashboard/approvals/ApprovalCard';
import toast from 'react-hot-toast';

type Order = 'asc' | 'desc';

const Approvals: FC = observer(() => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const auth: IAuthorizationService = new AuthorizationService();
  const hrApprovalAccess = auth.get(user).hasHRApprovalsAccess;

  const { approvalFilters: { [user.customerId]: approvalFilters }, setApprovalFilters } = stateStore;
  const [approvalsFailed, setApprovalsFailed] = useState<boolean>(false);
  const [approvals, setApprovals] = useState<ApprovalItemDto[]>([]);
  const [selectedManager, setSelectedManager] = useState<string>(approvalFilters?.selectedManager ?? '');
  const [selectedStatus, setSelectedStatus] = useState(approvalFilters?.selectedStatus ?? 'Pending');
  const [selectedType, setSelectedType] = useState(approvalFilters?.selectedType ?? '');
  const [includeSubstitutions, setIncludeSubstitutions] = useState<boolean>(approvalFilters?.includeSubstitutions ?? false);
  const [searchString, setSearchString] = useState<string>(approvalFilters?.submitterQuery ?? '');
  const [allApprovals, setAllApprovals] = useState<boolean>(approvalFilters?.allApprovals ?? true);
  const [managersAndHr, setManagersAndHr] = useState<UserReferenceDto[]>(null);
  const [managerSubstitutions, setManagerSubstitutions] = useState<ManagerSubstitutionDto[]>(null);
  const [orderBy, setOrderBy] = useState<ApprovalSort>(approvalFilters?.orderBy ?? ApprovalSort.CreateTime);
  const [orderDirection, setOrderDirection] = useState<Order>(approvalFilters?.orderDirection ?? 'asc');
  const [popoverOpenId, setPopoverOpenId] = useState(null);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [nextPageApprovals, setNextPageApprovals] = useState<ApprovalItemDto[]>([]);
  const [absenceFormVisible, setAbsenceFormVisible] = useState(false);
  const [editAbsence, setEditAbsence] = useState<ApprovalItemDto>(null);
  const [multiselect, setMultiselect] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const pageSize = 20;
  const debouncedSearchString = useDebounce(searchString, 500);

  const approvalStatuses: ManagerApprovalStatus[] = ['Pending', 'Approved', 'Declined', 'Returned'];

  const getApprovals = async (append: boolean = false) => {
    try {
      const params: ApprovalQueryParameters = {
        customerId: user?.customerId,
        page: append ? page : 1,
        pageSize: pageSize,
        approvalStatus: selectedStatus as ManagerApprovalStatus,
        managerId: selectedManager !== '' ? parseInt(selectedManager, 10) : null,
        sort: orderBy,
        sortDirection: orderDirection === 'asc' ? SortDirection.Asc : SortDirection.Desc,
        includes: selectedType !== '' ? [selectedType as ApprovalInclude] : [ApprovalInclude.IncludeAbsences, ApprovalInclude.IncludeAttachments, ApprovalInclude.IncludeHolidayBalanceRequests],
        includeSubsituteManagerApprovals: includeSubstitutions,
        submitterName: searchString
      };
      setApprovalsFailed(false);
      setShowLoadMore(false);
      setLoading(true);

      if (append) {
        // appending next page to approvals for next page
        setApprovals([...approvals, ...nextPageApprovals]);
      } else {
        // resolving approvals from backend
        setApprovals([]);
        let response: ApprovalItemDto[];
        if (allApprovals && hrApprovalAccess) {
          response = await managerApprovalsApi.getApprovalsHR(params);
        } else {
          response = await managerApprovalsApi.getMyApprovals(params);
        }
        setApprovals(response);
        setLoading(false);
      }

      // resolving next page behind the scenes from backend
      let nextPage: ApprovalItemDto[];
      const nextPageParams = { ...params, page: page + 1 };
      if (allApprovals && hrApprovalAccess) {
        nextPage = await managerApprovalsApi.getApprovalsHR(nextPageParams);
      } else {
        nextPage = await managerApprovalsApi.getMyApprovals(nextPageParams);
      }

      setNextPageApprovals(nextPage || []);
      // next page length affects to if we show the 'Load more' button
      setShowLoadMore(nextPage?.length > 0);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setApprovalsFailed(true);
    }
  };

  const updateAbsence = async (absence: AbsenceDto, hasAttachments: boolean, approvalStatus: ManagerApprovalStatus) => {
    const approvalToUpdate = approvals.find((a) => a.id === absence.absenceId && a.approvalType === ApprovalInclude.IncludeAbsences);
    if (approvalToUpdate) {
      let absenceTypeName = absence.absenceType.contents.find((c) => c.languageCode === user.languageCode)?.name;
      if (!absenceTypeName) {
        absenceTypeName = absence.absenceType.contents.find((c) => !!c.name)?.name;
      }

      approvalToUpdate.status = approvalStatus;
      approvalToUpdate.absence = {
        ...approvalToUpdate.absence,
        absenceTypeId: absence.absenceType.absenceTypeId,
        absenceTypeName: absenceTypeName,
        attachmentsRequired: absence.absenceType.attachmentRequirement === 'Required',
        endOfAbsence: absence.endOfAbsenceUTC,
        startOfAbsence: absence.startOfAbsenceUTC,
        hasAttachments: hasAttachments
      };
    }
  };

  const getManagersAndHr = async () => {
    try {
      const response = await managerApprovalsApi.getManagerAndHrUsers(user?.customerId);
      setManagersAndHr(response);
    } catch (e) {
      console.error(e);
    }
  };

  const getMyEffectiveManagerSubstitutions = async () => {
    try {
      const response = await baseApi.getMyEffectiveManagerSubstitutions();
      setManagerSubstitutions(response);
    } catch (e) {
      console.error(e);
    }
  };

  const filtersChanged = () => {
    if (selectedStatus !== 'Pending' ||
      selectedManager !== '' ||
      selectedType !== '' ||
      !includeSubstitutions ||
      searchString != '' ||
      allApprovals
    )
      return true;
    else
      return false;
  };

  useEffect(() => {
    setApprovalFilters({
      selectedManager,
      selectedStatus,
      selectedType,
      submitterQuery: searchString,
      includeSubstitutions,
      allApprovals,
      orderBy,
      orderDirection
    }, user?.customerId);
    getApprovals();
  }, [allApprovals, selectedManager, selectedStatus, selectedType, includeSubstitutions, orderBy, orderDirection, debouncedSearchString, hrApprovalAccess]);

  useEffect(() => {
    getApprovals(true);
  }, [page]);

  useEffect(() => {
    if (hrApprovalAccess) {
      getManagersAndHr();
    }
    getMyEffectiveManagerSubstitutions();
  }, []);

  /**
   * editing absences availability to managers and substitution managers
   * @param approval
   * @returns
   */
  const hasEditPermission = (approval: ApprovalItemDto): boolean => {
    return approval.status === 'Pending' && approval.absence && (approval.manager?.id === user.userId || !!approval.substitutes?.find((s) => s.id === user.userId));
  };

  const handleEditAbsence = (approval: ApprovalItemDto) => {
    setEditAbsence(approval);
    setAbsenceFormVisible(true);
  };

  const absenceTimeDurationFormatter = (absenceStart, absenceEnd): string => {
    const startDate = new Date(ensureUTCFormat(absenceStart));
    const endDate = new Date(ensureUTCFormat(absenceEnd));
    const hourDifference = differenceInHours(endDate, startDate);
    const dayDifference = Math.ceil(hourDifference / 24);
    const isUnderOneHour = hourDifference < 1;
    if (isUnderOneHour) return `${t('ApprovalUnderHour')} ${t('ApprovalHourLabel')}`;
    const isUnderOneDay = hourDifference < 23;
    if (isUnderOneDay) return `${hourDifference} ${hourDifference < 2 ? t('ApprovalHourLabel') : t('ApprovalHoursLabel')}`;
    if (dayDifference < 2) return `1 ${t('ApprovalDayLabel')}`;
    return `${dayDifference} ${t('ApprovalDaysLabel')}`;
  };

  const holidayBonusChangeDaysFormatter = (approval: ApprovalItemDto) => {
    return (
      <div>
        <Typography>{t('ApprovalRequestedDays')}: <span style={{ fontWeight: 'bold' }}>{approval.holidayBonusChangeRequest.holidayBonusChangeRequestDays}</span></Typography>
        <Typography>{t('ReceivedVacationDaysAmount')}: <span style={{ fontWeight: 'bold' }}>{approval.holidayBonusChangeRequest.holidayBonusChangeReceivedDays}</span></Typography>
      </div>
    );
  };

  const approvalTypeToPath = (approval: ApprovalItemDto): string => {
    if (approval.absence) return `/dashboard/approvals/absence/${approval.id}`;
    if (approval.attachment) return `/dashboard/approvals/attachment/${approval.id}`;
    if (approval.holidayBonusChangeRequest) return `/dashboard/approvals/holidayBonusChangeRequest/${approval.id}`;
    return '';
  };

  const attachmentTypeTranslator = (approval: ApprovalItemDto): string => {
    switch (approval.attachment.attachmentType) {
      case 'EmploymentDocument':
        return t('ApprovalEmploymentDocument');
      case 'SickLeaveCertificate':
        return t('ApprovalSickLeaveDocument');
      default:
        return t('ApprovalOtherDocument');
    }
  };

  const dateFormatter = (approval: ApprovalItemDto) => {
    const dateStart = new Date(ensureUTCFormat(approval.absence.startOfAbsence));
    const dateEnd = new Date(ensureUTCFormat(approval.absence.endOfAbsence));
    if (isSameDay(dateEnd, dateStart)) {
      return `${format(dateStart, 'dd.MM.yyyy')}`;
    }
    return `${format(dateStart, 'dd.MM.yyyy')}–${format(dateEnd, 'dd.MM.yyyy')}`;
  };

  const handleSelectedManagerChange = (event: SelectChangeEvent) => {
    setSelectedManager(event.target.value);
  };

  const handleSelectedStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value);
  };

  const handleSelectedTypeChange = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value);
  };

  useEffect(() => {
    setSearchString(searchString);
  }, [searchString]);

  const handleIncludeSubstitutionsChange = (event) => {
    setIncludeSubstitutions(event.target.checked as boolean);
  };

  const handleAllApprovalsChange = (event) => {
    setSelectedManager('');
    setAllApprovals(event.target.checked as boolean);
  };

  const handleSortChange = (sort: ApprovalSort) => {
    if (orderBy === sort) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderDirection('asc');
      setOrderBy(sort);
    }
  };

  const translateApprovalStatus = (status: ManagerApprovalStatus) => {
    switch (status) {
      case 'Pending':
        return t('ApprovalsStatusPending');
      case 'Approved':
        return t('ApprovalsStatusApproved');
      case 'Declined':
        return t('ApprovalsStatusDeclined');
      case 'Returned':
        return t('ApprovalsStatusReturned');
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, popoverId) => {
    event.preventDefault();
    event.stopPropagation();
    setPopoverOpenId(popoverId);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setPopoverOpenId(null);
    setAnchorEl(null);
  };

  const multiSelectItem = (selectedId: number) => {
    if (!multiselect) return;

    const index = selectedItems.indexOf(selectedId);
    setSelectedItems((prevItems) => {
      if (index === -1) {
        return [...prevItems, selectedId];
      } else {
        return prevItems.filter(item => item !== selectedId);
      }
    });
  };

  useEffect(() => {
    if (!multiselect) {
      setSelectedItems([]);
    }
  }, [multiselect]);

  useEffect(() => {
    setMultiselect(false);
  }, [selectedType, selectedStatus, selectedManager, searchString, includeSubstitutions, allApprovals]);

  const selectAll = () => {
    setSelectedItems(approvals.map(approval => {return approval.id;}));

  };

  const approveSelected = async () => {
    try {
      await managerApprovalsApi.approveMultiHolidayBonusChangeRequests(user.userId, selectedItems, user.customerId);
      getApprovals();
      appStore.notificationStore.refreshApprovalNotifications(user);
      setSelectedItems([]);
      setMultiselect(false);
      toast.success(t('ApprovalsSaveSuccessful'));
    } catch (err) {
      console.log(err);
      toast.error(t('ApprovalsSaveFailed'));
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('ApprovalsPageHelmet')}</title>
      </Helmet>
      <Box
        mx={2}
        py={8}
      >
        <Container maxWidth="xl">
          <Typography
            color="textPrimary"
            variant="h5"
            sx={{ mb: 3 }}
          >
            {t('ApprovalsPageTitle')}
          </Typography>
          <Breadcrumbs
            sx={{ paddingLeft: '1%' }}
            aria-label="breadcrumb"
            separator={(
              <ChevronRightIcon
                fontSize="small"
                sx={{ mb: 5 }}
              />
            )}
          >
            <Grid
              item
              sx={{ mb: 5 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard/landingpage"
                variant="subtitle2"
              >
                {t('LandingPagePageTitle')}
              </Link>
            </Grid>
            <Grid item>
              <Typography
                color="text.primary"
                sx={{ mb: 5 }}
                variant="subtitle2"
              >
                {t('ApprovalsPageTitle')}
              </Typography>
            </Grid>
          </Breadcrumbs>
          <ManagerSubstitutionAlert isLoading={loading} />
          <Paper>
            <Grid
              container
              gap={3}
              sx={{ p: 2 }}
            >
              {hrApprovalAccess &&
                <Grid item xs={3} >
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Typography>{t('ApprovalsFilterOwnApprovals')}</Typography>
                    <Switch
                      checked={allApprovals}
                      onChange={handleAllApprovalsChange}
                    />
                    <Typography>{t('ApprovalsFilterAll')}</Typography>
                  </Stack>
                </Grid>
              }
              <Grid item xs={hrApprovalAccess ? 8 : 11}>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Switch
                    checked={includeSubstitutions}
                    onChange={handleIncludeSubstitutionsChange}
                  />
                  <Typography>{t('ApprovalsFilterSubstitutions')}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  autoComplete='off'
                  variant='outlined'
                  fullWidth
                  label={t('ApprovalsTableSender')}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                {allApprovals && !!managersAndHr ?
                  <>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={managersAndHr.map((a) => ({ name: a.name, id: a.id }))}
                        getOptionLabel={(option => option.name)}
                        value={managersAndHr.find((a) => a.id === parseInt(selectedManager, 10))}
                        onChange={(event, newValue) => {
                          setSelectedManager(newValue ? newValue.id.toString() : '');
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t('ApprovalsManager')}
                          />
                        )}
                      />
                    </FormControl>
                  </> :
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="manager-select-label">{t('ApprovalsManager')}</InputLabel>
                      <Select
                        labelId="manager-select-label"
                        id="manager-select"
                        value={selectedManager}
                        label={t('ApprovalsManager')}
                        onChange={handleSelectedManagerChange}
                      >
                        <MenuItem value={''}><em>{t('ApprovalsFilterSelectNone')}</em></MenuItem>
                        {managerSubstitutions?.map((ms) => (
                          <MenuItem key={ms.managerId} value={ms.managerId}>{ms.managerName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                }
              </Grid>
              <Grid
                item
                xs={2}
              >
                <FormControl fullWidth>
                  <InputLabel id="status-select-label">{t('StatusTitle')}</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={selectedStatus}
                    label={t('StatusTitle')}
                    onChange={handleSelectedStatusChange}
                  >
                    <MenuItem value={''}><em>{t('ApprovalsFilterAll')}</em></MenuItem>
                    {approvalStatuses.map((status) => (<MenuItem key={status} value={status}>{translateApprovalStatus(status)}</MenuItem>))}
                  </Select>
                </FormControl>

              </Grid>
              <Grid
                item
                xs={2}
              >
                <FormControl fullWidth>
                  <InputLabel id="type-select-label">{t('ApprovalsFilterType')}</InputLabel>
                  <Select
                    value={selectedType}
                    onChange={handleSelectedTypeChange}
                    label="Type"
                  >
                    <MenuItem value={''}><em>{t('ApprovalsFilterAll')}</em></MenuItem>
                    <MenuItem value="IncludeAbsences">{t('ApprovalsAbsenseTitle')}</MenuItem>
                    <MenuItem value="IncludeAttachments">{t('ApprovalsAttachmentTitle')}</MenuItem>
                    <MenuItem value="IncludeHolidayBalanceRequests">{t('ApprovalsHolidayBonusChangeRequest')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {filtersChanged() &&
                <Grid
                  item
                  xs={4}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedStatus('Pending');
                      setSelectedManager('');
                      setSelectedType('');
                      setIncludeSubstitutions(true);
                      setSearchString('');
                      setAllApprovals(false);
                    }}
                  >
                    {t('RemoveFiltersTitle')}
                  </Button>
                </Grid>
              }
              {selectedType === 'IncludeHolidayBalanceRequests' && selectedStatus === 'Pending' && approvals?.length > 0 && (
                <Grid
                  item
                  xs={12}
                >
                  <ToggleButton
                    sx={{ marginRight: '10px' }}
                    size="small"
                    value="multiselect"
                    selected={multiselect}
                    onChange={() => { setMultiselect((prevState) => !prevState); }}
                  >
                    {multiselect ? t('ApprovalsExitSelectMultiple') : t('ApprovalsSelectMultiple')}
                  </ToggleButton>
                  {multiselect && (
                    <Button
                      sx={{ marginRight: '10px' }}
                      variant="outlined"
                      onClick={selectAll}
                    >
                      {t('ApprovalsSelectAll')}
                    </Button>)}
                  {multiselect && selectedItems.length > 0 && (
                    <Button
                      variant="outlined"
                      onClick={approveSelected}
                    >
                      {t('ApprovalsApproveSelected')}
                    </Button>
                  )} 
                </Grid>
              )}
            </Grid>
            {approvalsFailed && (
              <Table component="div">
                <TableRow component="div">
                  <TableCell component="div" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <SentimentVeryDissatisfiedIcon fontSize="large" color="error" />
                    <Typography variant="h6" color="error">
                      {t('GeneralErrorMessage')} <Link href="/dashboard/approvals" style={{ cursor: 'pointer' }}>{t('GeneralErrorMessageTryAgain')}</Link>
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
            )}
            {approvals?.length > 0 &&
              <Table component="div">
                <TableHead component="div"
                  sx={{
                    'th': {
                      padding: '16px 8px'
                    }
                  }}
                >
                  <TableRow component="div">
                    <TableCell component="div"
                      sortDirection={orderBy === ApprovalSort.Submitter ? orderDirection : false}
                    >
                      <TableSortLabel
                        active={orderBy === ApprovalSort.Submitter}
                        direction={orderBy === ApprovalSort.Submitter ? orderDirection : 'asc'}
                        onClick={() => { handleSortChange(ApprovalSort.Submitter); }}
                        sx={{ marginLeft: '48px' }}
                      >
                        <Typography>{t('ApprovalsTableSender')}</Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell component="div">{t('StatusTitle')}</TableCell>
                    <TableCell component="div"
                      sortDirection={orderBy === ApprovalSort.CreateTime ? orderDirection : false}
                    >
                      <TableSortLabel
                        active={orderBy === ApprovalSort.CreateTime}
                        direction={orderBy === ApprovalSort.CreateTime ? orderDirection : 'asc'}
                        onClick={() => { handleSortChange(ApprovalSort.CreateTime); }}
                      >
                        <Typography>{t('CreatedTitle')}</Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell component="div"
                      sortDirection={orderBy === ApprovalSort.Manager ? orderDirection : false}
                    >
                      <TableSortLabel
                        active={orderBy === ApprovalSort.Manager}
                        direction={orderBy === ApprovalSort.Manager ? orderDirection : 'asc'}
                        onClick={() => { handleSortChange(ApprovalSort.Manager); }}
                      >
                        <Typography>{t('ApprovalsManager')}</Typography>
                      </TableSortLabel>

                    </TableCell>
                    {allApprovals && <TableCell component="div"><Typography>{t('ApprovalsTablePossibleApprovers')}</Typography></TableCell>}
                    {allApprovals && <TableCell component="div"><Typography>{t('UserRoleApprover')}</Typography></TableCell>}
                    {auth.get(user).hasManagerOrSubstituteManagerAccess && <TableCell component="div"></TableCell>}
                    {multiselect && <TableCell component="div"></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody component="div">
                  {approvals?.map((approval) => (
                    <TableRow
                      key={approval.id + '' + approval.approvalType}
                      component={multiselect ? 'div' : RouterLink}
                      to={approvalTypeToPath(approval)}
                      state={{ managerName: approval.manager?.name, canEdit: hasEditPermission(approval) } as ManagerInfo}
                      sx={{
                        textDecoration: 'none',
                        '&:hover': {
                          background: 'rgba(255, 255, 255, 0.05)'
                        },
                        'td': {
                          padding: '16px 8px'
                        },
                        ...(multiselect && { cursor: 'pointer' }),
                        ...(multiselect && selectedItems.includes(approval.id) && { outline: '1px solid #C70C0E' })
                      }}
                      onClick={() => {multiSelectItem(approval.id);}}
                    >
                      <TableCell component="div">
                        <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                          <div>
                            {approval.approvalType === ApprovalInclude.IncludeAbsences && <SickOutlinedIcon fontSize='large' />}
                            {approval.approvalType === ApprovalInclude.IncludeHolidayBalanceRequests && <DateRangeIcon fontSize='large' />}
                            {approval.approvalType === ApprovalInclude.IncludeAttachments && <AttachmentIcon fontSize='large' />}
                          </div>
                          <div>
                            <Typography sx={{ fontWeight: 600 }}>{approval.user.name}</Typography>
                            {approval.approvalType === ApprovalInclude.IncludeAbsences &&
                              <>
                                <Typography>
                                  {approval.absence.absenceTypeName} {absenceTimeDurationFormatter(approval.absence.startOfAbsence, approval.absence.endOfAbsence)}
                                </Typography>
                                <Typography>{dateFormatter(approval)}</Typography>
                                {approval.absence.attachmentsRequired && approval.absence.hasAttachments &&
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AttachmentIcon
                                      fontSize="small"
                                      sx={{
                                        mr: 1,
                                      }}
                                    />
                                    <Typography color="textSecondary">
                                      {t('ApprovalSickLeaveDocument')}
                                    </Typography>
                                  </div>
                                }
                                {approval.absence.attachmentsRequired && !approval.absence.hasAttachments &&
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AttachmentIcon
                                      color="error"
                                      fontSize="small"
                                      sx={{
                                        mr: 1,
                                      }}
                                    />
                                    <Typography color="textSecondary">
                                      {t('ApprovalsMissingAttachments')}
                                    </Typography>
                                  </div>
                                }
                              </>
                            }
                            {approval.approvalType === ApprovalInclude.IncludeHolidayBalanceRequests && holidayBonusChangeDaysFormatter(approval)}
                            {approval.approvalType === ApprovalInclude.IncludeAttachments && <Typography>{attachmentTypeTranslator(approval)}</Typography>}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell component="div">
                        {translateApprovalStatus(approval.status)}
                      </TableCell>
                      <TableCell component="div">
                        {displayLocalDate(approval.created, true)}
                      </TableCell>
                      <TableCell component="div">
                        {approval.manager?.name}
                      </TableCell>
                      {allApprovals &&
                        <>
                          <TableCell component="div">
                            <IconButton
                              color="primary"
                              onClick={(e) => handlePopoverOpen(e, approval.id + '' + approval.approvalType)}
                              size="large"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                            <Popover
                              open={popoverOpenId === approval.id + '' + approval.approvalType}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              <Typography sx={{ p: 1 }}>{approval.manager?.name}</Typography>
                              {approval.substitutes?.map((s) => (
                                <Typography key={s.id} sx={{ p: 1 }}>{s.name}</Typography>
                              ))}

                            </Popover>
                          </TableCell>
                          <TableCell component="div">
                            {approval.approver?.name}
                          </TableCell>

                        </>
                      }
                      {auth.get(user).hasManagerOrSubstituteManagerAccess &&
                      <TableCell component="div">
                        {ApprovalInclude.IncludeAbsences && (hasEditPermission(approval)) &&
                          (
                            <Button
                              variant="contained"
                              type="submit"
                              startIcon={<EditIcon />}
                              onClick={(e) => {
                                e.preventDefault();
                                handleEditAbsence(approval);
                              }}
                            >
                              {t('EditButtonTitle')}
                            </Button>
                          )
                        }
                      </TableCell>}
                      {multiselect && (
                        <TableCell component="div">
                          <Checkbox checked={selectedItems.includes(approval.id)}/>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
            {loading &&
              <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
            }
            {showLoadMore &&
              <Button
                fullWidth
                variant="light"
                onClick={
                  () => { setPage(page + 1); }
                }
              >
                {t('LoadMore')}
              </Button>
            }
          </Paper>
        </Container>
      </Box>
      {editAbsence &&
        <CommonModal open={absenceFormVisible}>
          <AbsenceForm
            absenceInformation={{ userName: editAbsence?.user?.name, absenceId: editAbsence.id.toString() }}
            selectedSubordinateId={editAbsence.user.id}
            disableOnBehalfSubordinateOptions
            onAbsenceEdit={(absence, hasAttachments, approvalStatus) => {
              updateAbsence(absence, hasAttachments, approvalStatus);
              setAbsenceFormVisible(false);
            }}
            onCancel={() => {
              setAbsenceFormVisible(false);
            }}
            alwaysPending={true}
          />
        </CommonModal>
      }
    </>
  );
});


export default Approvals;