import { useLayoutEffect, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Button, Collapse, IconButton, ListItem, Menu, MenuItem, Tooltip } from '@mui/material';
import type { ListItemProps } from '@mui/material';
import ChevronDownIcon from '../icons/ChevronDown';
import ChevronRightIcon from '../icons/ChevronRight';
import MoreVert from '@mui/icons-material/MoreVert';
import { baseApi } from 'src/API/baseApi';
import { allCustomersId } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import CreateMessageViewModal from './CreateMessageViewModal';
import { useTheme } from '@mui/material/styles';

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  amount?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  hideTitle?: boolean;
  showMessageActions?: boolean;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active = false,
    children,
    depth,
    icon,
    info,
    open: openProp = false,
    path,
    title,
    amount,
    hideTitle,
    showMessageActions,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(openProp);
  const messagesMenuAnchorEl = useRef(null);
  const [maessagesMenuOpen, setMessagesMenuOpen] = useState(false);
  const [createMessageViewModalOpen, setCreateMessageViewModalOpen] = useState(false);
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const titleElement = useRef(null);
  const headerElement = useRef(null);
  const [forceTooltip, setForceTooltip] = useState(false);
  const showTooltip = hideTitle || forceTooltip;
  const theme = useTheme();

  useLayoutEffect(() => {
    setTimeout(() => {
      if (titleElement.current) {
        const sw = titleElement.current.scrollWidth;
        const cw = titleElement.current.clientWidth;
        if (sw > cw) {
          setForceTooltip(true);
        }
      }
      if (headerElement.current) {
        const sw = headerElement.current.scrollWidth;
        const cw = headerElement.current.clientWidth;
        if (sw > cw) {
          setForceTooltip(true);
        }
      }
    }, 300);
  }, []);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 28;

  if (depth > 0) {
    paddingLeft = hideTitle ? 12 : 45 + 8 * depth;
  }
  const conditionalTooltip = (content) => showTooltip ?
    <Tooltip
      title={title}
      placement="right"
    >
      {content}
    </Tooltip> : content;

  // Branch
  if (children) {
    const content = (
      <div>
        <Button
          ref={headerElement}
          {...hideTitle ? {} : {
            endIcon: !open ? <ChevronRightIcon fontSize="small" />
              : <ChevronDownIcon fontSize="small" />
          }}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: `${theme.palette.primary.sidebarText} !important`,
            textDecoration: 'none !important',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            textAlign: 'left',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textTransform: 'none',
            width: '100%',
            height: '50px',
            fontSize: 16,
            whiteSpace: 'nowrap',
            ...(
              active && {
                color: 'primary.main',
                fontWeight: 'fontWeightBold',
                '& svg': {
                  color: 'primary.main'
                }
              }
            )
          }}
          variant="text"
          component={path && RouterLink}
          to={path}
        >
          <Box sx={{ flexGrow: 1, overflow: showTooltip ? 'hidden' : 'unset', textOverflow: 'ellipsis' }}>
            {(!hideTitle || depth > 0) && title}
          </Box>
          {info}
          {amount}
          {showMessageActions &&
            <IconButton
              ref={messagesMenuAnchorEl}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMessagesMenuOpen(true);
              }}
            >
              <MoreVert />
            </IconButton>}
        </Button>
        {showMessageActions && messagesMenuAnchorEl.current &&
          <Menu
            anchorEl={messagesMenuAnchorEl.current}
            open={maessagesMenuOpen}
            onClose={() => {
              setMessagesMenuOpen(false);
            }}
          >
            <MenuItem
              onClick={async () => {
                setMessagesMenuOpen(false);
                await baseApi.markAllAsRead(user.customerId === allCustomersId ? undefined : user.customerId);
                appStore.notificationStore.refreshUnreadMessagesNotifications(user);
              }}
            >
              {t('MessagesMarkAllAsRead')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setCreateMessageViewModalOpen(true);
                setMessagesMenuOpen(false);
              }}
            >
              {t('MessagesPageCreateView')}
            </MenuItem>
          </Menu>
        }
        {showMessageActions && createMessageViewModalOpen &&
          <CreateMessageViewModal
            onSave={() => {
              setCreateMessageViewModalOpen(false);
            }}
            onCancel={() => {
              setCreateMessageViewModalOpen(false);
            }}
          />}
      </div>
    );

    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...other}
      >
        {conditionalTooltip(content)}
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  const content = (
    <Button
      component={path && RouterLink}
      startIcon={icon}
      sx={{
        color: `${theme.palette.primary.sidebarText} !important`,
        textDecoration: 'none !important',
        fontWeight: 'fontWeightMedium',
        justifyContent: 'flex-start',
        textAlign: 'left',
        pl: `${paddingLeft}px`,
        pr: '8px',
        py: '12px',
        textTransform: 'none',
        width: '100%',
        height: '50px',
        fontSize: 16,
        whiteSpace: 'nowrap',
        ...(
          active && {
            color: 'primary.main',
            fontWeight: 'fontWeightBold',
            '& svg': {
              color: 'primary.main'
            }
          }
        )
      }}
      variant="text"
      to={path}
      ref={titleElement}
    >
      <Box sx={{ flexGrow: 1, overflow: showTooltip ? 'hidden' : 'unset', textOverflow: 'ellipsis' }}>
        {(!hideTitle || depth > 0) && title}
      </Box>
      <Box alignSelf="left">
        {amount}
      </Box>
      {info}
    </Button>
  );

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      {conditionalTooltip(content)}
    </ListItem>
  );
};

export default NavItem;
