const dateTimeFormat: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
const dateFormat: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
const timeFormat: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };

/**
 * ensures that the dateTime string has 'Z' to indicate UTC time
 * @param dateTime
 * @returns
 */
export const ensureUTCFormat = (dateTime: string): string => {
  if (!dateTime?.endsWith('Z')) { dateTime += 'Z'; }

  return dateTime;
};

/**
 * returns dateTime string in formatted local time
 * @param dateTime
 * @param isUTC
 * @param language
 * @returns
 */
export const displayLocalDateTime = (dateTime: string, isUTC: boolean, language?: string): string | null => {
  if (!dateTime || Number.isNaN(Date.parse(dateTime))) { return null; }

  if (isUTC) { dateTime = ensureUTCFormat(dateTime); }

  return `${new Date(dateTime).toLocaleString(language ?? 'fi', dateTimeFormat)}`;
};

export const displayLocalDate = (dateTime: string, isUTC: boolean, language?: string): string | null => {
  if (!dateTime || Number.isNaN(Date.parse(dateTime))) { return null; }

  if (isUTC) { dateTime = ensureUTCFormat(dateTime); }

  return `${new Date(dateTime).toLocaleString(language ?? 'fi', dateFormat)}`;
};

export const displayLocalTime = (dateTime: string, isUTC: boolean, language?: string): string | null => {
  if (!dateTime || Number.isNaN(Date.parse(dateTime))) { return null; }

  if (isUTC) { dateTime = ensureUTCFormat(dateTime); }

  return `${new Date(dateTime).toLocaleString(language ?? 'fi', timeFormat)}`;
};
