import axios, { CancelToken, CancelTokenSource, GenericAbortSignal } from 'axios';
import qs from 'qs';
import {
  AnnouncementCreationDto,
  AnnouncementEditDto,
  AnnouncementDetailDto,
  AnnouncementDetailDtoPagedResultDto,
  AnnouncementDto,
  AnnouncementQueryParameters,
  AnnouncementScopeDto,
  AnnouncementScopesQueryParameters,
  AnnouncementScopeType,
  Company,
  LoginInfoDto,
  ManagerApprovalDetailDto,
  NotificationPreference,
  PagedResult,
  AbsenceType,
  Absence,
  AbsenceResult,
  AttachmentType,
  UserDtoPagedResultDto,
  UserQueryParameters,
  AbsenceCategory,
  ManagerApprovalStatus,
  AbsenceTranslated,
  TranslatedMessageCategory,
  MessageThread,
  MessageThreadsResponse,
  Message,
  CardEntry,
  UserAccessRequestCreationDto,
  UserAccessRequestDto,
  CustomerToJoinSearchResultDto,
  DashboardCompanyQueryParameters,
  MessageThreadQueryParameters,
  MessageThreadStatus,
  UserAccessRequestApprovalDto,
  UserAccessRequestStatusEnum,
  MessageThreadVisibility,
  MessageThreadVisibilityParameters,
  MessageVisibility,
  User,
  MessageThreadAssigneeOptions,
  MessageThreadActivity,
  NotificationSetting,
  ThreadUserLastRead,
  UnreadMessageThreads,
  UserPersonalInformation,
  UserPersonalInformationUpdate,
  UserProfilePictureResultDto,
  MessageAttachmentCreation,
  MessageAttachment,
  TranslatedCreatedMessageThread,
  MessageThreadsFilters,
  MessageThreadRetentionDays,
  Customer,
  GenAiRequestItem,
  GenAiApprovalUpdateDto,
  SLAPriority,
  SubordinateDto,
  ManagerSubstitutionDto,
  MessageThreadRelatedItemOptionsDto,
  MessageThreadRelatedCompanyOrEmployeeUpdateDto,
  CustomerAttachmentTypeDto,
  AbsenceUpdate,
  AbsenceUpdateResult,
  AttachmentResult,
  MessageAttachmentType,
  UserRole,
  InternalRole,
  UserReferenceDto,
  TranslatedLinkGroupDto,
} from 'src/types/apiSchemas';
import { base64ImageToFile } from 'src/utils/imageUtils';
import { formatQueryParameterToString, getAuthHeader, getBackendUrl } from 'src/utils/requestUtils';
import { omitBy, isEmpty } from 'lodash';
import { isValidJSON } from 'src/utils/jsonUtils';
import { MessageView } from 'src/store/mobx/stateStore';

const { CancelToken: ct } = axios;
let source: CancelTokenSource;

// TODO: Refactor to fit new Announcements API when it is ready
class BaseApi {
  async login(token: string): Promise<LoginInfoDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/LoginInfoCustomerPortal`;
    try {
      const response = await axios.get(endpointUrl, {
        headers: {
          Authorization: getAuthHeader(token)
        }
      });
      const { data } = response;
      return await Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMyColleagues(userNameFilter?: string): Promise<UserDtoPagedResultDto> {
    try {
      const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/Colleagues`;
      const queryParams = { NameFilter: formatQueryParameterToString(userNameFilter), PageSize: 50 } as UserQueryParameters;
      const response = await axios(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: queryParams,
        paramsSerializer: (params) => qs.stringify(params),
      });
      const usersPaged: UserDtoPagedResultDto = response.data;
      return await Promise.resolve(usersPaged);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getCustomerCompanies(customerId: number): Promise<Company[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardAdmin/Customers/${customerId}/Companies`;
    try {
      const response = await axios(endpointUrl, { responseType: 'json' });
      const companies: Company[] = response.data;
      return await Promise.resolve(companies);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getCompany(companyId: number): Promise<Company> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardAdmin/Companies/${companyId}`;
    try {
      const response = await axios(endpointUrl, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAnnouncementsPaged(customerId: number, queryParameters: AnnouncementQueryParameters): Promise<AnnouncementDetailDtoPagedResultDto> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Announcements`;

    const formattedParameters = omitBy({
      SearchString: formatQueryParameterToString(queryParameters?.SearchString),
      AnnouncementType: formatQueryParameterToString(queryParameters?.AnnouncementType),
      AnnouncementImportance: formatQueryParameterToString(queryParameters?.AnnouncementImportance),
      PageNumber: queryParameters.PageNumber.toString(),
      PageSize: queryParameters.PageSize.toString()
    }, isEmpty);

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
          params: formattedParameters,
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        });
      const customersPaged: AnnouncementDetailDtoPagedResultDto = response.data;
      return await Promise.resolve(customersPaged);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async fetchAnnouncementScopes(customerId: number): Promise<AnnouncementScopeDto[]> {
    if (!customerId) return Promise.reject();
    // TODO: Move to a utils file?
    const ParseAnnouncementScopeType = (scopeName: string): AnnouncementScopeType => {
      switch (scopeName) {
        case 'companies':
          return AnnouncementScopeType.Company;
        case 'locations':
          return AnnouncementScopeType.Location;
        case 'organizationUnits':
          return AnnouncementScopeType.OrganizationUnit;
        case 'employmentTypes':
          return AnnouncementScopeType.EmploymentType;
        case 'businessUnits':
          return AnnouncementScopeType.BusinessUnit;
        default:
          throw new Error('Scope type not found');
      }
    };

    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/AnnouncementScopes`;
    try {
      const response = await axios.get(endpointUrl);
      const { data } = response;
      const entries = Object.entries(data as { [key: string]: string[] });
      const scopes: AnnouncementScopeDto[] = [];
      entries.forEach(([key, value]) => {
        value
          .filter((x) => x !== null)
          .forEach((x) => {
            scopes.push(
              {
                announcementScopeId: 0,
                scopeType: ParseAnnouncementScopeType(key),
                scopeValue: x,
              }
            );
          });
      });
      return await Promise.resolve(scopes);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postAnnouncement(creationDto: AnnouncementCreationDto): Promise<AnnouncementDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${creationDto.authorCustomerId}/Announcements`;
    try {
      const response = await axios.post(endpointUrl, JSON.stringify(creationDto), {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postAnnouncementImage(customerId: number, announcementId: number, base64image: string, imageName: string): Promise<AnnouncementDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Announcements/${announcementId}/Image`;
    try {
      const formData = new FormData();
      if (base64image !== null && imageName !== null) {
        formData.append('file', await base64ImageToFile(base64image, imageName));
      } else {
        return null;
      }
      const response = await axios.put(endpointUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async fetchAnnouncementScopeRecipients(customerId: number, parameters: AnnouncementScopesQueryParameters): Promise<number> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/AnnouncementRecipientCount`;
    try {
      const response = await axios.get(endpointUrl, {
        params: parameters,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateCurrentUserLanguageAndTheme(user: LoginInfoDto): Promise<void> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${user.userId}`;
    try {
      await axios.patch(endpointUrl, {}, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { languageCode: user.languageCode, siteTheme: user.siteTheme }
      });
      return await Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateUserNotificationPreferences(user: LoginInfoDto, preference: NotificationPreference): Promise<void> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${user.userId}/NotificationPreference`;
    try {
      await axios.patch(endpointUrl, {}, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { notificationPreference: preference }
      });
      return await Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAnnouncement(announcementId: string, customerId: number): Promise<AnnouncementDetailDto> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Announcements/${announcementId}`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results as AnnouncementDetailDto);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // TODO: Figure out if customer admin needs to be able to confirm announcements
  async putAnnouncementConfirmation(announcementId: string, userId: number): Promise<void> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Announcements/${announcementId}/UserConfirmation/${userId}`;
    try {
      // TODO: Working put-call
      const response = await axios.put(endpointUrl);
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async patchAnnouncement(editDto: AnnouncementEditDto): Promise<AnnouncementDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${editDto.authorCustomerId}/Announcements/${editDto.announcementId}`;
    try {
      const response = await axios.put(endpointUrl, JSON.stringify(editDto), {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteAnnouncementImage(editDto: AnnouncementEditDto): Promise<AnnouncementDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${editDto.authorCustomerId}/Announcements/${editDto.announcementId}/Image`;
    try {
      const response = await axios.delete(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async deleteAnnouncement(customerId: number, announcementId: number): Promise<void> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Announcements/${announcementId}`;
    try {
      await axios.delete(endpointUrl);
      return await Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getManagerApprovalAbsence(absenceId: string, managerId: number): Promise<ManagerApprovalDetailDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Managers/${managerId}/Absences/${absenceId}`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAbsenceTypes(customerId: number): Promise<AbsenceType[]> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/AbsenceTypes`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postAbsence(user: LoginInfoDto, absence: Absence, subordinateId?: number): Promise<AbsenceResult> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${user.userId}/Absences`;
    try {
      const response = await axios.post(endpointUrl, absence,
        {
          params: {
            subordinateId
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async putAbsence(user: LoginInfoDto, absenceId: number, absence: AbsenceUpdate, subordinateId?: number): Promise<AbsenceUpdateResult> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${user.userId}/Absences/${absenceId}`;
    try {
      const response = await axios.put(endpointUrl, absence,
        {
          params: {
            subordinateId
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async deleteAbsenceAttachmentFile(user: LoginInfoDto, absenceId: number, attachmentId: number, attachmentFileId: number, subordinateId?: number): Promise<AttachmentResult> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${user.userId}/Absences/${absenceId}/Attachments/${attachmentId}/AttachmentFiles/${attachmentFileId}`;
    try {
      const response = await axios.delete(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          subordinateId
        },
        responseType: 'json',
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postAttachment(user: LoginInfoDto, attachments: File[], attachmentType: AttachmentType, absenceId?: number, subordinateId?: number, isEditMode?: boolean) {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${user.userId}/Attachments`;
    const formData = new FormData();

    attachments.forEach((attachment) => {
      formData.append('files', attachment);
    });

    try {
      const response = await axios.post(endpointUrl, formData,
        {
          params: {
            attachmentType,
            absenceId,
            subordinateId,
            isEditMode
          },
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUserAbsences(userId: number, pageNumber: number, absenceCategory: AbsenceCategory, approvalStatus?: ManagerApprovalStatus, pageSize = 5): Promise<PagedResult<AbsenceTranslated>> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/Absences`;
    try {
      const response = await axios.get(endpointUrl,
        {
          params: {
            absenceCategory,
            approvalStatus,
            pageSize,
            pageNumber
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUserAbsence(userId: number, absenceId: string, subordinateId?: number): Promise<AbsenceTranslated> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/Absences/${absenceId}`;
    try {
      const response = await axios.get(endpointUrl,
        {
          params: {
            subordinateId
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAnnouncementConfirmations(customerId: number, announcementId: string): Promise<any> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Announcements/${announcementId}/AudienceConfirmations`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAnnouncementConfirmationCount(customerId: number, announcementId: string): Promise<any> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Announcements/${announcementId}/UserConfirmationsAmount`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async hasUserConfirmedAnnouncement(customerId: number, announcementId: string, userId: number): Promise<boolean> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Announcements/${announcementId}/HasUserConfirmed/${userId}`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async IsUserInAnnouncementAudience(announcementId: string, userId: number): Promise<boolean> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Announcements/${announcementId}/IsInAnnouncementAudience/${userId}`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getSubordinates(customerId: number): Promise<SubordinateDto[]> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${customerId}/Subordinates`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMyEffectiveManagerSubstitutions(): Promise<ManagerSubstitutionDto[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/ManagerSubstitutions`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMyManagerSubstitutionSubordinates(userId: number): Promise<SubordinateDto[]> {
    if (!userId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/ManagerSubstitutions/${userId}/Subordinates`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreads(parameters: MessageThreadQueryParameters, signal?: GenericAbortSignal): Promise<MessageThreadsResponse[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
          params: parameters,
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
          signal
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThread(messageThreadId: string): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadMessages(messageThreadId: string, after: string = null): Promise<Message[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Messages`;

    try {
      const response = await axios.get(endpointUrl,
        {
          params: {
            includeInternalMessages: true,
            after
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadVisibility(messageThreadId: string): Promise<MessageThreadVisibility> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Visibility`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadVisibility(messageThreadId: string, visibility: MessageThreadVisibility): Promise<MessageThreadVisibility> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Visibility`;

    try {
      const response = await axios.put(endpointUrl, visibility,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadVisibilityParameters(customerId: number, userNameFilter?: string): Promise<MessageThreadVisibilityParameters> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreadVisibilityParameters`;
    if (source) {
      source.cancel();
    }
    source = ct.source();
    try {
      const response = await axios.get(endpointUrl,
        {
          cancelToken: source.token,
          params: {
            userNameFilter,
            customerId
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadUsers(threadId: string, filter?: string, messageThreadVisibility?: MessageThreadVisibility, customerId?: number, signal?: GenericAbortSignal): Promise<User[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/Users`;

    try {
      const response = await axios.post(endpointUrl, messageThreadVisibility,
        {
          signal,
          params: {
            customerId,
            filter,
            threadId
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadAssigneeOptions(messageThreadId: string, userNameFilter: string, onlyIntegrataAssignees: boolean, cancelToken?: CancelToken): Promise<MessageThreadAssigneeOptions[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Assignee/Options`;

    try {
      const response = await axios.get(endpointUrl,
        {
          cancelToken,
          params: {
            userNameFilter,
            onlyIntegrataAssignees
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageCategories(customerId: number, includeTechnicalCategories?: boolean): Promise<TranslatedMessageCategory[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageCategories`;

    try {
      const response = await axios.get(endpointUrl,
        {
          params: {
            customerId,
            includeTechnicalCategories
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postMessageThread(message: MessageThread): Promise<TranslatedCreatedMessageThread> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads`;
    try {
      const response = await axios.post(endpointUrl, message,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadAssignee(messageThreadId: string, userId: number): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/assignee`;
    try {
      const response = await axios.patch(endpointUrl, { assigneeId: userId },
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadCustomerAssignee(messageThreadId: string, userId: number): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/customerAssignee`;
    try {
      const response = await axios.patch(endpointUrl, { assigneeId: userId },
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadRetentionPeriod(messageThreadId: string, retentionDays: MessageThreadRetentionDays): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/retention`;
    try {
      const response = await axios.put(endpointUrl, retentionDays,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postMessageThreadMessage(messageThreadId: string, content: string, richTextContent?: string, attachmentIds?: number[], userMentions?: number[], userRoleMentions?: UserRole[], internalRoleMentions?: InternalRole[]): Promise<Message> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Messages`;

    try {
      const response = await axios.post(endpointUrl, { content, richTextContent, attachmentIds, userMentions, userRoleMentions, internalRoleMentions },
        {
          responseType: 'json',
        });
      const result: Message = response.data;

      return await Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAttachmentSasUrl(attachmentUrl: string): Promise<string> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageAttachment/GetSasUrl`;

    try {
      const response = await axios.get(endpointUrl,
        {
          params: {
            attachmentUrl
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postMessageAttachment(messageId: number, messageAttachment: MessageAttachmentCreation): Promise<MessageAttachment> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageAttachment`;
    const formData = new FormData();

    formData.append('file', messageAttachment.file);

    try {
      const response = await axios.post(endpointUrl, formData,
        {
          params: {
            messageAttachmentType: messageAttachment.messageAttachmentType,
            messageId
          },
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postMessageAttachmentWithoutMessageId(file: File, customerId: number): Promise<MessageAttachment> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageAttachment`;
    const formData = new FormData();

    formData.append('file', file);

    try {
      const response = await axios.post(endpointUrl, formData,
        {
          params: {
            messageAttachmentType: MessageAttachmentType.Other,
            customerId
          },
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async deleteMessage(messageThreadUniqueId: string, messageId: number, customerId: number): Promise<Message> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadUniqueId}/Messages/${messageId}`;
    try {
      const response = await axios.delete(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postMessageThreadInternalMessage(messageThreadId: string, content: string, richTextContent: string, messageVisibility: MessageVisibility, userMentions: number[], userRoleMentions: UserRole[], internalRoleMentions: InternalRole[], attachmentIds: number[]): Promise<Message> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Messages`;
    try {
      const response = await axios.post(endpointUrl, { content, richTextContent, isInternal: true, messageVisibility, userMentions, userRoleMentions, internalRoleMentions, attachmentIds },
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadCategory(messageThreadId: string, categoryId: number, preserveVisibility: boolean): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/category/${categoryId}`;
    try {
      const response = await axios.patch(
        endpointUrl,
        null,
        {
          params: {
            preserveVisibility: preserveVisibility
          },
          responseType: 'json',
        });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadStatus(messageThreadId: string, status: MessageThreadStatus): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/MessageThreads/${messageThreadId}/status`;
    try {
      const response = await axios.patch(endpointUrl, { status },
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadMessageAiRequests(messageThreadId: string): Promise<GenAiRequestItem[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/GenAi`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async patchMessageThreadMessageAiRequestApprovalStatus(messageThreadId: string, requestId: number,
    approval: GenAiApprovalUpdateDto): Promise<GenAiRequestItem> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/GenAi/${requestId}`;

    try {
      const response = await axios.patch(endpointUrl, approval,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postMessageThreadMessageAiRequest(messageThreadId: string): Promise<GenAiRequestItem> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/GenAi`;

    try {
      const response = await axios.post(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadPriority(messageThreadId: string, priority: SLAPriority): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Priority`;
    try {
      const response = await axios.patch(endpointUrl, priority,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageEmailFile(messageThreadId: string, messageId: number): Promise<Blob> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Messages/${messageId}/EmailFile`;
    try {
      const response = await axios.get(endpointUrl, { responseType: 'blob' });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUserCardEntries(userId: number): Promise<CardEntry[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/CardEntries`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async isConsultancyEnabledForUserName(userName: string): Promise<boolean> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/IsConsultancyEnabledForUserName`;
    try {
      if (!userName) { return false; }

      const queryParams = { userName };

      const response = await axios(endpointUrl,
        {
          responseType: 'json',
          params: { ...queryParams },
          paramsSerializer: (params) => qs.stringify(params),
        });
      const isEnabled: boolean = response.data;
      return await Promise.resolve(isEnabled);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async isConsultancyEnabledForUserIds(userIds: Number[]): Promise<{}> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/IsConsultancyEnabledForUserIds`;
    try {
      if (!userIds) { return {}; }

      const queryParams = { userIds };

      const response = await axios(endpointUrl,
        {
          responseType: 'json',
          params: { ...queryParams },
          paramsSerializer: (params) => qs.stringify(params),
        });
      const isEnabled: boolean = response.data;
      return await Promise.resolve(isEnabled);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async addOrUpdateUserAccessRequest(content: UserAccessRequestCreationDto): Promise<UserAccessRequestDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/UserAccessRequestAddOrUpdate`;
    try {
      const response = await axios.post(endpointUrl, content,
        {
          responseType: 'json',
        });
      const { data } = response;
      return await Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async searchCustomersToRequestAccess(searchString: string): Promise<CustomerToJoinSearchResultDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/UserAccessRequestSearchCustomers`;

    try {
      const queryParams = { SearchString: searchString } as DashboardCompanyQueryParameters;

      if (searchString?.length < 3) {
        return {} as CustomerToJoinSearchResultDto;
      }

      const response = await axios(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: queryParams,
        paramsSerializer: (params) => qs.stringify(params),
      });

      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getPendingUserAccessRequests(customerId: number): Promise<UserAccessRequestDto[]> {
    if (!customerId) return Promise.reject();
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/UserAccessRequests`;

    const queryParams = { status: UserAccessRequestStatusEnum.Pending };
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
          params: queryParams,
          paramsSerializer: (params) => qs.stringify(params),
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateUserAccessRequestApproval(customerId: number, content: UserAccessRequestApprovalDto): Promise<UserAccessRequestApprovalDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/UpdateUserAccessRequestApproval`;
    try {
      const response = await axios.patch(endpointUrl, content,
        {
          responseType: 'json',
        });
      const { data } = response;
      return await Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUnreadMessageThreads(customerId: number): Promise<string[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/UnreadThreads`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: { customerId },
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUnreadMessageThreadsAllCustomers(customerIds: number[]): Promise<UnreadMessageThreads[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/UnreadThreadsAllCustomers`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: { customerIds },
        paramsSerializer: (params) => qs.stringify(params)
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async markThreadAsRead(
    threadId: string,
    markAsUnread: boolean
  ): Promise<any> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MarkAsRead/${threadId}`;
    try {
      const response = await axios.put(endpointUrl, null, {
        responseType: 'json',
        params: { markAsUnread }
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async markAllAsRead(
    customerId?: number
  ): Promise<any> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MarkAllThreadsAsRead`;
    try {
      const response = await axios.put(endpointUrl, null, {
        responseType: 'json',
        params: { customerId }
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async leaveCustomerTeam(customerId: number): Promise<User> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Me/LeaveTeam`;
    try {
      const response = await axios.patch(endpointUrl, null,
        {
          responseType: 'json',
        });
      const { data } = response;
      return await Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadActivities(messageThreadId: string): Promise<MessageThreadActivity[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/Activities`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      const activitiesContentParsed = results.map((activity: any) => {
        if (isValidJSON(activity.content)) {
          return {
            ...activity,
            content: JSON.parse(activity.content)
          };
        }
        return null;
      }).filter((activity) => activity !== null);

      return await Promise.resolve(activitiesContentParsed);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getNotificationSettings(): Promise<NotificationSetting[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/NotificationSettings`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getNotificationPreference(): Promise<NotificationPreference> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/NotificationPreference`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateNotificationSettings(notificationSettings: NotificationSetting[]): Promise<NotificationSetting[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/NotificationSettings`;
    try {
      const response = await axios.patch(endpointUrl, notificationSettings, {
        responseType: 'json',
      });

      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadUserLastReads(messageThreadId: string): Promise<ThreadUserLastRead[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/UserSeenTimestamps`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadCC(messageThreadId: string): Promise<string[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/CC`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadCC(messageThreadId: string, emails: string[]) {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${messageThreadId}/CC`;
    try {
      const response = await axios.put(endpointUrl, emails, {
        responseType: 'json',
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getPersonalInformation(userId: number): Promise<UserPersonalInformation> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/PersonalInformation`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updatePersonalInformation(userId: number, data: UserPersonalInformationUpdate) {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/${userId}/PersonalInformation`;
    try {
      const response = await axios.put(endpointUrl, data, {
        responseType: 'json',
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async patchUserProfilePicture(picture: File, token?: string): Promise<UserProfilePictureResultDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/ProfilePicture`;
    const formData = new FormData();
    formData.append('picture', picture);

    try {
      const response = await axios.patch(endpointUrl, formData,
        {
          responseType: 'json',
          headers: token ? {
            'Content-Type': 'multipart/form-data',
            Authorization: getAuthHeader(token)
          } : {
            'Content-Type': 'multipart/form-data',
          },
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async deleteUserProfilePicture(): Promise<UserProfilePictureResultDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/ProfilePicture`;

    try {
      const response = await axios.delete(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return await Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadsFilters(customerId?: string): Promise<MessageThreadsFilters> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreadsFilters`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: {
          customerId
        }
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAssigneeFilters(customerId?: number, search?: string, assigneeIds?: number[]): Promise<MessageThreadsFilters> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/AssigneeFilters`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: {
          customerId,
          search,
          assigneeIds
        },
        paramsSerializer: (params) => qs.stringify(params)
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUserMentionFilters(customerId?: number, search?: string, userIds?: number[]): Promise<UserReferenceDto[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/UserMentionFilters`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: {
          customerId,
          search,
          userIds
        },
        paramsSerializer: (params) => qs.stringify(params)
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getResponsibleInternalRoleFilters(customerId?: number): Promise<MessageThreadsFilters> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/ResponsibleInternalRoleFilters`;
    try {
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: {
          customerId,
        },
        paramsSerializer: (params) => qs.stringify(params)
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUserCustomers(): Promise<Customer[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/Customers`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUserMessageViews(): Promise<MessageView[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/MessageViews`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateUserMessageViews(messageViews: MessageView[]): Promise<any> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/MessageViews`;

    try {
      const response = await axios.put(endpointUrl, messageViews,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMessageThreadRelatedItemOptions(customerId: number, userNameFilter: string): Promise<MessageThreadRelatedItemOptionsDto> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreadRelatedItemOptions`;

    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
          params: {
            customerId,
            userNameFilter
          }
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadRelatedItem(updateDto: MessageThreadRelatedCompanyOrEmployeeUpdateDto, threadId: string): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${threadId}/RelatedCompanyOrEmployee`;

    try {
      const response = await axios.put(endpointUrl, updateDto,
        {
          responseType: 'json',
          params: {
            updateDto
          }
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getAttachmentTypes(customerId: number): Promise<CustomerAttachmentTypeDto[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/AttachmentTypes`;
    try {
      const response = await axios.get(endpointUrl,
        {
          responseType: 'json',
        });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getDashboardCustomerCompanies(customerId: number): Promise<Company[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Companies`;
    try {
      const response = await axios(endpointUrl, { responseType: 'json' });
      const companies: Company[] = response.data;
      return await Promise.resolve(companies);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateMessageThreadResponsibleInternalRole(threadId: string, internalRole: InternalRole): Promise<MessageThreadsResponse> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Me/MessageThreads/${threadId}/ResponsibleInternalRole`;

    try {
      const response = await axios.put(endpointUrl, JSON.stringify(internalRole), {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getCustomerUsersWithRoles(customerId: number, roles: UserRole[], internalRoles: InternalRole[]): Promise<UserReferenceDto[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/Users`;

    try {
      const response = await axios(
        endpointUrl,
        {
          responseType: 'json',
          params: { roles, internalRoles },
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        }
      );
      const results = response.data;
      return await Promise.resolve(results);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getTranslatedLinks(customerId: number): Promise<TranslatedLinkGroupDto[]> {
    const endpointUrl = `${getBackendUrl()}/api/v1/DashboardCustomers/${customerId}/TranslatedLinks`;
    try {
      const response = await axios(endpointUrl, { responseType: 'json' });
      const companies: TranslatedLinkGroupDto[] = response.data;
      return await Promise.resolve(companies);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async declineOwnAbsence(userId: number, absenceId: number, approvalData: { approverId: number; approvalStatus: number; comment: string }): Promise<void> {
    const endpointUrl = `${getBackendUrl()}/api/v1/Users/Me/Absences/${absenceId}/Decline?userId=${userId}`;  
    try {
      await axios.put(endpointUrl, approvalData, { headers: { 'Content-Type': 'application/json' } });
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export const baseApi = new BaseApi();
