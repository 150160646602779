import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Divider,
  Button,
  Tabs,
  Tab,
  TablePagination,
  CircularProgress,
  InputAdornment,
  TextField,
  TableHead,
  TableRow,
  TableCell,
  Table,
  IconButton,
  Card,
} from '@mui/material';
import { FC, useCallback, useEffect, useState, MouseEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '../../icons/ChevronRight';
import { paymentApi } from 'src/API/paymentAPI';
import { DashboardPaymentTransactionsQueryParameters, TransactionDtoPagedResultDto, FilterOption, PaymentPayslipDto, PagedResult, PaymentDetailDto } from 'src/types/apiSchemas';
import { useDebounce, useMounted } from 'src/hooks';
import PaymentDetailBox from 'src/components/dashboard/payments/PaymentDetailCard';
import PaymentReviewsCard from 'src/components/dashboard/payments/PaymentReviewsCard';
import PaymentApprovalsCard from 'src/components/dashboard/payments/PaymentApprovalsCard';
import PaymentReviewDialog, { ReviewAction } from 'src/components/dashboard/payments/PaymentReviewDialog';
import PaymentApprovalDialog, { ApprovalAction } from 'src/components/dashboard/payments/PaymentApprovalDialog';
import PaymentChangedTransactionCard from 'src/components/dashboard/payments/PaymentChangedTransactionCard';
import PaymentUnChangedTransactionCard from 'src/components/dashboard/payments/PaymentUnChangedTransactionCard';
import PaymentFlagsRemoveDialog from 'src/components/dashboard/payments/PaymentFlagsRemoveDialog';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { CacheService, ICacheService } from 'src/Services';
import SearchIcon from '@mui/icons-material/Search';
import PaymentCommentSummary from 'src/components/dashboard/payments/PaymentCommentSummary';
import BadgeIcon from '@mui/icons-material/Badge';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import SalaryChangeIcon from '../../icons/SalaryChange';
import EuroOverlinedIcon from '../../icons/EuroOverlined';
import HelpIcon from '@mui/icons-material/Help';
import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios, { CancelTokenSource } from 'axios';
import { CloseOutlined } from '@mui/icons-material';
import PaymentPayslips from './PaymentPayslips';
import stateStore from 'src/store/mobx/stateStore';
import PaymentTimeline from './PaymentTimeline';

const PaymentSummary: FC = observer(() => {
  const { t } = useTranslation();

  const { paymentId } = useParams();
  const cacheKeyTabSelection = `paymentSummaryTab${paymentId}`;
  const cacheKeyPageNumber = `paymentSummaryPageNumber${paymentId}`;
  const cacheKeyPageSize = `paymentSummaryPageSize${paymentId}`;
  const cacheKeyScrollPosition = `paymentSummaryScrollY${paymentId}`;

  const cache: ICacheService = new CacheService();

  const filterOptions: FilterOption[] = [
    {
      label: 'Muuttumattomat maksut',
      value: 'UNCHANGED'
    },
    {
      label: t('NewEmployment'),
      value: 'NEW_EMPLOYMENT',
      icon: <BadgeIcon sx={{ marginLeft: '8px !important' }} />
    },
    {
      label: t('EndingEmployment'),
      value: 'ENDING_EMPLOYMENT',
      icon: <CancelPresentationIcon sx={{ marginLeft: '8px !important' }} />
    },
    {
      label: t('SalaryChange'),
      value: 'SALARY_CHANGE',
      icon: <SalaryChangeIcon sx={{ marginLeft: '8px !important' }} />
    },
    {
      label: t('WorktimeChange'),
      value: 'WORKTIME_CHANGE',
      icon: <AccessTimeIcon />
    },
    {
      label: t('Exception'),
      value: 'EXCEPTION',
      icon: <HelpIcon />
    },
    {
      label: t('PaylessAbsences'),
      value: 'PAYLESS_ABSENCES',
      icon: <EuroOverlinedIcon sx={{ width: '20px', marginLeft: '8px !important' }} />
    },
  ];

  const tabs = [
    { label: t('PaymentOverviewTitle'), value: 'overview' },
    { label: t('PaymentTransactionsTitle'), value: 'transactions' },
    { label: t('PaymentPayslipsTitle'), value: 'payslips' }
  ];
  const mounted = useMounted();
  const [payment, setPayment] = useState<PaymentDetailDto | null>(null);
  const [transaction, setTransaction] = useState<TransactionDtoPagedResultDto | null>(null);
  const pageSizeStored = cache.get<number>(cacheKeyPageSize);
  const initialPageSize = pageSizeStored && !Number.isNaN(pageSizeStored) ? pageSizeStored : 20;
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const pageNumberStored = cache.get<number>(cacheKeyPageNumber);
  const initialPageNumber = pageNumberStored && !Number.isNaN(pageNumberStored) ? pageNumberStored : 0;
  const [pageNumber, setPageNumber] = useState<number>(initialPageNumber);
  const [totalCount, setTotalCount] = useState(0);
  const activeTabStored = cache.get<string>(cacheKeyTabSelection);
  const activeTab = tabs.filter((tab) => tab.value === activeTabStored).length > 0 ? activeTabStored : 'overview';
  const [currentTab, setCurrentTab] = useState<string>(activeTab);
  const [openReviewDialog, setOpenReviewDialog] = useState<boolean>(false);
  const [reviewAction, setReviewAction] = useState<ReviewAction>();
  const [approvalAction, setApprovalAction] = useState<ApprovalAction>();
  const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false);
  const [openRemoveFlagsDialog, setOpenRemoveFlagsDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);
  const [reviewer, setCurrentUserAsReviewer] = useState<boolean>(false);
  const [approver, setCurrentUserAsApprover] = useState<boolean>(false);
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const defaultFilters = filterOptions.map((o) => o.value);

  const { transactionSearch: { [paymentId]: transactionSearch = '' }, setTransactionSearch } = stateStore;
  const { transactionFilters: { [paymentId]: transactionFilters = defaultFilters }, setTransactionFilters } = stateStore;

  const [openCommentDialog, setOpenCommentDialog] = useState<boolean>(false);
  const [flaggedTransactions, setFlaggedTransactions] = useState<TransactionDtoPagedResultDto | null>(null);
  const cancelTokenSource = React.useRef<CancelTokenSource>();
  const payslipsCancelTokenSource = React.useRef<CancelTokenSource>();
  const debouncedFilterString = useDebounce(transactionSearch, 500);
  const filtersActive = defaultFilters.length !== transactionFilters.length || debouncedFilterString.length > 0;
  const [paymentPayslips, setPaymentPayslips] = useState<PagedResult<PaymentPayslipDto>>();
  const [payslipsLoading, setPayslipsLoading] = useState(false);
  const [payslipTabVisible, setPayslipTabVisible] = useState(false);
  const [finalReviewPending, setFinalReviewPending] = useState(false);
  const navigate = useNavigate();

  const toggleFilter = (filter: string) => {
    const newFilters = transactionFilters.filter((f) => f !== filter);
    if (newFilters.length === transactionFilters.length) {
      setTransactionFilters([...transactionFilters, filter], paymentId);
    } else {
      setTransactionFilters(newFilters, paymentId);
    }
  };

  const scrollHandler = () => {
    if (mounted && !loading && !loadingTransactions) {
      const scrollPosition = cache.get<string>(cacheKeyScrollPosition);
      const scrollableElem = document.getElementById('dashboardLayoutContent');
      const scrollPositionNumeric = parseInt(scrollPosition, 10);
      if (!Number.isNaN(scrollPositionNumeric) && scrollPositionNumeric && scrollableElem) {
        scrollableElem.scrollTo(0, scrollPositionNumeric);
        cache.remove(cacheKeyScrollPosition);
      }
    }
  };

  useEffect(() => {
    scrollHandler();
  }, [loading, loadingTransactions]);

  const handleClick = () => {
    const scrollableElem = document.getElementById('dashboardLayoutContent');
    if (!scrollableElem) { return; }

    cache.set<number>(cacheKeyScrollPosition, scrollableElem.scrollTop);
  };

  const getPaymentPayslips = async (page: number, search?: string) => {

    setPayslipsLoading(true);
    try {
      if (payslipsCancelTokenSource.current) {
        payslipsCancelTokenSource.current.cancel();
      }
      payslipsCancelTokenSource.current = axios.CancelToken.source();
      const result = await paymentApi.getPaymentPayslips(parseInt(paymentId, 10), page, 10, search, payslipsCancelTokenSource.current.token);
      setPaymentPayslips(result);
      if (result.totalCount > 0) {
        setPayslipTabVisible(true);
      }

      setPayslipsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaymentPayslips(1);
  }, [paymentId]);

  const getPayment = useCallback(async () => {
    try {
      setLoading(true);
      const paymentData = await paymentApi.getPayment(parseInt(paymentId, 10));
      if (mounted.current) {
        setPayment(paymentData);
        setCurrentUserAsReviewer(paymentData?.reviews?.some((r) => r.userId === user.userId) ?? false);
        setCurrentUserAsApprover(paymentData?.approvals?.some((a) => a.userId === user.userId) ?? false);

        if (paymentData.companyRequiredReviews && paymentData.reviews) {
          const reviewCount = paymentData.reviews.reduce((acc, obj) => {
            if (obj.status === 'Reviewed') {
              acc += 1;
            }
            return acc;
          }, 0);

          if (paymentData.companyRequiredReviews - reviewCount === 1) {
            setFinalReviewPending(true);
          }
        }
      }
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err) && err.response?.status === 404) {
        navigate('/404');
      }
    } finally {
      setLoading(false);
    }
  }, [mounted]);

  const getTransactions = async () => {
    try {
      setLoadingTransactions(true);
      const queryParams: DashboardPaymentTransactionsQueryParameters = {
        SearchString: transactionSearch,
        Changes: transactionFilters,
        PageSize: pageSize,
        PageNumber: pageNumber + 1
      };
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel();
      }
      cancelTokenSource.current = axios.CancelToken.source();
      const data = await paymentApi.getTransactions(parseInt(paymentId, 10), queryParams, cancelTokenSource.current.token);
      setTransaction(data);
      setTotalCount(data.totalCount ?? 0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingTransactions(false);
    }
  };

  const getFlaggedTransactions = async () => {
    try {
      const queryParams: DashboardPaymentTransactionsQueryParameters = {
        Flagged: true,
        PageSize: 20,
        PageNumber: 1
      };
      const data = await paymentApi.getTransactions(parseInt(paymentId, 10), queryParams);
      setFlaggedTransactions(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getFlaggedTransactions();
  }, [mounted]);

  const resetCached = () => {
    cache.remove(cacheKeyPageNumber);
    cache.remove(cacheKeyPageSize);
    cache.remove(cacheKeyScrollPosition);
    cache.remove(cacheKeyTabSelection);
  };

  const handleTabsChange = (event: React.ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
    resetCached();
    cache.set<string>(cacheKeyTabSelection, value);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPageNumber(newPage);
    cache.set<number>(cacheKeyPageNumber, newPage);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const val = parseInt(event.target.value, 10);
    setPageSize(val);
    cache.set<number>(cacheKeyPageSize, val);
  };

  useEffect(() => {
    getTransactions();
  }, [pageSize, pageNumber, debouncedFilterString, transactionFilters.join('')]);

  useEffect(() => {
    getPayment();
  }, [mounted]);

  const handleCloseReviewDialogWithoutSuccess = () => {
    setOpenReviewDialog(false);
  };

  const handleCloseReviewDialogWithSuccess = () => {
    getPayment();
    setOpenReviewDialog(false);
  };

  const handleClickOpenReviewDialog = (action: ReviewAction) => {
    setReviewAction(action);
    setOpenReviewDialog(true);
  };

  const handleCloseApprovalDialogWithoutSuccess = () => {
    setOpenApproveDialog(false);
  };

  const handleCloseApprovalDialogWithSuccess = () => {
    getPayment();
    setOpenApproveDialog(false);
  };

  const handleClickOpenApprovalDialog = (action: ApprovalAction) => {
    setApprovalAction(action);
    setOpenApproveDialog(true);
  };

  const handleCloseRemoveFlagsDialogWithoutSuccess = () => {
    setOpenRemoveFlagsDialog(false);
  };

  const handleCloseRemoveFlagsDialogWithSuccess = () => {
    getPayment();
    setOpenRemoveFlagsDialog(false);
  };

  const handleClickOpenRemoveFlagsDialog = () => {
    setOpenRemoveFlagsDialog(true);
  };

  const handleOpenCommentSummaryClick = () => {
    setOpenCommentDialog(true);
  };

  const handleCloseCommentSummaryClick = () => {
    setOpenCommentDialog(false);
  };

  const reviewButtonDisabled = () => {
    const currentUserReviews = payment?.reviews?.filter((r) => r.userId === user?.userId);
    const currentUserReviewedOrDeclined = currentUserReviews?.length > 0
      && (currentUserReviews[0].status === 'Reviewed' || currentUserReviews[0].status === 'Declined');
    return (payment?.paymentStatus === 'Completed' || payment?.paymentStatus === 'Declined' || currentUserReviewedOrDeclined);
  };
  const approvalButtonDisabled = () => {
    const currentUserApprovals = payment?.approvals?.filter((s) => s.userId === user?.userId);
    const currentUserApprovedOrDeclined = currentUserApprovals?.length > 0
      && (currentUserApprovals[0].status === 'Approved' || currentUserApprovals[0].status === 'Declined');
    return (payment?.paymentStatus === 'Completed' || payment?.paymentStatus === 'Declined' || currentUserApprovedOrDeclined);
  };

  const hasFlaggedTransactions: boolean = transaction?.results?.filter((r) => r.flagged).length > 0;

  return (
    <>
      <Helmet>
        <title>{t('PaymentSummaryPageHelmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
          mx: 2
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
                sx={{ mb: 3 }}
              >
                {t('PaymentSummaryPageTitle')}
              </Typography>
              <Breadcrumbs
                sx={{
                  paddingLeft: '5%',
                  width: 300
                }}
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/landingpage"
                  variant="subtitle2"
                >
                  {t('LandingPagePageTitle')}
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/payments"
                  variant="subtitle2"
                >
                  {t('PaymentPageTitle')}
                </Link>
                <Typography
                  color="text.secondary"
                  variant="subtitle2"
                >
                  {t('PaymentTitle')}
                </Typography>
              </Breadcrumbs>
              <Box sx={{ mt: 3 }}>
                <Tabs
                  indicatorColor="primary"
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  textColor="primary"
                  value={currentTab}
                  variant="scrollable"
                >
                  {tabs.map((tab) => {
                    const visibility = !loadingTransactions && !filtersActive && tab.value === 'transactions' && transaction?.results?.length === 0 ? 'hidden' : 'visible';

                    if (tab.value === 'payslips' && tab.value !== currentTab && !payslipTabVisible) { return null; }
                    return (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                        sx={{ visibility }}
                      />
                    );
                  })}
                </Tabs>
              </Box>
            </Grid>
          </Grid>
          {!loading && (
            <Box sx={{ mt: 3 }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={5}
                  xl={5}
                  xs={12}
                >
                  {payment && (
                    <PaymentDetailBox
                      payment={payment}
                    />
                  )}
                  <Divider
                    variant="middle"
                    sx={{
                      marginTop: 3,
                      visibility: 'hidden'
                    }}
                  />
                  <Grid
                    container
                    justifyContent="center"
                  >
                    {payment && transaction && (
                      <>
                        {!reviewButtonDisabled() && reviewer && (
                          <Grid
                            item
                            mr={1}
                          >
                            <Button
                              sx={{ minWidth: '110px', maxWidth: '110px', mt: 1 }}
                              variant="contained"
                              onClick={() => handleClickOpenReviewDialog(ReviewAction.Decline)}
                              size="large"
                            >
                              {t('DeclineTitle')}
                            </Button>
                          </Grid>
                        )}
                        {!reviewButtonDisabled() && !hasFlaggedTransactions && reviewer && (
                          <Grid
                            item
                            mr={1}
                          >
                            <Button
                              sx={{ minWidth: '110px', maxWidth: '110px', mt: 1 }}
                              color="success"
                              variant="contained"
                              onClick={() => handleClickOpenReviewDialog(ReviewAction.Review)}
                              size="large"
                            >
                              {t('ReviewTitle')}
                            </Button>
                          </Grid>
                        )}
                      </>
                    )}
                    {payment && transaction && (
                      <>
                        {!approvalButtonDisabled() && approver && !reviewer && (
                          <Grid
                            item
                            mr={1}
                          >
                            <Button
                              sx={{ minWidth: '110px', maxWidth: '110px', mt: 1 }}
                              variant="contained"
                              onClick={() => handleClickOpenApprovalDialog(ApprovalAction.Decline)}
                              size="large"
                            >
                              {t('DeclineTitle')}
                            </Button>
                          </Grid>
                        )}
                        {!approvalButtonDisabled() && !hasFlaggedTransactions && approver && !reviewer && (
                          <Grid
                            item
                            mr={1}
                          >
                            <Button
                              sx={{ minWidth: '110px', maxWidth: '110px', mt: 1 }}
                              variant="contained"
                              onClick={() => handleClickOpenApprovalDialog(ApprovalAction.Approve)}
                              size="large"
                              color="success"
                            >
                              {t('ApproveTitle')}
                            </Button>
                          </Grid>
                        )}
                        {payment.paymentStatus !== 'Declined' && payment.paymentStatus !== 'Completed' && hasFlaggedTransactions && (reviewer || approver) && (
                          <Grid
                            item
                            mr={1}
                          >
                            <Button
                              sx={{ mt: 1 }}
                              variant="contained"
                              onClick={handleClickOpenRemoveFlagsDialog}
                              size="large"
                            >
                              {t('RemoveFlagsTitle')}
                            </Button>
                          </Grid>
                        )}
                      </>
                    )}
                    <Button
                      sx={{
                        mt: 1
                      }}
                      onClick={() => handleOpenCommentSummaryClick()}
                      variant="contained"
                    >
                      {t('ShowAllCommentsLabel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Card sx={{ mt: 5 }}>
                      <PaymentTimeline paymentDetails={payment} />
                    </Card>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={currentTab === 'payslips' ? 12 : 7}
                  xl={7}
                  xs={12}
                >
                  {payment && currentTab === 'overview' && (
                    <>
                      <PaymentReviewsCard
                        paymentReviews={payment.reviews}
                        paymentStatus={payment.paymentStatus}
                      />
                      <Divider
                        variant="middle"
                        sx={{
                          marginTop: 3,
                          visibility: 'hidden'
                        }}
                      />
                    </>
                  )}
                  {payment && currentTab === 'overview' && (
                    <>
                      <PaymentApprovalsCard
                        paymentApprovals={payment.approvals}
                        paymentStatus={payment.paymentStatus}
                      />
                    </>
                  )}
                  {currentTab === 'payslips' && (
                    <PaymentPayslips
                      paymentId={paymentId}
                      payslips={paymentPayslips}
                      onPageChange={(e, page, search) => {
                        getPaymentPayslips(page + 1, search);
                      }}
                      onSearchStringChange={(value) => {
                        getPaymentPayslips(1, value);
                      }}
                      isLoading={payslipsLoading}
                    />
                  )}
                  {currentTab === 'transactions' && transaction && (
                    <>
                      <Grid
                        container
                        display="flex"
                        gap={3}
                        sx={{
                          alignItems: 'center',
                          verticalAlign: 'middle',
                          mb: 3
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            alignItems: 'center',
                            verticalAlign: 'middle'
                          }}
                          xs={12}
                        >
                          <TextField
                            sx={{ maxWidth: 500 }}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon fontSize="small" />
                                </InputAdornment>
                              ),
                              endAdornment: transactionSearch.length > 0 ? (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => {
                                    setTransactionSearch('', paymentId);
                                    setPageNumber(0);
                                  }}
                                  >
                                    <CloseOutlined />
                                  </IconButton>
                                </InputAdornment>
                              ) : null
                            }}
                            onChange={(e) => {
                              setTransactionSearch(e.target.value, paymentId);
                              setPageNumber(0);
                            }}
                            placeholder={t('PaymentsTransactionsSeacrhbarPlaceholder')}
                            value={transactionSearch}
                            variant="outlined"
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          item
                        >
                          <div style={{ margin: '10px 0' }}><Typography variant="h6">{t('PaymentsTransactionsFilterTitle')}</Typography></div>
                          {filterOptions.map((option) => (
                            <Chip
                              key={option.value}
                              icon={option.icon}
                              sx={{ margin: 1, opacity: transactionFilters.find((f) => f === option.value) ? 1 : 0.4 }}
                              label={option.label}
                              onClick={() => {
                                toggleFilter(option.value);
                                setPageNumber(0);
                              }}
                            />
                          ))}
                        </Grid>
                      </Grid>
                      {!loadingTransactions && totalCount <= 0 && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant="h6"
                                  align="center"
                                >
                                  {t('NotTransactionsMessage')}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      )}
                      {!loadingTransactions && transaction && totalCount > 0 && (
                        <>
                          <PaymentChangedTransactionCard
                            transactions={transaction.results}
                            queryParams={{
                              SearchString: transactionSearch,
                              Changes: [...transactionFilters],
                              PageSize: pageSize,
                              PageNumber: pageNumber + 1
                            }}
                            clicked={handleClick}
                          />
                          <PaymentUnChangedTransactionCard
                            queryParams={{
                              SearchString: transactionSearch,
                              Changes: [...transactionFilters],
                              PageSize: pageSize,
                              PageNumber: pageNumber + 1
                            }}
                            transactions={transaction.results}
                            clicked={handleClick}
                          />
                          <TablePagination
                            component="div"
                            count={totalCount}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleLimitChange}
                            page={pageNumber}
                            rowsPerPage={pageSize}
                            rowsPerPageOptions={[20, 30, 40, 50]}
                          />
                        </>
                      )}
                      {(loadingTransactions || loading) && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          sx={{ mt: 10 }}
                          bgcolor="background.secondary"
                        >
                          <CircularProgress
                            size={65}
                          />
                        </Box>
                      )}
                    </>
                  )}

                  <Divider
                    variant="middle"
                    sx={{
                      marginTop: 3,
                      visibility: 'hidden'
                    }}
                  />
                  {payment && reviewAction && (
                    <PaymentReviewDialog
                      openDialog={openReviewDialog}
                      reviewAction={reviewAction}
                      closeDialogWithSuccess={handleCloseReviewDialogWithSuccess}
                      closeDialogWithoutSuccess={handleCloseReviewDialogWithoutSuccess}
                      paymentId={payment?.paymentId}
                      customerName={payment?.ownerName}
                      isFlagged={hasFlaggedTransactions}
                      paymentSum={payment.amount}
                      reviews={payment.reviews}
                      approvals={payment.approvals}
                      flaggedTransactions={flaggedTransactions}
                      showApproverNotificationSwitch={finalReviewPending}
                    />
                  )}
                  {payment && approvalAction && (
                    <PaymentApprovalDialog
                      openDialog={openApproveDialog}
                      closeDialogWithSuccess={handleCloseApprovalDialogWithSuccess}
                      closeDialogWithoutSuccess={handleCloseApprovalDialogWithoutSuccess}
                      paymentId={payment?.paymentId}
                      customerName={payment?.ownerName}
                      approvalAction={approvalAction}
                      isFlagged={hasFlaggedTransactions}
                      paymentSum={payment.amount}
                      reviews={payment.reviews}
                      approvals={payment.approvals}
                      flaggedTransactions={flaggedTransactions}
                    />
                  )}
                  {payment && (
                    <PaymentFlagsRemoveDialog
                      openDialog={openRemoveFlagsDialog}
                      closeDialogWithSuccess={handleCloseRemoveFlagsDialogWithSuccess}
                      closeDialogWithoutSuccess={handleCloseRemoveFlagsDialogWithoutSuccess}
                      paymentId={payment?.paymentId}
                    />
                  )}
                  {payment && flaggedTransactions && payment.reviews && payment.approvals && (
                    <PaymentCommentSummary
                      openDialog={openCommentDialog}
                      closeDialogWithSuccess={handleCloseCommentSummaryClick}
                      reviews={payment.reviews}
                      approvals={payment.approvals}
                      flaggedTransactions={flaggedTransactions}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
});
export default PaymentSummary;
