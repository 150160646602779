import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BankReplyStatus, PaymentDetailDto, PaymentStatus, PaymentTransferStatus } from 'src/types/apiSchemas';
import { displayLocalDate, displayLocalTime } from 'src/utils/dateUtils';
import BlockIcon from '@mui/icons-material/Block';

interface PaymentTimeline {
  paymentDetails: PaymentDetailDto;
}

const displayDate = (date: string) => {
  return (
    <>
      {displayLocalDate(date, true)}
      <br />
      {displayLocalTime(date, true)}
    </>
  );
};

const PaymentTimeline: FC<PaymentTimeline> = ({ paymentDetails }) => {

  const { t } = useTranslation();

  const translateTransferStatus = (status: PaymentTransferStatus) => {
    switch (status) {
      case PaymentTransferStatus.Completed:
        return t('PaymentTimelineTransferComplete');
      case PaymentTransferStatus.Failed:
        return t('PaymentTimelineTransferFailed');
    }
  };
  const translateBankReplyStatus = (status: BankReplyStatus) => {
    switch (status) {
      case BankReplyStatus.Unknown:
        return t('BankReplyStatusUnknown');
      case BankReplyStatus.Pending:
        return t('BankReplyStatusWaiting');
      case BankReplyStatus.Approved:
        return t('BankReplyStatusApproved');
      case BankReplyStatus.Rejected:
        return t('BankReplyStatusRejected');
    }
  };
  const translatePaymentStatus = (status: PaymentStatus) => {
    switch (status) {
      case 'Completed':
        return t('PaymentTimelinePaymentStatusCompleted');
      case 'Declined':
        return t('PaymentTimelinePaymentStatusDeclined');
      case 'Pending':
      case 'Created':
        return t('PaymentTimelinePaymentStatusPending');
    }
  };

  const incompleteStatuses: PaymentStatus[] = ['Created', 'Pending', 'Declined'];
  const isIncompletePayment = incompleteStatuses.includes(paymentDetails.paymentStatus);

  const displayBankReplyStatuses: BankReplyStatus[] = [BankReplyStatus.Approved, BankReplyStatus.Rejected, BankReplyStatus.Pending];
  const hasBankReply = paymentDetails.paymentTransfer?.bankReplyMessage?.length > 0 || displayBankReplyStatuses.includes(paymentDetails.paymentTransfer?.bankReplyStatus);

  return (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContent>
          <div>{displayDate(paymentDetails.created)}</div>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ wordBreak: 'break-word' }}>{t('PaymentTimelinePaymentCreated')}</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          {paymentDetails.processed && (
            <>
              <div>{displayDate(paymentDetails.processed)}</div>
            </>
          )}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector sx={{ opacity: isIncompletePayment ? 0.5 : 1 }} />
        </TimelineSeparator>
        <TimelineContent sx={{ display: 'flex', wordBreak: 'break-word' }}>
          {paymentDetails.paymentStatus === 'Declined' && <BlockIcon color="error" sx={{ mr: 1 }} />}
          {translatePaymentStatus(paymentDetails.paymentStatus)}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          {paymentDetails.paymentTransfer?.transferTime && (
            <>
              <div>{displayDate(paymentDetails.paymentTransfer?.transferTime)}</div>
            </>
          )}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{ opacity: isIncompletePayment ? 0.5 : 1 }} />
          {hasBankReply && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent sx={{ opacity: isIncompletePayment ? 0.5 : 1, display: 'flex', wordBreak: 'break-word' }}>
          {paymentDetails.paymentTransfer?.transferStatus === PaymentTransferStatus.Failed && <BlockIcon color="error" sx={{ mr: 1 }} />}
          {paymentDetails.paymentTransfer ? translateTransferStatus(paymentDetails.paymentTransfer.transferStatus) : isIncompletePayment ? t('PaymentTimelineTransfer') : t('PaymentTimelineTransferPending')}
        </TimelineContent>
      </TimelineItem>
      {hasBankReply && (
        <TimelineItem>
          <TimelineOppositeContent>
            {paymentDetails.paymentTransfer?.bankReplyTime && (
              <>
                <div>{displayDate(paymentDetails.paymentTransfer.bankReplyTime)}</div>
              </>
            )}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
          </TimelineSeparator>
          <TimelineContent sx={{ wordBreak: 'break-word' }}>
            <div style={{ display: 'flex' }}>
              {t('PaymentTimelineBankReply')}: {paymentDetails.paymentTransfer?.bankReplyStatus === BankReplyStatus.Rejected && <BlockIcon color="error" sx={{ mr: 1, ml: 1 }} />} {translateBankReplyStatus(paymentDetails.paymentTransfer?.bankReplyStatus)}
            </div>
            <div>{paymentDetails.paymentTransfer?.bankReplyMessage}</div>
          </TimelineContent>
        </TimelineItem>
      )}
    </Timeline>
  );
};

export default PaymentTimeline;