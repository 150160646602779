import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { useEffect, useRef, useState } from 'react';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { MessageThreadVisibility } from 'src/types/apiSchemas';
import ToolbarPlugin from './ToolbarPlugin';
import MentionsPlugin from './MentionsPlugin';
import ImagesPlugin from './ImagesPlugin';
import './MessageEditor.css';
import { editorConfig } from './editorConfig';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import messageStore from 'src/store/mobx/messageStore';
import { baseApi } from 'src/API/baseApi';
import { isAxiosError } from 'axios';

const emptyRichTextValue = '<p class="editor-paragraph"><br></p>';

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const MATCHERS = [
  (text) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
      attributes: { target: '_blank' },
    };
  },
];

function Editor({
  height,
  maxHeight,
  showToolbar = false,
  defaultText = '',
  messageThreadUniqueId,
  messageThreadVisibility,
  customerId,
  scope
}: {
  height?: number,
  maxHeight?: number,
  showToolbar?: boolean,
  defaultText?: string,
  messageThreadUniqueId: string,
  messageThreadVisibility: MessageThreadVisibility,
  customerId: number,
  scope: string
}) {
  const [lexicalEditor] = useLexicalComposerContext();
  const [toolbarVisibile, setToolbarVisible] = useState(showToolbar);
  const init = useRef(true);
  const [isEditable, setIsEditable] = useState(false);

  const {
    draft: { [scope]: draft },
    attachments: { [scope]: attachments = [] },
    setDraft,
    setContent,
    setRichTextContent,
    addAttachment,
    addMention,
    removeAttachment,
    removeMention,
    clearMentions,
    clearDraft,
    clearValue: { [scope]: clearValue },
    deleteClearValue,
    restoreAttachment
  } = messageStore;

  const messageAttachments = attachments.filter(a => !a.deleted);

  useEffect(() => {
    const getDraft = async () => {
      if (init.current) {
        init.current = false;

        if (draft) {
          const json = JSON.parse(draft);
          const removedAttachmentUrls = [];

          /**
           * If draft contains attachments, refresh sas urls so that they can be displayed correctly
           * If sas url fetch fails with 404 the attachment gets removed
           */
          const sasUrls = messageAttachments.map(async (a) => {
            try {
              const sasUrl = await baseApi.getAttachmentSasUrl(a.urlWithoutSas);
              return {
                url: a.urlWithoutSas,
                sasUrl
              };
            } catch (err) {
              if (isAxiosError(err) && err.response.status === 404) {
                removeAttachment(a.urlWithoutSas, scope);
                removedAttachmentUrls.push(a.urlWithoutSas);
              }
            }
          });

          const results = await Promise.allSettled(sasUrls);

          const fulfilled = results.filter((result): result is PromiseFulfilledResult<{ url: string, sasUrl: string }> => result.status === 'fulfilled')
            .map(result => result.value).filter(v => v);

          const loopChildren = (children) => {
            return children?.map(child => {
              let sasUrl: string;
              if (child.type === 'image') {
                sasUrl = fulfilled.find(r => r.url === child.url)?.sasUrl;
                if (removedAttachmentUrls.find(url => url === child.url)) {
                  return null;
                }
              }
              return { ...child, src: sasUrl || child.url, children: loopChildren(child.children) };
            }).filter(v => v);
          };

          json.root.children = loopChildren(json.root.children);
          const initialEditorState = lexicalEditor.parseEditorState(json);
          clearMentions(scope);
          setDraft(JSON.stringify(json), scope);
          lexicalEditor.setEditorState(initialEditorState);
        }

        lexicalEditor.setEditable(true);
        setIsEditable(true);
      }
    };
    getDraft();
  }, [lexicalEditor]);

  useEffect(() => {
    lexicalEditor.setEditable(isEditable);
  }, [isEditable, lexicalEditor]);

  useEffect(() => {
    lexicalEditor.update(() => {
      const paragraph = $createParagraphNode();
      paragraph.append($createTextNode(defaultText));

      const root = $getRoot();
      root.clear();
      root.append(paragraph);
    });
  }, [defaultText]);

  useEffect(() => {
    lexicalEditor.update(() => {
      const paragraph = $createParagraphNode();
      const root = $getRoot();
      root.clear();
      root.append(paragraph);
    });
    deleteClearValue(scope);
  }, [clearValue]);

  return (
    <div className="editor-container">
      <div style={{ position: 'relative' }}>
        <ToolbarPlugin
          onAttachmentAdd={(attachment) => {
            addAttachment(attachment, scope);
          }}
          visible={toolbarVisibile}
          customerId={customerId}
          scope={scope}
        />
      </div>
      <div className="editor-inner" style={{ ...(height ? { height } : {}), ...(maxHeight ? { maxHeight, overflow: 'auto' } : {}) }}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className="editor-input"
              onFocus={() => {
                setToolbarVisible(true);
              }}
              style={{ ...(height ? { minHeight: height } : {}) }}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
        <ImagesPlugin captionsEnabled={false}
          onImageDelete={(image) => {
            removeAttachment(image.__url, scope);
          }}
          onImageCreate={(image) => {
            restoreAttachment(image.__url, scope);
          }}
        />
        <OnChangePlugin onChange={(editorState, editor) => {
          editor.update(() => {
            const richText = $generateHtmlFromNodes(editor, null);
            const stringifiedEditorState = JSON.stringify(
              editor.getEditorState().toJSON(),
            );
            const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
            const plainText = parsedEditorState.read(() => $getRoot().getTextContent());

            if (!plainText && richText === emptyRichTextValue && messageAttachments.length < 1) {
              clearDraft(scope);
            } else {
              setRichTextContent(richText, scope);
              setContent(plainText, scope);
              setDraft(stringifiedEditorState, scope);
            }
          });
        }}
        />
        <AutoLinkPlugin matchers={MATCHERS} />
        <MentionsPlugin messageThreadVisibility={messageThreadVisibility}
          onMentionAdd={(mention) => {
            addMention(mention, scope);
          }}
          onMentionRemove={(mention) => {
            removeMention(mention, scope);
          }}
          customerId={customerId}
          messageThreadUniqueId={messageThreadUniqueId}
        />
      </div>
    </div >);
}

export default function MessageEditor({
  height,
  maxHeight,
  showToolbar = false,
  defaultText = '',
  messageThreadUniqueId,
  messageThreadVisibility,
  customerId,
  scope
}: {
  height?: number,
  maxHeight?: number,
  showToolbar?: boolean,
  defaultText?: string,
  messageThreadUniqueId: string,
  messageThreadVisibility?: MessageThreadVisibility,
  customerId: number,
  scope: string
}) {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Editor
        maxHeight={maxHeight}
        height={height}
        showToolbar={showToolbar}
        defaultText={defaultText}
        messageThreadUniqueId={messageThreadUniqueId}
        messageThreadVisibility={messageThreadVisibility}
        customerId={customerId}
        scope={scope}
      />
    </LexicalComposer>
  );
}
