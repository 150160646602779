import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $isLinkNode } from '@lexical/link';

import {
  $getSelection,
  $createParagraphNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  ElementFormatType,
  $isElementNode,
} from 'lexical';
import {
  $isListNode,
  ListNode,
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND
} from '@lexical/list';
import {
  $setBlocksType,
} from '@lexical/selection';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
} from '@lexical/utils';
import { $isAtNodeEnd } from '@lexical/selection';
import { ElementNode, RangeSelection, TextNode } from 'lexical';
import { INSERT_IMAGE_COMMAND } from './ImagesPlugin';
import { ImagePayload } from './ImageNode';
import { isAllowedAttachmentFile, isImageFile } from 'src/utils/imageUtils';
import { allowedMessageFileExtensions, attachmentFileSizeLimitInMB, imageFileExtensions } from 'src/constants';
import { baseApi } from 'src/API/baseApi';
import { MessageAttachment } from 'src/types/apiSchemas';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { Attachment } from '@mui/icons-material';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CommonDialog from 'src/components/common/CommonDialog';
import messageStore from 'src/store/mobx/messageStore';

const getStyles = () => `
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin: 4px;
  border-radius: 5px;

  :hover:not([disabled]) {
    opacity: 1;
    background: rgba(0,0,0,0.05);
  }

  :disabled {
    cursor: default;
    opacity: 0.5;
  }

  svg {
    width: 25px;
  }`;

export const ToolbarButton = styled.button<{ active: boolean }>`
  opacity: ${props => props.active ? 1 : 0.2};
  ${getStyles()}
`;

const LabelButton = styled.label<{ active: boolean, disabled: boolean, title?: string }>`
  ${getStyles()}
  ${props => props.disabled && `
    cursor: default;
    opacity: 0.1;
  `}
`;


const Button = ({ active, children, onClick, disabled, title }: { active?: boolean, children: React.ReactNode, onClick: () => void, disabled: boolean, title?: string }) => {
  return <ToolbarButton disabled={disabled} onClick={onClick} active={active} title={title} type="button">{children}</ToolbarButton>;
};

export function getSelectedNode(
  selection: RangeSelection,
): TextNode | ElementNode {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
  }
}

const LowPriority = 1;

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

export default function ToolbarPlugin({ onAttachmentAdd, visible, customerId, scope }: { onAttachmentAdd?: (attachment: MessageAttachment) => void, visible: boolean, customerId?: number, scope?: string }) {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation();
  const toolbarRef = useRef(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left');
  const isEditable = editor.isEditable();

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
            const parent = e.getParent();
            return parent !== null && $isRootOrShadowRoot(parent);
          });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      if ($isListNode(element)) {
        const parentList = $getNearestNodeOfType<ListNode>(
          anchorNode,
          ListNode,
        );
        const type = parentList
          ? parentList.getListType()
          : element.getListType();
        setBlockType(type);
      } else {
        setBlockType(element.getType() as keyof typeof blockTypeToBlockName);
      }
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline(),
        );
      }
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType() || 'left'
          : $isElementNode(node)
            ? node.getFormatType() || 'left'
            : parent?.getFormatType() || 'left',
      );
    }

  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority,
      )
    );
  }, [editor, $updateToolbar]);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const addMessageAttachments = async (files: File[], forceAsAttachment = false) => {
    for (const file of files) {
      if (editor.isEditable() && file) {
        try {
          if (file.size > attachmentFileSizeLimitInMB * 1024 * 1024) {
            toast.error(t('FormSubmitResult.FileIsTooLargeTheLimitForOneFileIs10Mb'));
          } else if (!isAllowedAttachmentFile(file.name, allowedMessageFileExtensions)) {
            toast.error(t('FormSubmitResult.FileTypeNotAccepted'));
          } else {
            const res = await baseApi.postMessageAttachmentWithoutMessageId(file, customerId);
            const isImage = isImageFile(file.name);

            if (isImage && !forceAsAttachment) {
              const payload: ImagePayload = {
                src: res.url,
                thumbnail: res.thumbnailUrl,
                url: res.urlWithoutSas,
                altText: 'image',
                captionsEnabled: false
              };
              editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
            }
            onAttachmentAdd(res);
          }
        } catch (err) {
          console.log(err);
          if (axios.isAxiosError && err.response?.data?.resultKey) {
            toast.error(t(err.response?.data?.resultKey));
          } else {
            toast.error(t('AttachmentsFormAttachmentsSendFailed'));
          }
        }
      }
    }
  };

  useEffect(() => {
    const dropFile = async (ev: DragEvent) => {
      ev.preventDefault();
      if (ev.dataTransfer.items) {
        const files = [...ev.dataTransfer.items].filter(item => item.kind === 'file').map(item => item.getAsFile());
        addMessageAttachments(files);
      } else {
        addMessageAttachments([...ev.dataTransfer.files]);
      }
    };

    const preventDefault = (ev: DragEvent) => {
      ev.preventDefault();
    };

    const pasteFile = async (ev: ClipboardEvent) => {
      const files = [...ev.clipboardData.files];
      if (files.length > 0) {
        ev.preventDefault();
      }
      addMessageAttachments([...files]);
    };

    document.addEventListener('dragover', preventDefault);
    document.addEventListener('drop', dropFile);
    document.addEventListener('paste', pasteFile);

    return () => {
      document.removeEventListener('dragover', preventDefault);
      document.removeEventListener('drop', dropFile);
      document.removeEventListener('paste', pasteFile);
    };
  }, []);

  const { clearEditor } = messageStore;

  const [openDialog, setOpenDialog] = useState(false);

  const handleClearEditor = useCallback(() => {
    clearEditor(scope);
  }, [clearEditor, scope]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const cancelClearEditor = () => {
    setOpenDialog(false);
  };

  const confirmClearEditor = () => {
    handleClearEditor();
    setOpenDialog(false);
  };

  return (
    <Box className="editor-toolbar" ref={toolbarRef} sx={{ borderBottom: '1px solid rgba(0,0,0,0.05)', background: '#fff', display: visible ? 'flex' : 'none', borderRadius: '5px' }}>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        active={isBold}
        disabled={!isEditable}
      >
        <FormatBoldIcon />
      </Button>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        active={isItalic}
        disabled={!isEditable}
      >
        <FormatItalicIcon />
      </Button>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        active={isUnderline}
        disabled={!isEditable}
      >
        <FormatUnderlinedIcon />
      </Button>
      <div style={{ borderLeft: '1px solid rgba(0,0,0,0.05)' }}></div>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        active={elementFormat === 'left'}
        disabled={!isEditable}
      >
        <FormatAlignLeftIcon />
      </Button>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        active={elementFormat === 'center'}
        disabled={!isEditable}
      >
        <FormatAlignCenterIcon />
      </Button>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        active={elementFormat === 'right'}
        disabled={!isEditable}
      >
        <FormatAlignRightIcon />
      </Button>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        active={elementFormat === 'justify'}
        disabled={!isEditable}
      >
        <FormatAlignJustifyIcon />
      </Button>
      <div style={{ borderLeft: '1px solid rgba(0,0,0,0.05)' }}></div>
      <Button
        onClick={() => {
          if (blockType !== 'bullet') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          } else {
            formatParagraph();
          }
        }}
        active={blockType === 'bullet'}
        disabled={!isEditable}
      >
        <FormatListBulletedIcon />
      </Button>
      <Button
        onClick={() => {
          if (blockType !== 'number') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
          } else {
            formatParagraph();
          }
        }}
        active={blockType === 'number'}
        disabled={!isEditable}
      >
        <FormatListNumberedIcon />
      </Button>
      <div style={{ borderLeft: '1px solid rgba(0,0,0,0.05)' }}></div>
      {
        onAttachmentAdd && customerId &&
        (
          <>
            <LabelButton active={false}
              htmlFor="image-upload"
              tabIndex={0}
              onClick={() => {
                editor.focus();
              }}
              title={t('GenericButtonInsertImage')}
              disabled={!isEditable}
            >
              <AddPhotoAlternateIcon sx={{
                color: '#000'
              }}
              />
            </LabelButton>
            <LabelButton active={false}
              htmlFor="attachment-upload"
              tabIndex={0}
              onClick={() => {
                editor.focus();
              }}
              title={t('MessagesPageAddAttachment')}
              disabled={!isEditable}
            >
              <Attachment sx={{
                color: '#000'
              }}
              />
            </LabelButton>
            <input
              disabled={!isEditable}
              id="image-upload"
              accept={imageFileExtensions.join(', ')}
              type="file"
              hidden
              name="image-upload"
              onChange={async (e) => {
                const file = e.target.files[0];
                await addMessageAttachments([file]);
                e.target.value = '';
              }}
            />
            <input
              disabled={!isEditable}
              id="attachment-upload"
              accept={allowedMessageFileExtensions.join(', ')}
              type="file"
              hidden
              name="file-upload"
              onChange={async (e) => {
                const file = e.target.files[0];
                await addMessageAttachments([file], true);
                e.target.value = '';
              }}
            />
            <div style={{ marginLeft: 'auto' }}>
              <Button onClick={handleOpenDialog} 
                title={t('GenericButtonDiscardDraft')}
                disabled={!isEditable}
                active={true}
              >
                <DeleteOutlineIcon />
              </Button>

              <CommonDialog
                isOpen={openDialog}
                dialogTitle={t('MessageDraftDiscard')}
                dialogContent=""
                handleConfirm={confirmClearEditor}
                handleCancel={cancelClearEditor}
                confirmButtonTitle={t('GenericButtonDiscard')}
                cancelButtonTitle={t('ButtonCancel')}
              />
            </div>
          </>
        )}
    </Box>
  );
}
